import { useState } from 'react';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import styles from './FileVaultRecent.module.scss';
import PDFLogo from '../../img/pdfLogo.svg';
import XLSLogo from '../../img/xlsLogo.svg';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FileVaultPreview from '../FileVaultPreview/FileVaultPreview';

export default function FileVaultRecent({ files }) {
	const settings = {
		infinite: files?.length > 4,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <ChevronRight className={styles.arrow} color={'#00539A'} />,
		prevArrow: <ChevronLeft className={styles.arrow} color={'#00539A'} />,
	};

	const [filePreview, setFilePreview] = useState(null);

	return (
		<>
			<div className={styles.title}>Recent Files</div>
			<div className={styles.container}>
				<Slider {...settings}>
					{files?.map((file, index) => (
						<div
							key={index}
							onClick={() => {
								setFilePreview(file);
							}}
						>
							<div className={styles.file}>
								<div className={styles.logo}>
									<img
										src={
											file.mimeType === 'application/pdf'
												? PDFLogo
												: XLSLogo
										}
										alt={file.type}
									/>
								</div>
								<div className={styles.description}>
									<div className={styles.name}>
										<p>{file.name}</p>
									</div>
									<span>
										Last edit by{' '}
										<span className={styles.who}>
											{file.updatedBy.name}
										</span>
									</span>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>

			{filePreview && (
				<FileVaultPreview
					file={filePreview}
					onClose={() => setFilePreview(null)}
					tab={'overview'}
				/>
			)}
		</>
	);
}
