const getValuation = (investment, period) => {
	return investment * ((period + 1) * 1.4);
};

const getReturn = (investment, period) => {
	return investment * ((period + 1) * 1.2);
};

const getRevenue = (investment, period) => {
	return investment * ((period + 1) * 1.6);
};

const getEbitda = (investment, period) => {
	return investment * ((period + 1) * 1.8);
};

export { getValuation, getReturn, getRevenue, getEbitda };
