import { createTheme } from '@material-ui/core';

const DefaultTheme = createTheme({
	typography: {
		fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
		fontFamilyNumbers: ['Roboto Mono', 'monospace'].join(','),
	},
	spacing: 8,
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
				input: {
					borderRadius: 0,
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					width: '100%',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: '#1192E8',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				contained: {
					backgroundColor: '#00539A',
					color: '#F2F4F8',
					borderRadius: 0,
					padding: '8px 16px',
					textTransform: 'capitalize',
				},
				outlined: {
					color: '#00539A',
					borderColor: '#00539A',
					borderRadius: 0,
					textTransform: 'capitalize',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: '#1192E8',
					height: '4px',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					'&.Mui-selected': {
						color: '#282828',
						fontWeight: 'bold',
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: '#F2F4F8',

						'&:hover': {
							backgroundColor: '#F2F4F8',
						},
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					backgroundColor: '#E5F6FF',
					color: '#00539A',
				},
				deleteIcon: {
					color: '#00539A',
				},
				colorWarning: {
					backgroundColor: '#F9E3B6',
					color: '#000000',
				},
				colorSuccess: {
					backgroundColor: '#A7F0BA',
					color: '#000000',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					boxShadow: 'none',
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: '0 9px',
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					background: '#DDE1E6',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				standardError: {
					background: '#fff',
					color: 'unset',
					borderLeft: '5px solid #A2191F',
					borderRadius: 0,
					boxShadow:
						'0px 6px 12px rgba(106, 115, 129, 0.16), 0px 3px 8px rgba(87, 102, 117, 0.06)',
				},
				standardInfo: {
					background: '#fff',
					color: 'unset',
					borderLeft: '5px solid #1192e8',
					borderRadius: 0,
					boxShadow:
						'0px 6px 12px rgba(106, 115, 129, 0.16), 0px 3px 8px rgba(87, 102, 117, 0.06)',
				},
				standardSuccess: {
					background: '#fff',
					color: 'unset',
					borderLeft: '5px solid #24a148',
					borderRadius: 0,
					boxShadow:
						'0px 6px 12px rgba(106, 115, 129, 0.16), 0px 3px 8px rgba(87, 102, 117, 0.06)',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					padding: '10px',
				},
				head: {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					// lineHeight: '12px',
					// paddingTop: '5px',
					// paddingBottom: '5px',
					// maxWidth: '50px',
				},
				stickyHeader: {
					background: '#DDE1E6',
				},
			},
		},
	},
	palette: {
		error: {
			main: '#A2191F',
		},
		success: {
			main: '#24a148',
		},
		info: {
			main: '#24a148',
		},
	},
});

export default DefaultTheme;
