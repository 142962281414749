import GuestFrame from '../GuestFrame/GuestFrame';
import OnboardingForm from '../forms/OnboardingForm/OnboardingForm';

export default function OnboardingPage() {
	return (
		<GuestFrame title={'Onboarding'}>
			<OnboardingForm />
		</GuestFrame>
	);
}
