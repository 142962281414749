/* eslint-disable no-console */
import qs from 'qs';
import GuestFrame from '../GuestFrame/GuestFrame';
import RequestPasswordResetForm from '../forms/RequestPasswordResetForm/RequestPasswordResetForm';
import ResetPasswordForm from '../forms/ResetPasswordForm/ResetPasswordForm';

export default function ResetPasswordPage(props) {
	const { userId, tfaCode } = qs.parse(props.location.search, {
		ignoreQueryPrefix: true,
	});

	return (
		<GuestFrame title={'Reset password'}>
			{userId && tfaCode && (
				<ResetPasswordForm id={userId} tfaCode={tfaCode} />
			)}
			{!tfaCode && !userId && <RequestPasswordResetForm />}
		</GuestFrame>
	);
}
