import { Grid } from '@material-ui/core';
import { useFileVault } from '../../services/FileVaultService';
import FileVaultBrowser from '../FileVaultBrowser/FileVaultBrowser';
import FileVaultRecent from '../FileVaultRecent/FileVaultRecent';
import FileVaultSearch from '../FileVaultSearch/FileVaultSearch';
import { FileVaultSearchResult } from '../FileVaultSearchResult/FileVaultSearchResult';
import styles from './FileVaultDashboard.module.scss';

export default function FileVaultDashboard({ tab }) {
	const fileVault = useFileVault();

	const { search } = fileVault;

	return (
		<Grid container className={styles.container} paddingLeft={0}>
			<Grid item md={12} paddingBottom={2}>
				<FileVaultSearch />
			</Grid>
			{search !== undefined ? (
				<Grid item md={12} paddingBottom={2} paddingTop={0}>
					<FileVaultSearchResult />
				</Grid>
			) : (
				<>
					{tab !== 'recycle' && (
						<Grid item md={12} paddingBottom={2} paddingTop={2}>
							<FileVaultRecent files={fileVault.recentFiles} />
						</Grid>
					)}
					<Grid item md={12} paddingBottom={2} paddingTop={0}>
						<FileVaultBrowser tab={tab} />
					</Grid>
				</>
			)}
		</Grid>
	);
}
