import { useState, useEffect } from 'react';
import { Alert, CircularProgress, Modal } from '@material-ui/core';
import { X, FileDownload } from 'tabler-icons-react';
import FileViewer from 'react-file-viewer';
import axios from 'axios';
import styles from './FileVaultPreview.module.scss';
import FileVaultService from '../../services/FileVaultService';
import FileIcon from '../FileIcon/FileIcon';
import { downloadFile, getFileTypeForViewer } from '../../utils/FileHelper';
import FileVualtDeleteBtn from '../FileVaultDeleteBtn/FileVaultDeleteBtn';

/* eslint-disable no-console */

export default function FileVaultPreview({ file, onClose }) {
	const [open, setOpen] = useState(true);
	const [url, setUrl] = useState();
	const [blobFile, setBlobFile] = useState();
	const [loading, setLoading] = useState();
	const [error, setError] = useState(null);

	const downloadBlobFile = (downloadURL) => {
		axios
			.get(downloadURL, { responseType: 'blob' })
			.then((response) => {
				setLoading(false);
				setBlobFile(response.data);
			})
			.catch(() => {
				setLoading(false);
				setError("Can't open this file");
			});
	};

	useEffect(() => {
		setLoading(true);
		FileVaultService.getDocument(file.id).then((data) => {
			setUrl(data.url);
			downloadBlobFile(data.url);
		});
	}, [file, onClose]);

	const handleModalClose = () => {
		setOpen(false);
		if (onClose) {
			onClose();
		}
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div className={styles.modal}>
					<div className={styles.title}>
						<FileIcon mimeType={file.mimeType} />
						<div className={styles.fileName}>{file.name} </div>
						<div className={styles.actions}>
							<FileDownload
								onClick={() => downloadFile(blobFile, file)}
								size={18}
							/>
							<FileVualtDeleteBtn
								file={file}
								size={18}
								callback={handleModalClose}
							/>
						</div>
						<div className={styles.closeBtn}>
							<X onClick={handleModalClose} />
						</div>
					</div>
					<div className={styles.content}>
						{loading && <CircularProgress />}
						{error && <Alert severity="error">{error}</Alert>}
						{url && blobFile && (
							<>
								<FileViewer
									fileType={getFileTypeForViewer(
										file.mimeType,
									)}
									filePath={URL.createObjectURL(blobFile)}
								/>
							</>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
}
