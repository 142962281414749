/* eslint-disable react-hooks/exhaustive-deps */
import styles from './FileVaultUploader.module.scss';

export default function FileVaultUploader({ rootProps, inputProps }) {
	return (
		<section className={styles.fileVaultUploader}>
			<div {...rootProps({ className: styles.dropzone })}>
				<input {...inputProps()} />
			</div>
		</section>
	);
}
