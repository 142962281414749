import PageFrame from '../PageFrame';
import PrivateEquityDashboard from '../PrivateEquityDashboard/PrivateEquityDashboard';

export default function HomePage() {
	return (
		<PageFrame title="Home">
			<PrivateEquityDashboard />
		</PageFrame>
	);
}
