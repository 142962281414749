import { Grid, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { FileDownload, Eye } from 'tabler-icons-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getFileType, downloadFile } from '../../utils/FileHelper';
import FileIcon from '../FileIcon/FileIcon';
import styles from './FileVaultFileInfo.module.scss';
import FileVaultService from '../../services/FileVaultService';
import FileVualtDeleteBtn from '../FileVaultDeleteBtn/FileVaultDeleteBtn';

export default function FileVaultFileInfo({ file, viewFile, onDelete, tab }) {
	const [loading, setLoading] = useState(false);
	const {
		mimeType,
		name,
		fileSize,
		owner: { name: ownerName },
		updatedAt,
		createdAt,
	} = file;

	const [preview, setPreview] = useState();
	const [previewLoading, setPreviewLoading] = useState(false);

	const startDownload = () => {
		setLoading(true);
		FileVaultService.getDocument(file.id).then((data) => {
			if (data.url) {
				axios
					.get(data.url, { responseType: 'blob' })
					.then((response) => {
						downloadFile(response.data, file);
						setLoading(false);
					});
			} else {
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		setPreviewLoading(true);
		FileVaultService.getDocument(file.id, true).then((data) => {
			setPreview(data.url);
			setPreviewLoading(false);
		});
	}, [file]);

	return (
		<Grid container className={styles.container}>
			<Grid item>
				<div className={styles.section}>
					<div className={styles.fileHeader}>
						<FileIcon mimeType={mimeType} />
						{name}
					</div>
					<div className={styles.actions}>
						{tab !== 'recycle' && (
							<Eye onClick={viewFile} className={styles.icon} />
						)}
						{!loading && tab !== 'recycle' ? (
							<FileDownload
								onClick={startDownload}
								className={styles.icon}
							/>
						) : (
							tab !== 'recycle' && (
								<CircularProgress
									size={20}
									className={styles.icon}
								/>
							)
						)}
						<FileVualtDeleteBtn
							file={file}
							className={styles.icon}
							callback={onDelete}
							bin={tab === 'recycle'}
							permanently={tab === 'recycle'}
						/>
					</div>
				</div>

				{tab !== 'recycle' && (
					<div className={styles.section}>
						<div className={styles.title}>Preview</div>
						<div>
							{previewLoading ? (
								<CircularProgress />
							) : (
								<img
									alt="Preview"
									className={styles.previewImg}
									src={preview}
								/>
							)}
						</div>
					</div>
				)}

				<div className={styles.section}>
					<div className={styles.title}>Properties</div>
					<div>
						<Grid
							container
							spacing={2}
							className={styles.properties}
						>
							<Grid item md={3} className={styles.label}>
								Type
							</Grid>
							<Grid item md={9}>
								{getFileType(mimeType)}
							</Grid>

							<Grid item md={3} className={styles.label}>
								Size
							</Grid>
							<Grid item md={9}>
								{Math.round(fileSize / 1024)}KB
							</Grid>

							<Grid item md={3} className={styles.label}>
								Owner
							</Grid>
							<Grid item md={9}>
								{ownerName}
							</Grid>

							<Grid item md={3} className={styles.label}>
								Modified
							</Grid>
							<Grid item md={9}>
								{moment(updatedAt).format('MM/DD/YYYY')}
							</Grid>

							<Grid item md={3} className={styles.label}>
								Created
							</Grid>
							<Grid item md={9}>
								{moment(createdAt).format('MM/DD/YYYY')}
							</Grid>
						</Grid>
					</div>
				</div>
			</Grid>
		</Grid>
	);
}
