const getFileType = (mimeType) => {
	switch (mimeType) {
		case 'application/pdf':
			return 'PDF';
		case 'application/xls':
		case 'application/xlsx':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return 'Spreadsheet';
		default:
			return mimeType;
	}
};

const getFileTypeForViewer = (mimeType) => {
	switch (mimeType) {
		case 'application/pdf':
			return 'pdf';
		case 'application/xls':
		case 'application/xlsx':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return 'xlsx';
		default:
			return mimeType;
	}
};

const downloadFile = (blob, file) => {
	const blobUrl = window.URL.createObjectURL(new Blob([blob]));
	const link = document.createElement('a');
	link.href = blobUrl;
	link.setAttribute('download', file.name);

	// Append to html link element page
	document.body.appendChild(link);

	// Start download
	link.click();

	// Clean up and remove the link
	link.parentNode.removeChild(link);
};

export { getFileType, getFileTypeForViewer, downloadFile };
