import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { Search } from 'tabler-icons-react';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, useRef } from 'react';
import styles from './FileVaultSearch.module.scss';
import FileVaultService from '../../services/FileVaultService';

/* eslint-disable no-console */

export default function FileVaultSearch() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [phrase, setPhrase] = useState('');

	const timeoutRef = useRef();
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		if (phrase.length !== 0) {
			timeoutRef.current = setTimeout(() => {
				setLoading(true);
				FileVaultService.search(phrase).then(
					({ success, errorReason, error: { message } = {} }) => {
						setTimeout(() => {
							setLoading(false);
						}, 500);
						if (!success) {
							setError(errorReason || message);
						}
					},
				);
			}, 300);
		} else {
			FileVaultService.resetSearchState();
		}

		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [phrase]);

	return (
		<TextField
			placeholder={'Search File Vault'}
			className={styles.input}
			value={phrase}
			onChange={(e) => {
				setPhrase(e.target.value);
			}}
			helperText={error}
			error={!!error}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{!loading ? (
							<Search size={18} />
						) : (
							<CircularProgress size={18} />
						)}
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment
						position="end"
						onClick={() => setPhrase('')}
					>
						{phrase.length > 0 ? <CloseIcon size={18} /> : null}
					</InputAdornment>
				),
			}}
		/>
	);
}
