import { Link } from '@material-ui/core';
import styles from './Footer.module.scss';

export default function Footer() {
	return (
		<div className={styles.footer}>
			<div className={styles.links}>
				<div className={styles.item}>© 18-15 Holdings</div>
				<div className={styles.item}>
					<Link
						href="mailto:josiah.jennings@velaadvisors.com"
						className={styles.contact}
					>
						Contact
					</Link>
				</div>
			</div>
		</div>
	);
}
