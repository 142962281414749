function randomIntFromInterval(min, max) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export const MOCK_FUND_MAX = 10000000;

const generateMockFund = (a = 1, b = MOCK_FUND_MAX) => ({
	valuation: randomIntFromInterval(a, b),
	revenue: randomIntFromInterval(a, b),
	ebitda: randomIntFromInterval(a, b),

	// Per Josiah J, these are no longer relevant
	// contribution: randomIntFromInterval(1, 10000000),
	// unitContribution: randomIntFromInterval(1, 10000000),
	// daysCash: randomIntFromInterval(15, 100),

	equityInvstmnt: randomIntFromInterval(a, b),
	return: randomIntFromInterval(1, b),
	duration: randomIntFromInterval(20, 100),
});

const generateMockTotal = () => generateMockFund(1000000, 60000000);

const generateAxisLabels = () => {
	return [
		'Fund Inception',
		2019,
		2020,
		'Today',
		2022,
		2023,
		2024,
		2025,
		2026,
		2027,
		2029,
		'Target Projection',
	];
};

const generateMockDeals = (number) => {
	const deals = [];

	const statuses = ['Underwriting', 'Completed'];

	const periods = generateAxisLabels();

	for (let i = 0; i < number; i++) {
		let deal = {
			id: i === 0 ? 'total' : i,
			name: i === 0 ? 'Oak Quarry Overall' : `Generic Deal ${i}`,
			company: `Company Name ${i}`,
			status: statuses[randomIntFromInterval(0, statuses.length)],
			series: [
				{
					label: 'Projected Valuation',
					data: [
						2000000,
						5000000,
						3000000,
						5000000,
						7000000,
						8000000,
						15000000,
						21000000,
						28000000,
						38000000,
						48000000,
						58000000,
					],
					fill: '1',
					borderColor: '#CA8501',
					borderDash: [10, 5],
				},
				{
					label: 'Investment',
					data: [
						500000,
						2000000,
						1000000,
						2000000,
						3000000,
						4000000,
						5000000,
						6000000,
						8000000,
						11000000,
						14000000,
						21000000,
					],
					borderColor: '#4d878a',
				},
				{
					label: 'Valuation',
					data: [
						1000000,
						2000000,
						1000000,
						3000000,
						4000000,
						5000000,
						7000000,
						10000000,
						15000000,
						20000000,
						24000000,
						30000000,
					],
					borderColor: '#00539A',
				},
				{
					label: 'Total Return',
					data: [
						1000000,
						2000000,
						1000000,
						3000000,
						4000000,
						5000000,
						7000000,
						10000000,
						15000000,
						20000000,
						24000000,
						30000000,
					],
					borderColor: '#00539A',
				},
			],
			periods: {},
		};

		for (let j = 0; j < periods.length; j++) {
			deal.periods[periods[j]] = generateMockFund();
		}

		deals.push(deal);
	}

	return deals;
};

const generateMockFunds = (number) => {
	const funds = [];

	const periods = generateAxisLabels();

	for (let i = 0; i < number; i++) {
		let fund = {
			id: i === 0 ? 'total' : i,
			name: i === 0 ? 'Funds Overall' : `Generic Fund ${i}`,
			company: `Company Name ${i}`,
			periods: {},
		};

		for (let j = 0; j < periods.length; j++) {
			fund.periods[periods[j]] = generateMockFund();
			fund.periods[periods[j]].deals = generateMockDeals(2);
		}

		funds.push(fund);
	}

	return funds;
};

const copyAndModifyPeriod = (p, ratio) => {
	const copy = {};
	Object.entries(p).forEach(([key, value]) => {
		copy[key] = value * ratio;
	});
	return copy;
};

const copyAndModifyPeriodMap = (periods, ratio) => {
	const periodsCopy = {};
	Object.entries(periods).forEach(([periodKey, { deals, ...p }]) => {
		periodsCopy[periodKey] = {
			...copyAndModifyPeriod(p, ratio),
			// eslint-disable-next-line no-use-before-define
			deals: deals && deals.map((d) => modifyFakeDeal(d, ratio)),
		};
	});

	return periodsCopy;
};

export function modifyFakeDeal(deal, ratio) {
	const { periods, series } = deal || {};
	return {
		...deal,
		periods: copyAndModifyPeriodMap(periods, ratio),
		series: series.map(({ data, ...props }) => ({
			...props,
			data: data.map((d) => d * ratio),
		})),
	};
}

export const modifyFakeFund = (fund, ratio) => {
	const { periods } = fund || {};

	return {
		...fund,
		periods: copyAndModifyPeriodMap(periods, ratio),
	};
};

export {
	generateMockDeals,
	generateMockTotal,
	generateMockFunds,
	generateAxisLabels,
};
