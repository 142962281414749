import FunctionalState, {
	useFunctionalState,
} from 'shared/components/FunctionalState';
import ApiService from './ApiService';

/* eslint-disable no-console */
export default class FileVaultService {
	// User file vault
	static filesState = new FunctionalState({});

	/** Uploads File to user File Vault
	 * @param {File} file File to be uploaded
	 * @param {string} folderId File destination - folder id
	 * @returns { success: true } or { error: Error }
	 */
	static async uploadFile(file, folderId = 'root', tab = 'overview') {
		const result = await ApiService.uploadFile(file, folderId);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		const files = fileState.files || [];

		let newState = fileState;
		if (tab === 'overview') {
			newState = {
				...fileState,
				files: [...files, result],
			};
		} else {
			newState = {
				...fileState,
				investors: {
					...fileState.investors,
					files: [...(fileState.investors?.files || []), result],
				},
			};
		}

		this.updateFilesState(newState);

		return { success: true };
	}

	/** Moves file to the recycle bin
	 * @param {string} fileId File ID to be deleted
	 * @returns { success: true } or { error: Error }
	 */
	static async moveToRecycleBin(filesToDelete, permanently = false) {
		const result = await ApiService.deleteFiles(filesToDelete, permanently);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			// return { error: result, errorReason: message };
			throw new Error(message);
		}

		const fileState = this.filesState.getValue();
		const files = (fileState.files || []).filter(
			(f) => filesToDelete.indexOf(f.id) === -1,
		);

		const investorFiles = (fileState.investors?.files || []).filter(
			(f) => filesToDelete.indexOf(f.id) === -1,
		);

		let binFiles = fileState.bin?.files;
		if (permanently) {
			binFiles = fileState.bin.files.filter(
				(f) => filesToDelete.indexOf(f.id) === -1,
			);
		}

		this.updateFilesState({
			...fileState,
			files,
			bin: {
				...fileState.bin,
				files: binFiles,
			},
			investors: {
				...fileState.investors,
				files: investorFiles,
			},
		});

		return { success: true };
	}

	/** Moves file to the recycle bin
	 * @param {string} fileId File ID to be deleted
	 * @returns { success: true } or { error: Error }
	 */
	static async deleteFolder(folderId, permanently = false) {
		const result = await ApiService.deleteFolder(folderId, permanently);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		const folders = (fileState.folders || []).filter(
			(f) => f.id !== folderId,
		);

		this.updateFilesState({
			...fileState,
			folders,
		});

		return { success: true };
	}

	/** Gets content of the recycle bin
	 * @returns { success: true, data: { files: [], folders: []} } or { error: Error }
	 */
	static async getRecycleBin() {
		const result = await ApiService.getRecycleBin();
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { files, folders } = result;

		this.updateFilesState({
			bin: {
				files,
				folders,
			},
		});

		return { success: true };
	}

	/** Deletes all the files from the recycle bin
	 * @returns { success: true, data: { files: [], folders: []} } or { error: Error }
	 */
	static async emptyBin() {
		const result = await ApiService.emptyBin();
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		this.updateFilesState({
			...fileState,
			bin: {
				files: [],
			},
		});

		return { success: true };
	}

	/** Restores file from the recycle bin
	 * @returns { success: true, data: { files: [], folders: []} } or { error: Error }
	 */
	static async restore(fileId) {
		const result = await ApiService.restore(fileId);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		const files = (fileState.bin.files || []).filter(
			(f) => f.id !== fileId,
		);

		this.updateFilesState({
			...fileState,
			bin: {
				...fileState.bin,
				files,
			},
		});

		return { success: true };
	}

	/** Rename file
	 * @param {string} fileId file id
	 * @param {string} fileName new File name
	 * @returns { success: true } or { error: Error }
	 */
	static async renameFile(fileId, fileName) {
		const result = await ApiService.renameFile(fileId, fileName);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		const files =
			fileState.files?.map((f) => {
				if (f.id === fileId) {
					return { ...f, name: fileName };
				}

				return f;
			}) || [];

		const investorFiles =
			(fileState.investors?.files || []).map((f) => {
				if (f.id === fileId) {
					return { ...f, name: fileName };
				}

				return f;
			}) || [];

		this.updateFilesState({
			...fileState,
			files,
			investors: {
				...fileState.investors,
				files: investorFiles,
			},
		});

		return { success: true };
	}

	/** Rename folder
	 * @param {string} folderId folder id
	 * @param {string} name new Folder name
	 * @returns { success: true } or { error: Error }
	 */
	static async renameFolder(folderId, name) {
		const result = await ApiService.renameFolder(folderId, name);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		const folders =
			fileState.folders.map((f) => {
				if (f.id === folderId) {
					return { ...f, name };
				}

				return f;
			}) || [];

		this.updateFilesState({
			...fileState,
			folders,
		});

		return { success: true };
	}

	/**
	 * Gets files and folders
	 * @param {string} folderId Folder ID, if its not provided, then root
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async getDocumentsList(
		folderId = 'root',
		loadingInvestor,
		refreshState = true,
	) {
		const result = await ApiService.getDocumentsList(folderId);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { files, folders, recentFiles } = result;

		const data = { folderId, recentFiles, folders };
		if (loadingInvestor) {
			data.investors = {
				files,
				folders,
			};
		} else {
			data.files = files;
			data.folders = folders;
		}

		if (refreshState) {
			this.updateFilesState(data);
		}

		return { success: true, files, folders };
	}

	/**
	 * Gets files and folders
	 * @param {string} folderId Folder ID, if its not provided, then root
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async getInvestorsFileVaults(refreshState = true) {
		const result = await ApiService.getFoldersRoot();
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		if (refreshState) {
			this.updateFilesState({
				investors: {
					folders: result,
					files: [],
				},
			});
		}

		return { success: true, roots: result };
	}

	/**
	 * Gets files and folders
	 * @param {string} folderId Folder ID, if its not provided, then root
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async getFoldersList(folderId = 'root') {
		const result = await ApiService.getFolders(folderId);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true, folders: result };
	}

	/**
	 * Copy file
	 * @param {string} folderId Folder ID, if its not provided, then root
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async copy(files, folderId, name) {
		const result = await ApiService.copy(files, folderId, name);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true };
	}

	/**
	 * Move file
	 * @param {string} folderId Folder ID, if its not provided, then root
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async move(moveFiles, folderId, name) {
		const result = await ApiService.move(moveFiles, folderId, name);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const fileState = this.filesState.getValue();
		const files = fileState.files?.filter(
			(f) => moveFiles.indexOf(f.id) === -1,
		);

		const investorFiles =
			(fileState.investors?.files || []).filter(
				(f) => moveFiles.indexOf(f.id) === -1,
			) || [];

		this.updateFilesState({
			...fileState,
			files,
			investors: {
				...fileState.investors,
				files: investorFiles,
			},
		});

		return { success: true };
	}

	/**
	 * Gets file
	 * @param {string} documentId Document ID
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async getDocument(documentId, preview = false) {
		const result = await ApiService.getDocument(documentId, preview);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return result;
	}

	/**
	 * Gets file
	 * @param {string} search Search phrase
	 * @param {int} limit Limit results
	 * @param {int} skip Number of documents to skip
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async search(search, limit = 50, skip = 50) {
		const result = await ApiService.search(search, limit, skip);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { list } = result;

		const fileState = this.filesState.getValue();

		this.updateFilesState({
			...fileState,
			search: list,
		});

		return { success: true };
	}

	/**
	 * Gets files and folders
	 * @param {string} name Folder name
	 * @param {string} folderId Folder ID, if its not provided, then root
	 * @returns { success: true, data: { files: [], folders: []} } or {error: Error}
	 */
	static async createFolder(name, folderId) {
		const result = await ApiService.createFolder(
			name,
			folderId || this.filesState.getValue().currentFolder,
		);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		if (!folderId) {
			const fileState = this.filesState.getValue();
			const data = {
				...fileState,
				folders: [...fileState.folders, result],
			};

			if (fileState.investors && fileState.investors.folders) {
				data.investors.folders = [...data.investors.folders, result];
			}

			this.updateFilesState(data);
		}

		return { success: true, folder: result };
	}

	/**
	 *
	 * @param {object[]} files List of Files
	 * @param {object[]} recentFiles List of Recent files if parent folder is root
	 * @param {object[]} folders List of Folder
	 */
	static updateFilesState({
		folderId,
		files,
		recentFiles,
		folders,
		search,
		bin,
		investors,
		currentFolder,
	}) {
		this.filesState.setState({
			currentFolder: folderId || currentFolder,
			files,
			recentFiles,
			folders,
			search,
			bin,
			investors,
		});
	}

	static resetSearchState() {
		const fileState = this.filesState.getValue();
		this.updateFilesState({
			...fileState,
			search: undefined,
		});
	}
}

// Export our FileVault as a state
export const useFileVault = () => {
	return useFunctionalState(FileVaultService.filesState);
};
