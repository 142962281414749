import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import GuestFrame from '../GuestFrame/GuestFrame';
import Warning from '../../img/warning.svg';
import styles from './NotFound.module.scss';

export const NotFound = () => {
	const history = useHistory();
	return (
		<GuestFrame title={'Page not found'}>
			<div className={styles.container}>
				<img alt="Warning" src={Warning} />
				<h1>404 Page Not Found</h1>

				<p>
					We are sorry but the page you are looking for cannot be
					found.
				</p>

				<Button variant="contained" onClick={() => history.push('/')}>
					Go to Dashboard
				</Button>
			</div>
		</GuestFrame>
	);
};
