import { Button, Menu, MenuItem } from '@material-ui/core';
import { useState } from 'react';
import { Logout, Settings, ChevronDown, ChevronUp } from 'tabler-icons-react';
import { useHistory } from 'react-router-dom';
import AuthService, { useProfile } from '../../services/AuthService';
import styles from './UserDropdown.module.scss';

/* eslint-disable no-console */

export default function UserDropdown() {
	const profile = useProfile();
	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		AuthService.logout();
	};

	const handleSettings = () => {
		setAnchorEl(null);
		history.push('/settings');
	};

	return (
		<>
			<Button
				className={styles.button}
				id="basic-button"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={
					open ? <ChevronUp size={18} /> : <ChevronDown size={18} />
				}
			>
				{profile.user.name}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				className={styles.menu}
			>
				<MenuItem onClick={handleSettings}>
					<Settings size={16} /> Settings
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<Logout size={16} /> Logout
				</MenuItem>
			</Menu>
		</>
	);
}
