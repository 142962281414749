import { Tooltip, Typography } from '@material-ui/core';
import { InfoSquare, TrendingDown, TrendingUp } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { moneyNumberFormat } from '../../utils/numbers';
import styles from './DashboardKPI.module.scss';

export default function DashboardKPI({
	name,
	value,
	changeValue,
	changePercent,
	small,
}) {
	const [trendingSign, setTrendingSign] = useState('');

	useEffect(() => {
		if (changeValue === 0) {
			setTrendingSign('');
		} else {
			const sign = changeValue > 0 ? '+' : '-';
			setTrendingSign(sign);
		}
	}, [changeValue]);

	return (
		<div className={styles.kpi}>
			<label className={styles.name}>
				{name}{' '}
				<Tooltip title={`${name} KPI`}>
					<span>
						<InfoSquare size={14} className={styles.icon} />
					</span>
				</Tooltip>
			</label>
			<div className={styles.value}>
				<Typography
					className={small ? styles.smallValue : styles.value}
					fontFamily={'numbers'}
				>
					${moneyNumberFormat(value, 3)}
				</Typography>
			</div>
			{changeValue && changePercent && (
				<div
					className={classnames(
						styles.trending,
						changeValue >= 0 ? styles.up : styles.down,
					)}
				>
					<Typography
						className={styles.trending}
						fontFamily={'numbers'}
					>
						{changeValue >= 0 ? <TrendingUp /> : <TrendingDown />}{' '}
						{trendingSign}
						{moneyNumberFormat(changeValue, 3)} ({trendingSign}
						{changePercent}%)
					</Typography>
				</div>
			)}
		</div>
	);
}
