import {
	Grid,
	InputLabel,
	TextField,
	Checkbox,
	FormControlLabel,
	Link,
	Alert,
	AlertTitle,
	InputAdornment,
} from '@material-ui/core';
import { useState } from 'react';
import { Check } from 'tabler-icons-react';
import styles from './OnboardingForm.module.scss';
import LoadingButton from '../../LoadingButton/LoadingButton';
import AuthService, { useProfile } from '../../../services/AuthService';

export default function AccoutnCreation({ handleSuccess }) {
	const profile = useProfile();
	// const [agreedToPolicy, setAgreedToPolicy] = useState();
	const [agreedToMarketing, setAgreedToMarketing] = useState();
	const [user, setUser] = useState(profile?.user?.name);
	const [email, setEmail] = useState(profile?.user?.email);
	const [pass, setPass] = useState();
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const privacyPolicyLink = <Link>Privacy Policy</Link>;
	// const termsLink = <Link>Terms of Service</Link>;

	const validateEmail = (val) => {
		return String(val)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};
	const onLoginClick = () => {
		if (validateEmail(email)) {
			setLoading(true);
			AuthService.onboarding({
				name: user,
				password: pass,
				email,
				hasPrivacyAgreement: true,
				hasMarketingAgreement: !agreedToMarketing,
			}).then(({ success, errorReason, error: { message } = {} }) => {
				setLoading(false);
				if (success) {
					handleSuccess();
				}

				setError(errorReason || message);
			});
		} else {
			setError('Enter valid email address');
		}
	};

	const handleFormSubmit = (e) => {
		if (e.key === 'Enter' && user && pass) {
			onLoginClick();
		}
	};

	return (
		<Grid container spacing={4}>
			{error && (
				<Grid item xs={12} md={12}>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{error}
					</Alert>
				</Grid>
			)}
			<Grid item xs={12} md={12}>
				<InputLabel>Full name</InputLabel>
				<TextField
					placeholder={'Enter your full name'}
					onKeyDown={handleFormSubmit}
					onChange={(e) => {
						setUser(e.target.value);
					}}
					value={user}
					disabled={!!profile?.user?.name}
					InputProps={{
						endAdornment:
							user && user.length > 1 ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Email address</InputLabel>
				<TextField
					placeholder={'Enter your email address'}
					onKeyDown={handleFormSubmit}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					disabled={!!profile?.user?.email}
					value={email}
					InputProps={{
						endAdornment:
							email && validateEmail(email) ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Password</InputLabel>
				<TextField
					type={'password'}
					placeholder={'Enter your desired Password'}
					onKeyDown={handleFormSubmit}
					onChange={(e) => {
						setPass(e.target.value);
					}}
					data-test-id="login-button"
					InputProps={{
						endAdornment:
							pass && pass.length > 4 ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			{/* <Grid item xs={12} md={12}>
				<FormControlLabel
					className={styles.formLabel}
					control={<Checkbox />}
					onChange={(e) => setAgreedToPolicy(e.target.checked)}
					label={
						<>
							By checking this box you agree to our{' '}
							{privacyPolicyLink} and {termsLink}
						</>
					}
				/>
			</Grid> */}
			<Grid item xs={12} md={12}>
				<FormControlLabel
					className={styles.formLabel}
					control={<Checkbox />}
					onChange={(e) => setAgreedToMarketing(e.target.checked)}
					label={
						<>
							If this box is left unchecked, we will occasionally
							send helpful and relevant emails. You can
							unsubscribe at any time. Read our{' '}
							{privacyPolicyLink} for more details.
						</>
					}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<LoadingButton
					className={styles.loginBtn}
					variant="contained"
					onClick={onLoginClick}
					disabled={!(user && pass && email)}
					loading={loading}
					children={'Continue'}
				/>
			</Grid>
		</Grid>
	);
}
