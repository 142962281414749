import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, AlertTitle, Grid } from '@material-ui/core';
import AuthService from '../../services/AuthService';
import GuestFrame from '../GuestFrame/GuestFrame';

export default function AcceptInvite() {
	const history = useHistory();

	const [error, setError] = useState();
	const { salesForceId, inviteId } = useParams();

	useEffect(() => {
		AuthService.acceptInvite({ salesForceId, inviteId }).then(
			({ success, errorReason, error: { message } = {} }) => {
				if (success) {
					history.push('/onboarding');
				} else {
					setError(errorReason || message);
				}
			},
		);
	}, [salesForceId, history, inviteId]);

	return (
		<GuestFrame title={'Accept Invite'}>
			<Grid item xs={12} md={12}>
				{error && (
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{error}
					</Alert>
				)}
			</Grid>
		</GuestFrame>
	);
}
