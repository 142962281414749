import {
	Grid,
	InputLabel,
	TextField,
	Autocomplete,
	Alert,
	AlertTitle,
	InputAdornment,
} from '@material-ui/core';
import { useState } from 'react';
import { Check } from 'tabler-icons-react';
import styles from './OnboardingForm.module.scss';
import LoadingButton from '../../LoadingButton/LoadingButton';
import constants from '../../../utils/constants';
import AuthService from '../../../services/AuthService';

export default function AddressForm({ handleAddress: onSuccess }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const [address, setAddress] = useState({
		streetName: null,
		streetNumber: null,
		city: null,
		state: null,
		postalCode: null,
	});

	const handleStateChange = (e, state) => {
		const addressObj = { ...address };
		if (state) {
			addressObj.state = state.abbreviation;
		} else {
			addressObj.state = null;
		}

		setAddress(addressObj);
	};

	const handleAddress = () => {
		setLoading(true);
		AuthService.onboarding({
			address,
		}).then(({ success, errorReason, error: { message } = {} }) => {
			setLoading(false);
			if (success) {
				onSuccess();
			}

			setError(errorReason || message);
		});
	};

	return (
		<Grid container spacing={4}>
			{error && (
				<Grid item xs={12} md={12}>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{error}
					</Alert>
				</Grid>
			)}
			<Grid item xs={12} md={12}>
				<span>
					We will need the information below to create your new
					account
				</span>
			</Grid>
			<Grid item xs={12} md={8}>
				<InputLabel>Street name</InputLabel>
				<TextField
					placeholder={'Enter your street name'}
					onChange={(e) =>
						setAddress({ ...address, streetName: e.target.value })
					}
					InputProps={{
						endAdornment:
							address &&
							address.streetName &&
							address.streetName.length > 0 ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<InputLabel>Number</InputLabel>
				<TextField
					placeholder={'Number'}
					onChange={(e) =>
						setAddress({ ...address, streetNumber: e.target.value })
					}
					InputProps={{
						endAdornment:
							address && address.streetNumber > 0 ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>City</InputLabel>
				<TextField
					placeholder={'Enter your city'}
					onChange={(e) =>
						setAddress({ ...address, city: e.target.value })
					}
					InputProps={{
						endAdornment:
							address &&
							address.city &&
							address.city.length > 1 ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={8}>
				<InputLabel>State</InputLabel>
				<Autocomplete
					options={constants.states}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={'Select your state'}
						/>
					)}
					getOptionLabel={(option) => option.name}
					onChange={handleStateChange}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<InputLabel>Zip Code</InputLabel>
				<TextField
					placeholder={'Zip code'}
					onChange={(e) =>
						setAddress({ ...address, postalCode: e.target.value })
					}
					InputProps={{
						endAdornment:
							address && address.postalCode ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<LoadingButton
					className={styles.loginBtn}
					variant="contained"
					disabled={
						!(
							address.streetName &&
							address.streetNumber &&
							address.city &&
							address.state &&
							address.postalCode
						)
					}
					onClick={handleAddress}
					loading={loading}
					children={'Continue'}
				/>
			</Grid>
		</Grid>
	);
}
