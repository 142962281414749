import { Button, CircularProgress } from '@material-ui/core';

export default function LoadingButton({ loading, onClick, ...props }) {
	return loading ? (
		<Button disabled={true} {...props}>
			{props.children}&nbsp;&nbsp;
			<CircularProgress size={18} />
		</Button>
	) : (
		<Button {...props} onClick={onClick} />
	);
}
