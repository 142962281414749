import {
	TableCell,
	TableRow,
	IconButton,
	Typography,
	Link,
	CircularProgress,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import PEService from '../../services/PEService';
import { moneyNumberFormat } from '../../utils/numbers';
/* eslint-disable no-console */
export default function CollapsedFundRow({ fund, year }) {
	const [open, setOpen] = useState(false);
	const [fundDetails, setFundDetails] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		PEService.getFund(fund.id).then(
			({ success, errorReason, error: { message } = {}, result }) => {
				setLoading(false);
				if (!success) {
					setError(errorReason || message);
				} else {
					setFundDetails(result);
				}
			},
		);
	}, [fund]);

	const history = useHistory();
	return (
		<>
			<TableRow selected={open}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <ChevronUp /> : <ChevronDown />}
					</IconButton>
				</TableCell>
				<TableCell align="left">
					<Link
						href={`#/fund/${fund.id}`}
						onClick={(e) => {
							e.preventDefault();
							history.push(`/fund/${fund.id}`);
						}}
					>
						{fund.name}
					</Link>
				</TableCell>
				<TableCell align="right">
					<Typography fontFamily={'numbers'}>
						$
						{moneyNumberFormat(
							fund.kpiHighlights['Target Valuation'] &&
								fund.kpiHighlights['Target Valuation'][year],
							3,
						)}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography fontFamily={'numbers'}>
						$
						{moneyNumberFormat(
							fund.kpiHighlights.Revenue &&
								fund.kpiHighlights.Revenue[year],
							3,
						)}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography fontFamily={'numbers'}>
						$
						{moneyNumberFormat(
							fund.kpiHighlights['Target Earnings'] &&
								fund.kpiHighlights['Target Earnings'][year],
							3,
						)}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography fontFamily={'numbers'}>
						$
						{moneyNumberFormat(
							fund.kpiHighlights['Target Equity'] &&
								fund.kpiHighlights['Target Equity'][year],
							3,
						)}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography fontFamily={'numbers'}>
						$
						{moneyNumberFormat(
							fund.kpiHighlights['Total Return'] &&
								fund.kpiHighlights['Total Return'][year],
							3,
						)}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography fontFamily={'numbers'}>
						{moneyNumberFormat(
							fund.kpiHighlights['% Complete'] &&
								fund.kpiHighlights['% Complete'][year],
							3,
						)}
						%
					</Typography>
				</TableCell>
			</TableRow>
			{open ? (
				<>
					{error && (
						<TableRow>
							<TableCell>{error}</TableCell>
						</TableRow>
					)}
					{loading && (
						<TableRow>
							<TableCell>
								<CircularProgress />
							</TableCell>
						</TableRow>
					)}
					{fundDetails?.deals.map(({ deal }) => (
						<>
							{deal && (
								<TableRow key={deal.id} selected>
									<TableCell />
									<TableCell
										align="left"
										sx={{ paddingLeft: '40px' }}
										onClick={(e) => {
											e.preventDefault();
											history.push(`/deal/${deal.id}`);
										}}
									>
										<Link href="#">{deal.name}</Link>
									</TableCell>
									<TableCell align="right">
										<Typography fontFamily={'numbers'}>
											$
											{moneyNumberFormat(
												deal.kpiValues[year][
													'Target Valuation'
												],
												3,
											)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontFamily={'numbers'}>
											$
											{moneyNumberFormat(
												deal.kpiValues[year].Revenue,
												3,
											)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontFamily={'numbers'}>
											$
											{moneyNumberFormat(
												deal.kpiValues[year][
													'Target Earnings'
												],
												3,
											)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontFamily={'numbers'}>
											$
											{moneyNumberFormat(
												deal.kpiValues[year][
													'Target Equity'
												],
												3,
											)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontFamily={'numbers'}>
											$
											{moneyNumberFormat(
												deal.kpiValues[year][
													'Total Return'
												],
												3,
											)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontFamily={'numbers'}>
											{moneyNumberFormat(
												deal.kpiValues[year][
													'% Complete'
												],
												3,
											)}
											%
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</>
					))}
				</>
			) : (
				''
			)}
		</>
	);
}
