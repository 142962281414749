import GuestFrame from '../GuestFrame/GuestFrame';
import LoginForm from '../forms/LoginForm/LoginForm';

export default function LoginPage() {
	return (
		<GuestFrame title={'Login'}>
			<LoginForm />
		</GuestFrame>
	);
}
