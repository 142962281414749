import Head from 'components/Head';
import { Container, Box, Link } from '@material-ui/core';
import styles from './GuestFrame.module.scss';
import Logo from '../../img/1815N_logo.svg';
import Footer from '../Footer/Footer';
import CookieInfo from '../CookieInfo/CookieInfo';

/**
 * Simple frame for most app pages that keeps pages DRY.
 * Wraps the page in an `<GuestFrame>`, adds a `<Head>` for SEO,
 * places the `<MainNavigation>` at top, then renders any
 * children given.
 *
 * @param {string} props.title Page Title (for the <title> tag)
 * @param {*} props.children Child nodes to render on the page
 */
export default function GuestFrame({ title, children }) {
	return (
		<div className={styles.root}>
			<Head title={title} />
			<Container fixed className={styles.container}>
				<Box className={styles.logo}>
					<Link href="/">
						<img src={Logo} alt="Unknown moon" />
					</Link>
				</Box>
				{children}
			</Container>
			<Footer />
			<CookieInfo />
		</div>
	);
}
