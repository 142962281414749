import { CircleCheck } from 'tabler-icons-react';
import { Grid, Alert, AlertTitle } from '@material-ui/core';
import { useEffect, useState } from 'react';
import GuestFrame from '../GuestFrame/GuestFrame';
import styles from './DocusignConfirmation.module.scss';
import AuthService, { useProfile } from '../../services/AuthService';
/* eslint-disable no-console */
export default function DocusignConfirmation(props) {
	const profile = useProfile();

	const [error, setError] = useState();

	const {
		location: { search },
	} = props;

	const type = new URLSearchParams(search).get('type');
	const { documentSignatures } = profile.user;

	const oak = 'type1';
	const tax = 'type2';
	const w9 = 'type3';

	useEffect(() => {
		const data = documentSignatures;
		if (type === oak) {
			data.oak.signed = true;
		}
		if (type === tax) {
			data.tax.signed = true;
		}
		if (type === w9) {
			data.w9.signed = true;
		}

		AuthService.onboarding({
			documentSignatures: data,
		}).then(({ success, errorReason, error: { message } = {} }) => {
			if (!success) {
				setError(errorReason || message);
			}
		});
	}, [type, documentSignatures, oak, tax]);

	return (
		<GuestFrame title={'Document signed'}>
			<Grid container spacing={4}>
				<Grid item xs={12} md={12}>
					{error && (
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					)}
					<div className={styles.message}>
						Thank you! Document is signed, you can close this window
					</div>
				</Grid>
				<Grid item xs={12} md={12}>
					<div className={styles.finalIcon}>
						<CircleCheck size={74} />
					</div>
				</Grid>
			</Grid>
		</GuestFrame>
	);
}
