import { Trash } from 'tabler-icons-react';
import { useState } from 'react';
import { Modal, Box, Grid, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import styles from './FileVaultDeleteBtn.module.scss';
import FileVaultService from '../../services/FileVaultService';
import FileVaultToast from '../FileVaultToast/FileVaultToast';

/* eslint-disable no-console */
export default function FileVualtDeleteBtn(props) {
	const {
		file,
		folder,
		callback,
		long,
		label,
		permanently,
		files,
		folders,
		onSuccess,
		bin = false,
	} = props;
	const [open, setOpen] = useState(false);

	const deleteFile = () => {
		setOpen();
		if (callback) {
			callback();
		}
		if (file || files) {
			return toast.promise(
				FileVaultService.moveToRecycleBin(
					files || [file.id],
					permanently,
				),
				{
					pending: {
						icon: false,
						closeButton: false,
						render: () => {
							return (
								<FileVaultToast
									title="Deleting file"
									name={
										file
											? file.name
											: `${files.length} items`
									}
									mimeType={file?.mimeType}
									status="loading"
								/>
							);
						},
					},
					success: {
						render: () => {
							if (onSuccess) {
								onSuccess();
							}
							return (
								<FileVaultToast
									title="File deleted"
									name={
										file
											? file.name
											: `${files.length} items`
									}
									mimeType={file?.mimeType}
									status="finished"
								/>
							);
						},
						closeButton: false,
						icon: false,
						autoClose: 2000,
					},
					error: {
						closeButton: false,
						icon: false,
						autoClose: 4000,
						render: ({ errorReason, data: { message } = {} }) => {
							console.log(errorReason || message);
							return (
								<FileVaultToast
									title="Error"
									name={
										file
											? file.name
											: `${files.length} items`
									}
									mimeType={file?.mimeType}
									status="finished"
									error={errorReason || message}
								/>
							);
						},
					},
				},
				{
					position: 'bottom-right',
				},
			);
		}
		if (folder) {
			return toast.promise(
				FileVaultService.deleteFolder(folder.id, permanently),
				{
					pending: {
						icon: false,
						closeButton: false,
						render: () => {
							return (
								<FileVaultToast
									title="Deleting folder"
									name={
										folder
											? folder.name
											: `${folders.length} items`
									}
									status="loading"
									folder={true}
								/>
							);
						},
					},
					success: {
						render: () => {
							return (
								<FileVaultToast
									title="Folder deleted"
									name={
										folder
											? folder.name
											: `${folders.length} items`
									}
									status="finished"
									folder={true}
								/>
							);
						},
						closeButton: false,
						icon: false,
						autoClose: 2000,
					},
					error: {
						closeButton: false,
						icon: false,
						autoClose: 4000,
						render: ({ errorReason, error: { message } = {} }) => {
							return (
								<FileVaultToast
									title="Folder deleted"
									name={
										folder
											? folder.name
											: `${folders.length} items`
									}
									status="finished"
									error={errorReason || message}
									folder={true}
								/>
							);
						},
					},
				},
				{
					position: 'bottom-right',
				},
			);
		}
		return null;
	};

	return (
		<>
			{!long ? (
				<Trash {...props} onClick={() => setOpen(true)} />
			) : (
				<div
					className={styles.longDeleteBtn}
					onClick={() => setOpen(true)}
				>
					<Trash size={16} />
					{label || 'Remove'}
				</div>
			)}
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
				<Box className={styles.modal}>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12}>
							<h2>Delete?</h2>
						</Grid>
						<Grid item md={12} sm={12}>
							{bin ? (
								<>
									Are you sure to delete this file
									permanently?
								</>
							) : (
								<>
									Are you sure you want to send this item to
									the recycle bin?
								</>
							)}
						</Grid>
						<Grid item md={12} sm={12}>
							<div className={styles.btnGroup}>
								<Button
									variant={'outlined'}
									onClick={() => setOpen(false)}
								>
									Cancel
								</Button>
								<Button
									variant={'contained'}
									onClick={deleteFile}
								>
									Delete
								</Button>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}
