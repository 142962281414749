/* eslint-disable no-console */
import FunctionalState, {
	useFunctionalState,
} from 'shared/components/FunctionalState';
import { useEffect } from 'react';
import { stableDefer } from 'shared/utils/defer';
import { cache as swrCache } from 'swr';

// ES6 handles cycles just fine, and we need ApiService to verify token
// eslint-disable-next-line import/no-cycle
import ApiService from './ApiService';

/**
 * Keys to use for persisting data in localStorage between page loads
 */
const TOKEN_CACHE_KEY = `@um/auth-token`;
const PRE_LOGIN_PATH_KEY = `@um/pre-login-path`;

/**
 * Where to go after logging in if no previous page requested auth
 */
const DEFAULT_PRE_LOGIN_PATH = '/';

/**
 * NB: If expiration ever changes on the backend, make sure we update this
 */
const TOKEN_EXPIRATION_MINUTES = 60;

/**
 * Refresh tokens at X percent of TOKEN_EXPIRATION_MINUTES
 */
const TOKEN_EXPIRATION_RATIO = 0.9;

export default class AuthService {
	// React state to hold true/false for authorization state
	// Start as undefined so checkAuthorizationStatus knows to verify
	static isAuthorizedState = new FunctionalState(undefined);

	// Authenticated user's information, from the 'profile-service/profile' endpoint
	static profileState = new FunctionalState({});

	// Holds token so other services can use the token as a unique identifier for caching (like CartService)
	static tokenState = new FunctionalState(undefined);

	/**
	 * Gets the current value of the authorization flag (does not check with the server - use checkAuthorizationStatus for that)
	 * @returns true/false indicating if the user is authorized (logged in)
	 */
	static isAuthorized() {
		return this.isAuthorizedState.getValue();
	}

	/**
	 * Update the authorization state.
	 * @param {bool} loggedIn True/false indicating if user is logged in
	 */
	static setIsAuthorized(loggedIn) {
		this.isAuthorizedState.setValue(loggedIn);
	}

	/**
	 * Accepts invite
	 * @param {string} salesForceId Sales force ID
	 * @returns { success: true  or { error: Error }
	 */
	static async acceptInvite({ salesForceId, inviteId }) {
		const result = await ApiService.acceptInvite({
			salesForceId,
			inviteId,
		});
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { token } = result;

		this.setToken(token);
		this.setIsAuthorized(true);
		this.setProfile(result);

		return { success: true };
	}

	/** Onboarding process: configures user account, returns { user: Object } or  { error }
	 * @param {Object} params User parameters
	 * @returns { success: true } or { error: Error }
	 */
	static async onboarding(params) {
		const result = await ApiService.onboarding(params);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		// updates user profile
		const userProfile = this.profileState.getValue();
		userProfile.user = result;
		this.setProfile(userProfile);

		return { success: true };
	}

	/**
	 * Login to the server, get a token, returns { success } or { error }
	 * @param {string} user User name
	 * @param {string} pass Password
	 * @returns { success: true } or { error: Error }
	 */
	static async login(user, pass, rememberMe) {
		const result = await ApiService.login(user, pass, rememberMe);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			this.setIsAuthorized(false);
			return { error: result, errorReason: message };
		}

		const { token } = result;
		// todo: move below methods to the 2 factor auth section
		// after code is verified, then user is authorized
		if (!result.twoFactorToken) {
			this.setToken(token);
			this.setIsAuthorized(true);
			this.setProfile(result);
		}
		return {
			success: true,
			twoFactorToken: result.twoFactorToken,
			phoneNumber: result.phoneNumber,
		};
	}

	static async logout() {
		this.clearToken();
	}

	/**
	 * Sends password reset link to the user, returns { success } or { error }
	 * @param {string} email User email address
	 * @returns { success: true } or { error: Error }
	 */
	static async requestPasswordReset(email) {
		const result = await ApiService.requestPasswordReset(email);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true };
	}

	/**
	 * Reset users password, returns { success } or { error }
	 * @param {string} email User email address
	 * @param {string} pass New password
	 * @returns { success: true } or { error: Error }
	 */
	static async ressetPassword(id, tfaCode, pass) {
		const result = await ApiService.resetPassword(id, tfaCode, pass);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { token } = result;
		this.setToken(token);
		this.setIsAuthorized(true);
		this.setProfile(result);

		return { success: true };
	}

	/**
	 * Authneticates user after successful two factoe authorization, returns { success } or { error }
	 * @param {string} twoFactorToken Verification token
	 * @returns { success: true } or { error: Error }
	 */
	static async authenticateUser(twoFactorToken) {
		const result = await ApiService.authenticateUser(twoFactorToken);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { token } = result;
		this.setToken(token);
		this.setIsAuthorized(true);
		this.setProfile(result);

		return { success: true };
	}

	/**
	 * Reset users password, returns { userEmail } or { error }
	 * @param {string} token Reset token
	 * @returns { userEmail: string } or { error: Error }
	 */
	static async verifyResetToken(email, pass) {
		const result = await ApiService.verifyResetToken(email, pass);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { userEmail: result.email };
	}

	/**
	 * Utility to check local storage and if we already have a token, check with the server to see if the token is valid.
	 *
	 * Designed to be called multiple times with no effect - only the first time hits the server,
	 * after that, just returns the same true (assuming token is present and valid). If missing a token,
	 * will always return false. If token present but not valid, will try to verify every time and return false
	 * if token is still invalid until token is removed (via `clearToken` or server returns a valid response.)
	 *
	 * We hit the server every time if we have a token but it's not valid because anything COULD cause a temporary error,
	 * like network down, temporary API problems, etc. So we don't want to clear the token on first error so we
	 * don't force the user to reauth - we want to let them retry (even if that means reloading the page) without
	 * having to go thru the auth flow again - and we do that by preserving the token until we explicitly need to clear it
	 * on log out or some other logic TBD.
	 *
	 * @returns Promise that resolves to true or false. True only if we have a token AND the server has validated the token for this session
	 */
	static async checkAuthorizationStatus() {
		if (this.isAuthorized() === undefined) {
			const token = this.getToken();
			// No token? No need to check with the API, we know we're not authorized
			if (!token) {
				// Set to false instead of undefined so we don't have to wait for a response next time
				this.setIsAuthorized(false);
				return false;
			}

			// Guard with this promise so if checkAuthorizationStatus is called
			// multiple times while the API is responding, then we don't
			// make multiple calls to the API - each caller to this method
			// can wait on this same promise.
			if (this.authPromise) {
				return this.authPromise;
			}

			this.authPromise = stableDefer({
				timeoutErrorMessage: 'authPromise timeout',
			});

			// Token expires every TOKEN_EXPIRATION_MINUTES minutes, so refresh it if we can.
			// Returns false if token is invalid or any other token problems.
			// Returns null of no token present - but we shouldn't get here anyway if no token.
			const newToken = await this.refreshToken();
			if (!newToken) {
				// Error should have already been logged, so just handle quietly
				this.authPromise.resolve(false);

				// Set to false instead of undefined so we don't have to wait for a response next time
				this.setIsAuthorized(false);
				return false;
			}

			// Notify any callers that are waiting and clear the 'guard'
			this.authPromise.resolve(newToken);
			this.authPromise = null;

			// Update the React state var
			this.setIsAuthorized(true);
		}

		// Already authorized...
		return this.getToken();
	}

	/**
	 * Refreshes the old token and stores the new token
	 * @returns {string} Fresh token
	 */
	static async refreshToken() {
		const token = this.getToken();
		if (!token) {
			return null;
		}

		const refreshResult = await ApiService.verifyToken(token);

		if (refreshResult instanceof Error) {
			const { response: { status } = {} } = refreshResult;

			// 40X errors are unrecoverable errors, so just remove the token
			if (status >= 400 && status <= 499) {
				this.clearToken();
				return false;
			}

			// Not an expired token, log the info to the console
			// TBD Find better logging
			console.warn(`Got error from refresh:`, refreshResult);

			return false;
		}

		// Store token in this class and in localStorage (for page reloads)
		const { token: newToken } = refreshResult;
		this.setToken(newToken);
		this.setProfile(refreshResult);

		return newToken;
	}

	/**
	 * Sends two factor verification code to the end user
	 * @param {string} phone User phone number
	 * @returns { success: true } or { error: Error }
	 */
	static async onboardingVerifyPhone(phone) {
		const result = await ApiService.verifyPhoneStart(phone);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true };
	}

	/**
	 * Vrifies two factor authentication code for the onboarding
	 * @param {string} code Users code
	 * @returns { success: true } or { error: Error }
	 */
	static async onboardingVerifyCode(code, update = false) {
		const result = await ApiService.verifyOnboardingCode(code);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		if (!update) {
			// updates user profile
			this.setProfile(result);
		}

		return { success: true };
	}

	/**
	 * Sends two factor verification code to the end user
	 * @param {string} phone User phone number
	 * @returns { success: true } or { error: Error }
	 */
	static async sendVerificationCode(phone) {
		const result = await ApiService.sendVerificationCode(phone);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true, successToken: result.successToken };
	}

	/**
	 * Starts docusign process
	 * @param {string} phone User phone number
	 * @returns { success: true } or { error: Error }
	 */
	static async beginDocusign(docType, callback) {
		const result = await ApiService.beginDocusign(docType, callback);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { redirectUrl, documentId, documentType } = result;

		return {
			success: true,
			redirect: redirectUrl,
			documentId,
			type: documentType,
		};
	}

	/**
	 * Vrifies two factor authentication code
	 * @param {string} code Users code
	 * @returns { success: true } or { error: Error }
	 */
	static async verifyCode(twoFactorToken, code) {
		const result = await ApiService.verifyCode(twoFactorToken, code);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true, twoFactorToken: result.twoFactorToken };
	}

	/**
	 * Updates profile state
	 */
	static setProfile(profile) {
		this.profileState.setState(profile);
		console.log(`Current User:`, profile);
	}

	/**
	 * Set a cron that runs while page is loaded to keep token up to date, just a few minutes before it expires
	 */
	static startRefreshTimer() {
		setInterval(
			() => AuthService.refreshToken(),
			TOKEN_EXPIRATION_MINUTES * 60 * 1000 * TOKEN_EXPIRATION_RATIO,
		);
	}

	/**
	 * Returns current/stored token. No validity checks done. Use checkAuthorizationStatus() to ensure token is good.
	 * @returns Current token. Assumed to be valid.
	 */
	static getToken() {
		const token = this.tokenState.getValue();
		if (token) {
			return token;
		}

		const cachedToken = window.localStorage.getItem(TOKEN_CACHE_KEY);
		this.tokenState.setValue(cachedToken);
		return cachedToken;
	}

	/**
	 * Update the stored/cached token. Primarily internal use for AuthService
	 * @param {string} token New token
	 */
	static setToken(token) {
		window.localStorage.setItem(TOKEN_CACHE_KEY, token);
		this.tokenState.setValue(token);
	}

	/**
	 * Clear/remove the stored/cached token. Use to effect a "logout".
	 */
	static clearToken() {
		window.localStorage.removeItem(TOKEN_CACHE_KEY);
		window.localStorage.removeItem(PRE_LOGIN_PATH_KEY);
		this.tokenState.setValue(null);
		this.profileState.setValue({});
		// Add date to cache bust
		window.location.href = `/#/login?_=${Date.now()}`;
		// Force clear SWR cache
		swrCache.clear();
		// Because Jess still sees cache issues..
		window.location.reload();
	}

	/**
	 * Used by <AppRoute> to store path for returning to the private page
	 * upon successful login
	 * @param {string} currentPath Path
	 */
	static storePreLoginPath(currentPath) {
		this.preLoginPath = currentPath;
		window.localStorage.setItem(PRE_LOGIN_PATH_KEY, currentPath);
	}

	/**
	 * Used by LoginPage to know where to go
	 * @returns Last path required for login
	 */
	static getPreLoginPath() {
		if (this.preLoginPath) {
			return this.preLoginPath;
		}

		const cached = window.localStorage.getItem(PRE_LOGIN_PATH_KEY);
		if (cached) {
			return cached;
		}

		return DEFAULT_PRE_LOGIN_PATH;
	}
}

// Export our React hook to get authorization state
export const useIsAuthorized = (checkStatusIfUndefined = false) => {
	const value = useFunctionalState(AuthService.isAuthorizedState);

	useEffect(() => {
		if (checkStatusIfUndefined && value === undefined) {
			// This will change isAuthenticated from `undefined` to true/false, forcing re-render
			AuthService.checkAuthorizationStatus();
		}
	});

	return value;
};

// Export our profile as a state
export const useProfile = () => {
	return useFunctionalState(AuthService.profileState);
};

// Start the timer to keep tokens fresh. NOOP if no token or not logged in
AuthService.startRefreshTimer();

if (process.env.NODE_ENV === 'development') {
	window.AuthService = AuthService;
}
