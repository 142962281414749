import {
	Box,
	Grid,
	InputLabel,
	TextField,
	Link,
	Alert,
	AlertTitle,
	CircularProgress,
} from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import FormHeader from '../../FormHeader/FormHeader';
import LoadingButton from '../../LoadingButton/LoadingButton';
import styles from './RequestPasswordResetForm.module.scss';

export default function RequestPasswordResetForm() {
	const history = useHistory();

	const [email, setEmail] = useState();
	const [sent, setSent] = useState();
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [newEmail, setNew] = useState(false);
	const [count, setCount] = useState(0);

	const onResetClick = () => {
		setError(null);
		setLoading(true);
		// TODO: Show some sort of loading spinner while waiting on backend
		AuthService.requestPasswordReset(email).then(
			({ success, errorReason, error: { message } = {} }) => {
				const c = count + 1;
				setCount(c);
				setLoading(false);
				if (c > 1) {
					setNew(true);
					setTimeout(() => {
						setNew(false);
					}, 5000);
				}
				if (success) {
					setSent(true);
				}
				setError(errorReason || message);
			},
		);
	};

	const backToLogin = (e) => {
		e.preventDefault();
		history.push('/login');
	};

	return (
		<Box fixed className={styles.formContainer}>
			<FormHeader
				title={!sent ? 'Reset your Password' : 'Check your email'}
			/>
			<Grid container spacing={4}>
				{error && (
					<Grid item xs={12} md={12}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					</Grid>
				)}
				{!sent && (
					<>
						<Grid item xs={12} md={12}>
							<p>
								Enter the email address associated with your
								account and we'll send you a link to reset your
								password.
							</p>
						</Grid>
						<Grid item xs={12} md={12}>
							<InputLabel>Email</InputLabel>
							<TextField
								placeholder={'Type your email address'}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<LoadingButton
								className={styles.loginBtn}
								variant="contained"
								onClick={onResetClick}
								disabled={!email}
								loading={loading}
							>
								Continue
							</LoadingButton>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							textAlign={'center'}
							fontWeight={700}
						>
							<Link href={'#'} onClick={(e) => backToLogin(e)}>
								Back to Log in
							</Link>
						</Grid>
					</>
				)}
				{sent && (
					<Grid item xs={12} md={12}>
						<p>
							We sent you an email with instructions on how to
							reset your password.
						</p>
						<p>
							Didn’t get the email? Check spam folder or{' '}
							<Link
								href={'#'}
								disabled={loading}
								onClick={(e) => {
									e.preventDefault();
									if (!loading) {
										onResetClick();
									}
								}}
							>
								resend{' '}
								{loading && <CircularProgress size={14} />}
							</Link>
						</p>
						{newEmail && !loading && (
							<p>
								<strong>New email has been sent!</strong>
							</p>
						)}
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
