/* eslint-disable no-console */
// Simple fake token generator for predictable string prefix
const newToken = () => `fakeToken:${Date.now()}`;

// Verify token is valid - used by assertAuthorized and verifyToken
const isValidToken = (token) => `${token}`.startsWith(`fakeToken:`);

// Verify token is valid - used by two factor authentication
const isValidTwoFactorToken = (token) => `${token}`.startsWith(`000000`);

// Yes, SUPER FAKE user/pass
export const MOCK_ADMIN_USER = 'admin';
export const MOCK_ADMIN_PASS = 'admin';

// Utility to verify token is present and valid in headers - throws if invalid or not present
const assertAuthorized = ({ headers: { Authorization: rawToken } = {} }) => {
	const token = `${rawToken}`.replace('Bearer ', '');
	if (token && isValidToken(token)) {
		return;
	}

	// Add token to error just for debugging - this is a mock response after all
	throw new Error(
		`Request not authorized - token is invalid or missing (${token})`,
	);
};

/**
 * Generic fake responses for all our API calls so we can develop and/or test without having a real backend.
 *
 * VERY IMPORTANT: When backend is established, review response/params for all mocked endpoints
 * and modify methods below to match
 */
export default class ApiMockResponder {
	static respond(method, endpoint, data, options) {
		const pair = `${method}:${endpoint}`;
		switch (pair) {
			case 'POST:auth/login':
				return this.loginResponse(data, options);
			case 'POST:auth/verify':
				return this.verifyToken(data, options);
			case 'POST:auth/request-password-reset':
				return this.requestPasswordReset(data, options);
			case 'POST:auth/verify-reset-token':
				return this.verifyResetToken(data, options);
			case 'POST:auth/reset-password':
				return this.resetPassword(data, options);
			case 'POST:auth/send-two-factor-code':
				return this.sendVerifyCode(data, options);
			case 'POST:auth/verify-two-factor-code':
				return this.verifyTwoFactorCode(data, options);
			case 'POST:auth/authenticate-user':
				return this.authenticateUser(data, options);
			default:
				break;
		}

		throw new Error(`Mock responder for '${pair}' not defined`);
	}

	static loginResponse({ email, password }) {
		if (email === MOCK_ADMIN_USER && password === MOCK_ADMIN_PASS) {
			return {
				twoFactorToken: newToken(),
				phoneNumber: '999 - 999 - 999',
			};
		}

		throw new Error('Incorrect email or password');
	}

	static authenticateUser({ twoFactorToken }) {
		if (isValidToken(twoFactorToken)) {
			return { token: newToken(), name: 'John Doe' };
		}

		throw new Error('Error during user authentication');
	}

	static verifyToken({ token }) {
		if (isValidToken(token)) {
			return {
				token: newToken(),
				name: 'John Doe',
			};
		}

		throw new Error('Invalid token');
	}

	static refreshToken({ token }) {
		if (isValidToken(token)) {
			return { token: newToken() };
		}

		throw new Error('Invalid token');
	}

	static requestPasswordReset({ email }) {
		if (email === MOCK_ADMIN_USER) {
			return { success: true };
		}

		throw new Error('Incorrect email address');
	}

	static verifyResetToken({ token }) {
		if (isValidToken(token)) {
			return { data: newToken() };
		}

		throw new Error('Invalid token');
	}

	static resetPassword({ email }) {
		if (email === MOCK_ADMIN_USER) {
			return {
				token: newToken(),
				name: 'John Doe',
			};
		}

		throw new Error('Incorrect email address');
	}

	static sendVerifyCode({ phone }) {
		if (phone) {
			return { success: true };
		}

		throw new Error('Invalid user/pass');
	}

	static verifyTwoFactorCode({ code }) {
		if (isValidTwoFactorToken(code)) {
			return { twoFactorToken: newToken() };
		}

		throw new Error('Invalid two factor code');
	}

	// Just illustrates how to use assertAuthorized, that's all
	static sampleSecureResponder(data, options) {
		assertAuthorized(options);
		return { sample: true };
	}

	static getVendors(data, options) {
		assertAuthorized(options);
		return {
			metadata: {
				value: {
					total: 1,
					currentStartIndex: 0,
					currentEndIndex: 0,
				},
				empty: false,
				defined: true,
			},
			data: [
				{
					id: 1,
					createdDate: 'string',
					discountSpecificUnitAmount: 0,
					discountPercentage: 0,
					isEntirePlanet: true,
					hasCountryFilter: true,
					hasSubregionFilter: true,
					hasCityFilter: true,
					isDeleted: true,
					name: 'string',
					provider: 'string',
					systemModstamp: 'string',
					vendorId: 'string',
					zipCode: 'string',
					hcLastop: 'string',
					hcErr: 'string',
				},
			],
		};
	}
}
