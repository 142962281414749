import { CircularProgress } from '@material-ui/core';
import { Check, X, FileAlert, Folder } from 'tabler-icons-react';
import FileIcon from '../FileIcon/FileIcon';
import styles from './FileVaultToast.module.scss';

export default function FileVaultToast({
	title,
	name,
	status,
	mimeType,
	closeToast,
	error,
	folder,
}) {
	return (
		<div className={styles.toast}>
			<div className={styles.title}>
				{title}
				<div className={styles.right}>
					<X size={18} onClick={closeToast} />
				</div>
			</div>
			<div className={styles.file}>
				{folder ? <Folder /> : <FileIcon mimeType={mimeType} />}{' '}
				<div className={styles.filename}>
					{name}{' '}
					{error && (
						<span className={styles.error}>
							<br />
							{error}
						</span>
					)}
				</div>
				<div className={styles.right}>
					{error ? (
						<FileAlert size={20} className={styles.error} />
					) : (
						<>
							{status === 'finished' ? (
								<Check size={20} className={styles.check} />
							) : (
								<CircularProgress size={20} thickness={5} />
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
