/* eslint-disable react-hooks/exhaustive-deps */
import { Link, Chip } from '@material-ui/core';
import { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paperclip, X } from 'tabler-icons-react';
import styles from './FileUploader.module.scss';

export default function FileUploader({ onDrop: callback }) {
	const [acceptedFiles, setAcceptedFiles] = useState([]);

	const onDrop = useCallback(
		(files) => {
			setAcceptedFiles(acceptedFiles.concat(files));
		},
		[acceptedFiles],
	);
	const { getRootProps, getInputProps } = useDropzone({
		multiple: true,
		onDrop,
	});

	useEffect(() => {
		if (callback) {
			callback(acceptedFiles);
		}
	}, [acceptedFiles]);

	const handleDeleteFile = (path) => {
		setAcceptedFiles(acceptedFiles.filter((item) => item.path !== path));
	};

	return (
		<section className="container">
			<div {...getRootProps({ className: styles.dropzone })}>
				<div className={styles.files}>
					{acceptedFiles.map((file, idx) => (
						<Chip
							className={styles.uploadedFile}
							label={file.path}
							key={idx}
							onDelete={() => handleDeleteFile(file.path)}
							deleteIcon={<X size={14} />}
						/>
					))}
				</div>
				<p className={styles.description}>
					<input {...getInputProps()} />
					<Paperclip className={styles.icon} />{' '}
					<Link href={'#'} onClick={(e) => e.preventDefault()}>
						Browse for Files
					</Link>{' '}
					or drag them here
				</p>
			</div>
		</section>
	);
}
