import {
	Box,
	Grid,
	InputLabel,
	TextField,
	Alert,
	AlertTitle,
	Autocomplete,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useProfile } from '../../../services/AuthService';
import UserService from '../../../services/UserService';
import LoadingButton from '../../LoadingButton/LoadingButton';
import styles from './AddNewUserForm.module.scss';

export default function AddNewUserForm({ onSuccess }) {
	const [user, setUser] = useState();
	const [email, setEmail] = useState();
	const [role, setRole] = useState();
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const profile = useProfile();

	const onCreateAccountClick = () => {
		setError(null);
		setLoading(true);
		// request to backend
		UserService.createUser({
			name: user,
			email,
			accountId: profile.account.id,
			isAdmin: role === 'Admin',
			isClient: role === 'Client',
		}).then(({ success, errorReason, error: { message } = {} }) => {
			setLoading(false);
			if (!success) {
				setError(errorReason || message);
			} else {
				onSuccess();
			}
		});
	};

	useEffect(() => {
		return function cleanup() {
			setUser(null);
			setEmail(null);
			setRole(null);
		};
	}, []);

	const handleFormSubmit = (e) => {
		if (e.key === 'Enter' && user && email && role) {
			onCreateAccountClick();
		}
	};

	return (
		<Box fixed className={styles.formContainer}>
			<Grid container spacing={4}>
				{error && (
					<Grid item xs={12} md={12}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					</Grid>
				)}
				<Grid item xs={12} md={12}>
					<InputLabel>Name</InputLabel>
					<TextField
						placeholder={'Type user name'}
						onKeyDown={handleFormSubmit}
						onChange={(e) => {
							setUser(e.target.value);
							setError(false);
						}}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<InputLabel>Email</InputLabel>
					<TextField
						placeholder={'Email address'}
						onKeyDown={handleFormSubmit}
						onChange={(e) => {
							setEmail(e.target.value);
							setError(false);
						}}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<InputLabel>Role</InputLabel>
					<Autocomplete
						disablePortal
						options={['Admin', 'Client']}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={'Select user role'}
							/>
						)}
						onChange={(e, value) => {
							setRole(value);
						}}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<LoadingButton
						className={styles.loginBtn}
						variant="contained"
						onClick={onCreateAccountClick}
						disabled={!(user && email && role)}
						loading={loading}
						children={'Send invitation'}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
