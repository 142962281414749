/* eslint-disable no-console */
import axios from 'axios';
import { PublicAppConfig } from 'shared/config';
import DeviceInfo from 'shared/utils/DeviceInfo';
import ApiMockResponder from './ApiMockResponder';

// ES6 handles cycles just fine, and we need AuthService to verify authorization status
// eslint-disable-next-line import/no-cycle
import AuthService from './AuthService';

/**
 * The base of our API calls.
 */
const API_ROOT = `https://${PublicAppConfig.apiHost}`;

/**
 * Contains all the API requests wrapped in convenient functions to process data to/from the backend.
 */
export default class ApiService {
	/**
	 * If true, requests will NOT hit the backend (API_ROOT), but instead
	 * be handled client-side by `ApiMockResponder`.
	 * I put this as member of ApiService rather than a top-level const variable
	 * so that in the future it could be set to explicitly true for testing even
	 * after we have a working backend and we put this as false for normal operations.
	 */
	static UseMocks = false;

	/** Accepts user invite
	 * @param {string} salesForceId Sales Force ID
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static acceptInvite = async ({ salesForceId, inviteId }) =>
		this.post(
			'auth/accept-invite',
			{
				salesForceId,
				inviteId,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/** Starts phone verifications
	 * @param {number} phoneNum Phone number
	 * @returns Object shaped like { success: true } or Error instance
	 */
	static verifyPhoneStart = async (phoneNum) =>
		this.post('onboarding/verify-phone/start', {
			phoneNum,
		});

	/** Starts phone verifications
	 * @param {number} phoneNum Phone number
	 * @returns Object shaped like { success: true } or Error instance
	 */
	static verifyOnboardingCode = async (tfaCode) =>
		this.post('onboarding/verify-phone/verify', {
			tfaCode,
		});

	/** Onboarding process
	 * @param {Object} params User params, such as First name, invester experience etc.
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static onboarding = async (params) =>
		this.post('onboarding', {
			...params,
		});

	/**
	 * Login to the server
	 * @param {string} email Email
	 * @param {string} password Password
	 * @param {boolean} rememberMe Remember me
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static login = async (email, password, rememberMe) =>
		// request() will fail this request if requireToken is not set to false because
		// obviously the user needs to login to have a token
		this.post(
			'auth/login',
			{
				email,
				password,
				rememberMe,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Request to reset a password
	 * @param {string} email Email
	 * @returns { success: true } or { error: Error }
	 */
	static requestPasswordReset = async (email) =>
		this.post(
			'auth/password-reset/start',
			{
				email,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Reset user password
	 * @param {string} email Email
	 * @param {string} pass User new password
	 * @returns { success: true } or { error: Error }
	 */
	static resetPassword = async (userId, tfaCode, password) =>
		this.post(
			'auth/password-reset/finish',
			{
				userId,
				tfaCode,
				password,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Verifies the given reset token is valid
	 * @param {string} token Token to verify
	 * @returns {object} like `{
	 *	"email": "string",
	 *	}`
	 */
	static verifyResetToken = async (token) =>
		this.post(
			'auth/verify-reset-token',
			{ token, deviceInfo: await DeviceInfo.getDeviceInfo() },
			{ requireToken: false },
		);

	/**
	 * Verifies the given token is valid and not expired
	 * @param {string} token Token to verify
	 * @returns {object} like `{
	 *	"userId": "string",
	 *	"role": {}
	 *	}`
	 */
	static verifyToken = async (token) =>
		// NOTE: VERY important that requireToken is false here,
		// because otherwise this will cause a cyclical loop because request() calls AuthService.checkAuthorizationStatus() internally,
		// which then calls this method verifyToken(), which calls request() - which calls checkAuthorizationStatus if requireToken is true
		this.post(
			'auth/verify',
			{ token, deviceInfo: await DeviceInfo.getDeviceInfo() },
			{ requireToken: false },
		);

	static sendVerificationCode = async (twoFactorToken) =>
		this.post(
			'auth/send-two-factor-code',
			{ twoFactorToken, deviceInfo: await DeviceInfo.getDeviceInfo() },
			{ requireToken: false },
		);

	static beginDocusign = async (documentType, returnUrl) =>
		this.get('docusign/begin', {
			documentType,
			returnUrl,
		});

	/**
	 * Verifies the given code is valid
	 * @param {string} phone Phone number
	 * @param {string} code Token to verify
	 * @returns {object} like `{
	 *	"email": "string",
	 *	}`
	 */
	static verifyCode = async (phone, code) =>
		this.post(
			'auth/verify-two-factor-code',
			{ phone, code, deviceInfo: await DeviceInfo.getDeviceInfo() },
			{ requireToken: false },
		);

	/**
	 * Authenticates user after two factor code verification
	 * @param {string} token Two factor authentication token
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static authenticateUser = async (twoFactorToken) =>
		// request() will fail this request if requireToken is not set to false because
		// obviously the user needs to login to have a token
		this.post(
			'auth/authenticate-user',
			{
				twoFactorToken,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Retrieves paginated list of vendors from the `vendor-service`. It is up to the caller to handle pagination.
	 * @param {Object} params See swagger docs for parameter documentation
	 * @returns See swagger docs for response documentation
	 */
	static getVendors = ({
		offset = 0,
		limit = 50,
		isSortAscending = true,
		sortCriteria = 'name',
	}) =>
		this.get('vendor-service/marketplace', {
			offset,
			limit,
			isSortAscending,
			sortCriteria,
		});

	/** Uploads file to the user file vault
	 * @param {File} file File object
	 * @param {string} folderId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static uploadFile = async (file, folderId) => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('folderId', folderId);
		return this.post('documents/upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	/** Renames file
	 * @param {string} folderId Folder ID
	 * @param {string} name New file name
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static renameFile = async (documentId, name) => {
		return this.post('documents/rename', {
			documentId,
			name,
		});
	};

	/** Renames folder
	 * @param {string} folderId Folder ID
	 * @param {string} name Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static renameFolder = async (folderId, name) => {
		return this.post('documents/folders/rename', {
			folderId,
			name,
		});
	};

	/** Deletes file from the CDN network
	 * @param {string} folderId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static deleteFiles = async (documentIdList, skipRecycleBin) => {
		return this.post('documents/delete', {
			documentIdList,
			skipRecycleBin,
		});
	};

	/** Deletes folder from the CDN network
	 * @param {string} folderId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static deleteFolder = async (folderId, skipRecycleBin) => {
		return this.post('documents/folders/delete', {
			folderId,
			skipRecycleBin,
		});
	};

	/** Gets content of the recycle bin
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getRecycleBin = async () => {
		return this.get('documents/recycle-bin');
	};

	/** Removes all the files from the bin
	 * @returns Object shaped like { success: <bool> } or Error instance
	 */
	static emptyBin = async () => {
		return this.post('documents/recycle-bin/empty');
	};

	/** Restore file from the recycle bin
	 * @returns Object shaped like { success: <bool> } or Error instance
	 */
	static restore = async (documentId) => {
		return this.post('documents/recycle-bin/restore', { documentId });
	};

	/** Gets files and folders for the given folder
	 * @param {string} folderId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getDocumentsList = async (folderId) => {
		return this.get('documents/list', {
			folderId,
		});
	};

	/** Get folders root for the Admin
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getFoldersRoot = async () => {
		return this.get('documents/folders/roots');
	};

	/** Gets folders for the given folder
	 * @param {string} folderId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getFolders = async (folderId) => {
		return this.get('documents/folders', {
			parentFolderId: folderId,
		});
	};

	/** Copy file
	 * @param {string} fileId File ID
	 * @param {string} folderId Folder ID
	 * @param {string} name New file name
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static copy = async (documentIdList, folderId, name) => {
		return this.post('documents/copy', {
			documentIdList,
			folderId,
			name,
		});
	};

	/** Move file
	 * @param {string} fileId File ID
	 * @param {string} folderId Folder ID
	 * @param {string} name New file name
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static move = async (documentIdList, folderId, name) => {
		return this.post('documents/move', {
			documentIdList,
			folderId,
			name,
		});
	};

	/** Gets file
	 * @param {string} documentId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getDocument = async (documentId, preview = false) => {
		const params = {
			documentId,
		};
		if (preview) {
			params.preview = true;
		}
		return this.get('document', params);
	};

	/** Creates new Folder
	 * @param {string} name Folder name
	 * @param {string} folderId Folder ID
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static createFolder = async (name, parentFolderId) => {
		return this.post('documents/folders', {
			name,
			parentFolderId,
		});
	};

	/** ------------- USER MANAGEMENT ROUTES ------------ */

	/** Gets list of users
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getUsersList = async () => {
		return this.get('user-admin');
	};

	/** Creates new user
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static createUser = async ({
		name,
		email,
		accountId,
		isAdmin,
		isClient,
		isAdvisor,
	}) => {
		return this.post('user-admin/create-user', {
			name,
			email,
			accountId,
			isAdmin,
			isClient,
			isAdvisor,
		});
	};

	static updateUser = async (id, params) => {
		return this.post('user-admin/update-user', { ...params, userId: id });
	};

	/** ------------ Private Equity Dashboard ------------ */

	/** Gets dashboard data
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getPEDashboard = async () => {
		return this.get('eq-dash/master');
	};

	/** Gets deal info
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getDeal = async (dealId) => {
		return this.get('eq-dash/deal', {
			dealId,
		});
	};

	/** Gets deal info
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static getFund = async (fundId) => {
		return this.get('eq-dash/fund', {
			fundId,
		});
	};

	/** Gets estimation calculation */
	static getEstimation = async (amount) => {
		return this.get('eq-dash/estimate', {
			amount,
		});
	};

	/** Filve vault search
	 * @param {string} search Search phrase
	 * @param {int} limit Limit result
	 * @param {int} skip Number of documents to skip
	 * @returns Object shaped like { name: <string> } or Error instance
	 */
	static search = async (search, limit, skip) => {
		return this.get('documents/search', {
			search,
			limit,
			skip,
		});
	};

	/**
	 * Get notifications for this user from the backend.
	 * @note This endpoint DOES NOT EXIST YET. This is theoretical at this point.
	 * @param {Object} params See swagger docs for parameter documentation
	 * @returns See swagger docs for response documentation
	 * @see Docs TBD
	 */
	static getNotifications = () => {
		// This is just sample data until an endpoint exists
		return [
			{
				description: 'View newly-added selections.',
				group: null,
				icon: null,
				id: 'c72e4216-1b5e-4330-809d-5b7024384c72',
				label: 'New benefits!',
			},
			{
				description: 'Complete your profile.',
				group: null,
				icon: 'coin',
				id: 'd3aa6be5-1688-4be2-909b-bddae4341db0',
				label: 'Earn points',
			},
		];

		// return this.get('notifications');
	};

	/**
	 * This is a simple generic requestor that wraps axios so we can encode the query string for GET requests and catch errors.
	 * TODO: Once we establish our auth method with backend, we can also auto-add the token here from the AuthService.
	 *
	 * @param {string} method Standard HTTP Method like GET/PUT/POST/PATCH/DELETE etc
	 * @param {string} endpoint [required] API endpoint (don't include anything from API_ROOT, like '/api/v1', etc)
	 * @param {object} data [optional] Object containing values to GET or POST, etc. request() will URL-encode for GET
	 * @param {object} options [optional] Options to pass to the underlying axios request - see axios docs for valid options
	 * @param {boolean} options.requireToken [optional] Defaults to `true`. This option is not passed to axios. It's enforced in request(),
	 * 	and if set to `true`, it will wait for AuthService to validate and check the token and error out if AuthService doesn't return a success response.
	 * @returns The `data` property from the axios response or an `Error` object if there was an exception. TBD if we want different error handling
	 */
	static async request(
		method,
		endpoint,
		data = {},
		{ requireToken = true, ...options } = {},
	) {
		let url = `${API_ROOT}/${endpoint}`;
		if (method === 'GET' && Object.keys(data).length) {
			url += `?${new URLSearchParams(data).toString()}`;
		}
		try {
			if (requireToken) {
				// Note as docs for checkAuthorizationStatus say, this only hits the backend
				// once to validate the token if we have a token. If no token stored,
				// or the token is invalid, it returns false every time until valid token (login)
				const token = await AuthService.checkAuthorizationStatus();
				if (!token) {
					// eslint-disable-next-line no-console
					console.error(
						`No token/not authorized, cannot request ${url}`,
					);
					return new Error(`Not authorized`);
				}

				// TBD expected backend header for token
				const headers = { Authorization: `${token}` };

				// Add our auth header OVER TOP OF any headers given in options
				const { headers: userHeaders } = options;
				Object.assign(options, {
					headers: { ...(userHeaders || {}), ...headers },
				});
			}

			if (ApiService.UseMocks) {
				const result = await ApiMockResponder.respond(
					method,
					endpoint,
					data,
					options,
				);
				// // eslint-disable-next-line no-console
				// console.warn(
				// 	`[MOCK RESPONSE] input:`,
				// 	{ method, endpoint, data, options },
				// 	`output:`,
				// 	result,
				// );
				return result;
			}

			const { data: result } = await axios({
				method,
				url,
				data: method === 'GET' ? undefined : data,
				...options,
			});
			return result;
		} catch (ex) {
			// TBD: Better error logging/handling
			// eslint-disable-next-line no-console
			console.error(
				`Error requesting ${url}`,
				ex,
				// ex && ex.response && ex.response.data,
			);
			return ex;
		}
	}

	/**
	 * Alias to `request()` with method set to GET
	 * @param {string} endpoint See request docs
	 * @param {object} data See request docs
	 * @param {object} options See request docs
	 * @returns See request docs
	 * Note: This code explicitly calls out args so IntelliSense in VSCode can derive expected args for code completion
	 */
	static get = (
		endpoint,
		data = {},
		{ requireToken = true, ...options } = {},
	) => this.request('GET', endpoint, data, { requireToken, ...options });

	/**
	 * Alias to `request()` with method set to POST
	 * @param {string} endpoint See request docs
	 * @param {object} data See request docs
	 * @param {object} options See request docs
	 * @returns See request docs
	 * Note: This code explicitly calls out args so IntelliSense in VSCode can derive expected args for code completion
	 */
	static post = (
		endpoint,
		data = {},
		{ requireToken = true, ...options } = {},
	) => this.request('POST', endpoint, data, { requireToken, ...options });

	/**
	 * Alias to `request()` with method set to PUT
	 * @param {string} endpoint See request docs
	 * @param {object} data See request docs
	 * @param {object} options See request docs
	 * @returns See request docs
	 * Note: This code explicitly calls out args so IntelliSense in VSCode can derive expected args for code completion
	 */
	static put = (
		endpoint,
		data = {},
		{ requireToken = true, ...options } = {},
	) => this.request('PUT', endpoint, data, { requireToken, ...options });
}
