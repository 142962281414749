import FileVualtCopyBtn from '../FileVaultCopyBtn/FileVaultCopyBtn';
import FileVualtDeleteBtn from '../FileVaultDeleteBtn/FileVaultDeleteBtn';
import FileVaultMoveBtn from '../FileVaultMoveBtn/FileVaultMoveBtn';
import styles from './FileVaultBulkActions.module.scss';

export default function FileVaultBulkActions({
	files,
	filesInstances,
	onSuccess,
}) {
	return (
		<div className={styles.bulkContainer}>
			<div className={styles.title}>
				{files.length} {files.length === 1 ? 'item' : 'items'} selected
			</div>
			<div className={styles.actions}>
				<FileVualtCopyBtn
					files={files}
					long={false}
					color={'#00539A'}
					size={20}
					alt={'Copy'}
					onSuccess={onSuccess}
				/>
				<FileVaultMoveBtn
					files={files}
					filesInstances={filesInstances}
					long={false}
					color={'#00539A'}
					size={20}
					onSuccess={onSuccess}
				/>
				<FileVualtDeleteBtn
					files={files}
					long={false}
					color={'#00539A'}
					size={20}
					onSuccess={onSuccess}
				/>
			</div>
		</div>
	);
}
