import React from 'react';
import AppRoute from 'components/AppRoute';
import { Switch, Route } from 'react-router-dom';
import LoginPage from './LoginPage/LoginPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import HomePage from './HomePage/HomePage';
import OnboardingPage from './OnboardingPage/OnboardingPage';
import FileVault from './FileVault/FileVault';
import InvestmentCalcPage from './InvestmentCalcPage/InvestmentCalcPage';
import AcceptInvite from './AcceptInvite/AcceptInvite';
import DocusignConfirmation from './DocusignConfirmation/DocusignConfirmation';
import Settings from './Settings/Settings';
import DealPage from './DealPage/DealPage';
import FundPage from './FundPage/FundPage';
import { NotFound } from './NotFound';

// Utility for mocking pages
// const FakePage = (title) => () => <h1>Fake Page for {title}</h1>;

// Central routing for the app.
// To add other pages, update this definition object.
const RouteDefinitions = {
	'/': { component: HomePage, isPrivate: true },
	'/calculator': { component: InvestmentCalcPage, isPrivate: true },
	'/file-vault': { component: FileVault, isPrivate: true },
	'/deal/:id': { component: DealPage, isPrivate: true },
	'/fund/:id': { component: FundPage, isPrivate: true },
	'/login': { component: LoginPage },
	'/reset-password': { component: ResetPasswordPage },
	'/password-reset': { component: ResetPasswordPage },
	'/accept-invite/local/:inviteId': { component: AcceptInvite },
	'/accept-invite/:salesForceId': { component: AcceptInvite },
	'/onboarding': { component: OnboardingPage, isPrivate: true },
	'/docusign/signed': { component: DocusignConfirmation, isPrivate: true },
	'/settings': { component: Settings, isPrivate: true },
};

export default function Routes() {
	return (
		<Switch>
			{Object.entries(RouteDefinitions).map(
				([path, { component, exact, isPrivate }]) => (
					<AppRoute
						key={path}
						path={path}
						component={component}
						exact={exact || true}
						isPrivate={isPrivate}
					/>
				),
			)}
			<Route component={NotFound} />
		</Switch>
	);
}
