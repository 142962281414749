import { Container, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useProfile } from '../../services/AuthService';
import ManageAccess from '../Admin/ManageAccess/ManageAccess';
import PageFrame from '../PageFrame';
import Profile from '../Profile/Profile';
import styles from './Settings.module.scss';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
/* eslint-disable no-console */
export default function Settings() {
	const profile = useProfile();

	const [value, setValue] = useState(0);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};
	return (
		<PageFrame title={'Settings'}>
			<Container maxWidth="false" className={styles.container}>
				<h1>{profile.user.name}</h1>

				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
						>
							<Tab label="My Profile" {...a11yProps(0)} />
							{profile.user.isAdmin && (
								<Tab label="Manage access" {...a11yProps(1)} />
							)}
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<Profile />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<ManageAccess />
					</TabPanel>
				</Box>
			</Container>
		</PageFrame>
	);
}
