import { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
	InputLabel,
	Button,
	Grid,
	Alert,
	AlertTitle,
	CircularProgress,
	FormLabel,
	Link,
	TextField,
} from '@material-ui/core';
import { Pencil, DeviceFloppy } from 'tabler-icons-react';
import MuiPhoneNumber from 'mui-phone-number';
import AuthService, { useProfile } from '../../../../services/AuthService';
import styles from './PhoneNumberForm.module.scss';

/* eslint-disable no-console */
export default function PhoneNumberForm() {
	const [error, setError] = useState();
	const [editPersonalInfo, setEditPersonalInfo] = useState(false);
	const profile = useProfile();
	const [resend, setResend] = useState(false);

	const [sent, setSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState();
	const [verification, setVerification] = useState();

	const [token] = useState();
	const [phone, setPhone] = useState(profile.user.phoneNum);

	// resend verification code
	const sendVerificationCodeCallback = useCallback(() => {
		setError(null);
		setSent(false);
		setLoading(true);
		AuthService.onboardingVerifyPhone(phone).then(
			({ success, errorReason, error: { message } = {} }) => {
				setLoading(false);
				if (success) {
					setSent(true);
				} else {
					setError(errorReason || message);
				}
			},
		);
	}, [phone]);

	// verify that code what user enters is correct
	const verifyCodeCallback = useCallback(() => {
		setError(null);
		setVerification(true);
		AuthService.onboardingVerifyCode(code, true).then(
			({ success, errorReason, error: { message } = {} }) => {
				setVerification(false);
				if (!success) {
					setError(errorReason || message);
				} else {
					setSent(false);
					setEditPersonalInfo(false);
					window.location.reload();
				}
			},
		);
	}, [code]);

	const onCodeChange = (e) => {
		setError(false);
		setCode(e.target.value);
	};

	const onResendClick = (e) => {
		setResend(true);
		e.preventDefault();
		setTimeout(() => {
			setResend(false);
		}, 3000);
		sendVerificationCodeCallback();
	};

	useEffect(() => {
		if (code && code.length === 6) {
			verifyCodeCallback();
		}

		return function cleanup() {
			setCode(null);
		};
	}, [code, verifyCodeCallback]);

	const handlePhoneChange = () => {
		sendVerificationCodeCallback();
	};

	return (
		<Formik
			initialValues={{
				phoneNum: profile.user.phoneNum,
			}}
			validate={(values) => {
				const errors = {};
				if (!values.phoneNum || values.phoneNum.length <= 5) {
					errors.phoneNum = 'Enter valid phone number';
				}
				return errors;
			}}
		>
			{({
				handleBlur,
				handleSubmit,
				/* and other goodies */
			}) => (
				<>
					<Grid item md={12}>
						<div className={styles.title}>
							Two Factor Authentication
							{!editPersonalInfo ? (
								<Button
									variant={'outlined'}
									onClick={() => setEditPersonalInfo(true)}
									disabled={editPersonalInfo}
								>
									Edit phone number&nbsp;&nbsp;&nbsp;
									<Pencil size={18} />
								</Button>
							) : (
								<Button
									variant="contained"
									onClick={handlePhoneChange}
								>
									Save&nbsp;&nbsp;&nbsp;
									<DeviceFloppy size={18} />
								</Button>
							)}
						</div>
					</Grid>
					<Grid item md={12}>
						<form onSubmit={handleSubmit}>
							<Grid container className={styles.container}>
								{error && (
									<>
										<Grid item xs={4} md={4}>
											<Alert severity="error">
												<AlertTitle>Error</AlertTitle>
												{error}
											</Alert>
										</Grid>

										<Grid md={8}></Grid>
									</>
								)}
								{resend && (
									<>
										<Grid item xs={4} md={4}>
											<Alert>
												New verification code was sent
											</Alert>
										</Grid>

										<Grid md={8}></Grid>
									</>
								)}
								{!sent && (
									<Grid
										item
										md={4}
										className={styles.formInner}
									>
										{error && <span>{error}</span>}
										<InputLabel>
											Phone
											{loading && <CircularProgress />}
										</InputLabel>
										<MuiPhoneNumber
											placeholder={'Your phone number'}
											className={styles.phoneNumber}
											defaultCountry={'us'}
											variant={'outlined'}
											preferredCountries={['us']}
											disableAreaCodes={true}
											disabled={!editPersonalInfo}
											onChange={setPhone}
											onBlur={handleBlur}
											name="phoneNum"
											value={phone}
										/>
									</Grid>
								)}
								{phone && (sent || token) && (
									<Grid item xs={4} md={4}>
										<TextField
											disabled={verification}
											placeholder={'_ _ _ _ _ _'}
											onChange={onCodeChange}
											className={styles.twoFactorInput}
											inputProps={{ maxlength: 6 }}
										/>
										<FormLabel>
											I haven't receved an SMS.{' '}
											<Link
												href={'#'}
												onClick={onResendClick}
											>
												Resend
											</Link>
										</FormLabel>
									</Grid>
								)}
							</Grid>
						</form>
					</Grid>
				</>
			)}
		</Formik>
	);
}
