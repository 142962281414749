import { TableRow, TableCell, TextField, Alert } from '@material-ui/core';
import { FolderPlus } from 'tabler-icons-react';
import { useState } from 'react';
import styles from './FileVaultNewFolderRow.module.scss';
import FileVaultService from '../../services/FileVaultService';

/* eslint-disable no-console */
export default function FileVaultNewFolderRow({ folderId, callback }) {
	const [showInput, setShowInput] = useState(false);
	const [folder, setFolder] = useState();
	const [error, setError] = useState();

	const startNewFolder = () => {
		setShowInput(true);
	};

	const createFolder = () => {
		if (folder.trim()) {
			FileVaultService.createFolder(folder.trim(), folderId).then(
				({
					success,
					folder: newFolder,
					errorReason,
					error: { message } = {},
				}) => {
					if (success) {
						setShowInput(false);
						setFolder(null);
						callback(newFolder);
					} else {
						setError(errorReason || message);
					}
				},
			);
		}
	};

	const handleInputChange = (e) => {
		if (e.key === 'Enter' && folder) {
			createFolder();
		}
	};

	return (
		<TableRow hover className={styles.row}>
			<TableCell>
				<FolderPlus color={'#00539A'} />
			</TableCell>
			<TableCell
				align="left"
				className={showInput ? styles.cell : styles.name}
			>
				{showInput ? (
					<TextField
						onKeyDown={handleInputChange}
						onChange={(e) => setFolder(e.target.value)}
						className={styles.textField}
					/>
				) : (
					<span onClick={() => startNewFolder()}>
						Create a folder here
					</span>
				)}
				{error && <Alert>{error}</Alert>}
			</TableCell>
		</TableRow>
	);
}
