import { useState } from 'react';
import { Formik } from 'formik';
import {
	InputLabel,
	TextField,
	Button,
	Grid,
	Alert,
	AlertTitle,
} from '@material-ui/core';
import { Pencil, DeviceFloppy } from 'tabler-icons-react';
import AuthService from '../../../../services/AuthService';
import styles from './PasswordForm.module.scss';

/* eslint-disable no-console */
export default function PasswordForm() {
	const [error, setError] = useState();
	const [editPersonalInfo, setEditPersonalInfo] = useState(false);

	return (
		<Formik
			initialValues={{
				password: '',
			}}
			validate={(values) => {
				const errors = {};
				if (!values.password) {
					errors.password = 'Password can not be empty';
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				AuthService.onboarding(values).then(
					({ success, errorReason, error: { message } = {} }) => {
						setSubmitting(false);
						if (success) {
							setEditPersonalInfo(false);
						}
						setError(errorReason || message);
					},
				);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				/* and other goodies */
			}) => (
				<>
					<Grid item md={12}>
						<div className={styles.title}>
							Password
							{!editPersonalInfo ? (
								<Button
									variant={'outlined'}
									onClick={() => setEditPersonalInfo(true)}
									disabled={editPersonalInfo}
								>
									Edit Password&nbsp;&nbsp;&nbsp;
									<Pencil size={18} />
								</Button>
							) : (
								<Button
									variant="contained"
									onClick={handleSubmit}
								>
									Save&nbsp;&nbsp;&nbsp;
									<DeviceFloppy size={18} />
								</Button>
							)}
						</div>
					</Grid>
					<Grid item md={12}>
						<form onSubmit={handleSubmit}>
							<Grid container className={styles.container}>
								{errors.password && touched.password && (
									<>
										<Grid item xs={4} md={4}>
											<Alert severity="error">
												<AlertTitle>Error</AlertTitle>
												{errors.password}
											</Alert>
										</Grid>

										<Grid md={8}></Grid>
									</>
								)}
								<Grid item md={4} className={styles.formInner}>
									{error && <span>{error}</span>}
									<InputLabel>Password</InputLabel>
									<TextField
										placeholder={'Your password'}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										name="password"
										type={'password'}
										disabled={!editPersonalInfo}
									/>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</>
			)}
		</Formik>
	);
}
