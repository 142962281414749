import FunctionalState, {
	useFunctionalState,
} from 'shared/components/FunctionalState';
import ApiService from './ApiService';

export default class UserService {
	static userState = new FunctionalState({
		users: [],
	});

	static async getUsersList() {
		const result = await ApiService.getUsersList();
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		this.updateUserState({ users: result });

		return { success: true };
	}

	static async createUser(params) {
		const result = await ApiService.createUser(params);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const userState = this.userState.getValue();
		const users = userState.users || [];

		const userList = [...users, result.user].sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});

		this.updateUserState({
			users: userList,
		});

		return { success: true };
	}

	static async updateUser(id, params) {
		const result = await ApiService.updateUser(id, params);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const userState = this.userState.getValue();
		let users = userState.users || [];

		if (params.isDeleted) {
			users = users.filter((u) => u.id !== id);
		} else {
			const newUser = result;
			users = users.map((u) => {
				if (u.id === newUser.id) {
					return newUser;
				}
				return u;
			});
		}

		this.updateUserState({ users });

		return { success: true };
	}

	static updateUserState({ users }) {
		this.userState.setState({
			users,
		});
	}
}

// Export our FileVault as a state
export const useUsers = () => {
	return useFunctionalState(UserService.userState);
};
