import { Line, defaults, Chart } from 'react-chartjs-2';
import { useState, useEffect, useRef } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import { moneyNumberFormat } from '../../utils/numbers';
import {
	externalTooltipHandler,
	getGradient,
} from '../../utils/graph/TooltipHelper';
// import { moneyNumberFormat } from '../../utils/numbers';

/* eslint-disable no-console */

export default function Graph({
	series,
	clickHandler,
	selectedYear,
	selectedIndex,
	xAxisLabels,
	hideAnnotations,
}) {
	const [data, setData] = useState({
		labels: xAxisLabels,
	});

	useEffect(() => {
		// setLine(false);

		const series2 = series.map((set) => {
			switch (set.label) {
				case 'Target Valuation':
					return {
						...set,
						borderColor: '#CA8501',
						fill: '1',
						borderDash: [10, 5],
						data: set.data.slice(0, 12).map(({ value }) => value),
					};
				case 'Invested Equity':
					return {
						...set,
						fill: '1',
						borderColor: '#07bdba',
						data: set.data.slice(0, 12).map(({ value }) => value),
					};
				case 'Total Return':
					return {
						...set,
						fill: '1',
						borderColor: '#00539A',
						data: set.data.slice(0, 12).map(({ value }) => value),
					};
				default:
					return {
						...set,

						fill: '1',
						borderColor: '#00539A',
						data: set.data.slice(0, 12).map(({ value }) => value),
					};
			}
		});

		setData((o) => {
			return { ...o, datasets: series2 };
		});
		defaults.font.family = 'Roboto Mono';
		defaults.font.size = 10;
	}, [series]);

	const ref = useRef();

	Chart.register(annotationPlugin);

	return (
		<Line
			ref={ref}
			data={data}
			options={{
				interaction: {
					intersect: false,
					mode: 'index',
				},
				scales: {
					y: {
						ticks: {
							callback(value) {
								return moneyNumberFormat(value, 3);
							},
						},
					},
					x: {
						grid: {
							// You can change the color, the dash effect, the main axe color, etc.
							borderDash: [8, 4],
						},
					},
				},
				elements: {
					point: {
						radius: 0,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false,
						external: (context) =>
							externalTooltipHandler(context, 3),
					},
					annotation: {
						annotations: {
							line: {
								type: 'line',
								value: selectedIndex,
								scaleID: 'x',
							},
							shade: {
								type: 'box',
								backgroundColor: 'rgba(15, 98, 254, 0.2)',
								borderWidth: 0,
								xMin: selectedIndex - 0.1,
								xMax: selectedIndex + 0.1,
							},

							line2: () =>
								hideAnnotations
									? null
									: {
											type: 'line',
											value: selectedIndex,
											scaleID: 'x',
									  },
							line3: () =>
								hideAnnotations
									? null
									: {
											type: 'line',
											value: 0,
											scaleID: 'x',
									  },
							line4: () =>
								hideAnnotations
									? null
									: {
											type: 'line',
											value: 11,
											scaleID: 'x',
									  },

							history: () => {
								return hideAnnotations
									? null
									: {
											type: 'line',
											mode: 'vertical',
											scaleID: 'x',
											value: 0.5,
											borderColor: 'transparent',
											label: {
												backgroundColor:
													'rgba(0,0,0,0.8)',
												xPadding: 12,
												yPadding: 6,
												cornerRadius: 4,
												position: 'start',
												enabled: true,
												content: 'Historical data',
											},
									  };
							},
							projection: () => {
								return hideAnnotations
									? null
									: {
											type: 'line',
											mode: 'vertical',
											scaleID: 'x',
											value: 4,
											borderColor: 'transparent',
											label: {
												backgroundColor:
													'rgba(0,0,0,0.8)',
												xPadding: 12,
												yPadding: 6,
												cornerRadius: 4,
												position: 'start',
												enabled: true,
												content: 'Target projection',
											},
									  };
							},
						},
					},
				},
				datasets: {
					line: {
						borderWidth: 2,
						backgroundColor: (context) => {
							const { chart } = context;
							const { ctx, chartArea } = chart;

							if (!chartArea) {
								// This case happens on initial chart load
								return null;
							}
							return getGradient(ctx, chartArea);
						},
					},
				},
				onClick: (e, points) => {
					const { index } = points[0];
					const xAxis = data.labels[index];
					if (selectedYear !== xAxis) {
						clickHandler(index, xAxis);
					}
				},
			}}
			height={75}
		/>
	);
}
