import { useState } from 'react';
import { Formik } from 'formik';
import {
	InputLabel,
	TextField,
	Button,
	Grid,
	Autocomplete,
	Alert,
	AlertTitle,
} from '@material-ui/core';
import { Pencil, DeviceFloppy } from 'tabler-icons-react';
import AuthService, { useProfile } from '../../../../services/AuthService';
import constants from '../../../../utils/constants';
import styles from './PersonalDetailsForm.module.scss';

/* eslint-disable no-console */
export default function PersonalDetailsForm() {
	const profile = useProfile();
	const [error, setError] = useState();
	const [editPersonalInfo, setEditPersonalInfo] = useState(false);

	return (
		<Formik
			initialValues={{
				email: profile.user.email,
				name: profile.user.name,

				address: {
					streetName: profile.user.address.streetName,
					streetNumber: profile.user.address.streetNumber,
					city: profile.user.address.city,
					postalCode: profile.user.address.postalCode,
					state: profile.user.address.state,
				},
			}}
			validate={(values) => {
				const errors = {};
				if (!values.name) {
					errors.name = 'Name is required';
				}
				if (!values.address.streetName) {
					errors.address = 'Street name is required';
				}
				if (!values.address.streetNumber) {
					errors.address = 'Street number name is required';
				}
				if (!values.address.city) {
					errors.address = 'City is required';
				}
				if (!values.address.postalCode) {
					errors.address = 'Postl code is required';
				}
				if (!values.address.state) {
					errors.address = 'State is required';
				}
				if (!values.email) {
					errors.email = 'Email Required';
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
						values.email,
					)
				) {
					errors.email = 'Invalid email address';
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				AuthService.onboarding(values).then(
					({ success, errorReason, error: { message } = {} }) => {
						setSubmitting(false);
						if (success) {
							setEditPersonalInfo(false);
						}
						setError(errorReason || message);
					},
				);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				/* and other goodies */
			}) => (
				<>
					<Grid item md={12}>
						<div className={styles.title}>
							Personal Details
							{!editPersonalInfo ? (
								<Button
									variant={'outlined'}
									onClick={() => setEditPersonalInfo(true)}
									disabled={editPersonalInfo}
								>
									Edit Details&nbsp;&nbsp;&nbsp;
									<Pencil size={18} />
								</Button>
							) : (
								<Button
									variant="contained"
									onClick={handleSubmit}
								>
									Save&nbsp;&nbsp;&nbsp;
									<DeviceFloppy size={18} />
								</Button>
							)}
						</div>
					</Grid>
					<Grid item md={12}>
						<form onSubmit={handleSubmit}>
							<Grid container className={styles.container}>
								<Grid
									item
									md={4}
									className={styles.formLeftSide}
								>
									{errors.name && touched.name && (
										<Grid item xs={12} md={12}>
											<Alert severity="error">
												<AlertTitle>Error</AlertTitle>
												{errors.name}
											</Alert>
										</Grid>
									)}
									{errors.email && touched.email && (
										<Grid item xs={12} md={12}>
											<Alert severity="error">
												<AlertTitle>Error</AlertTitle>
												{errors.email}
											</Alert>
										</Grid>
									)}
									{error && (
										<Grid item xs={12} md={12}>
											<Alert severity="error">
												<AlertTitle>Error</AlertTitle>
												{error}
											</Alert>
										</Grid>
									)}
									<InputLabel>Name</InputLabel>
									<TextField
										placeholder={'Full name'}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										name="name"
										disabled={!editPersonalInfo}
									/>
									{errors.name && touched.name && errors.name}
									<br />
									<br />
									<InputLabel>Email</InputLabel>
									<TextField
										placeholder={'Type your email address'}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										type="email"
										name="email"
										disabled={!editPersonalInfo}
									/>
									{errors.email &&
										touched.email &&
										errors.email}
								</Grid>
								<Grid
									item
									md={4}
									className={styles.formRightSide}
								>
									<Grid container spacing={4}>
										{errors.address && touched.address && (
											<Grid item xs={12} md={12}>
												<Alert severity="error">
													<AlertTitle>
														Error
													</AlertTitle>
													{errors.address}
												</Alert>
											</Grid>
										)}
										<Grid item xs={12} md={8}>
											<InputLabel>Street name</InputLabel>
											<TextField
												placeholder={
													'Enter your street name'
												}
												onChange={handleChange}
												name="address.streetName"
												disabled={!editPersonalInfo}
												value={
													values.address.streetName
												}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<InputLabel>Number</InputLabel>
											<TextField
												placeholder={'Number'}
												onChange={handleChange}
												name="address.streetNumber"
												disabled={!editPersonalInfo}
												value={
													values.address.streetNumber
												}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<InputLabel>City</InputLabel>
											<TextField
												placeholder={'Enter your city'}
												onChange={handleChange}
												name={'address.city'}
												disabled={!editPersonalInfo}
												value={values.address.city}
											/>
										</Grid>
										<Grid item xs={12} md={8}>
											<InputLabel>State</InputLabel>
											<Autocomplete
												options={constants.states}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder={
															'Select your state'
														}
													/>
												)}
												getOptionLabel={(option) => {
													let { states } = constants;
													states = states.filter(
														(o) =>
															o.abbreviation ===
															(option?.abbreviation ||
																option),
													);
													if (states.length) {
														return states[0].name;
													}

													return option;
												}}
												onChange={(e, val) => {
													setFieldValue(
														'address.state',
														val?.abbreviation,
													);
												}}
												disabled={!editPersonalInfo}
												value={values.address.state}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<InputLabel>Zip Code</InputLabel>
											<TextField
												placeholder={'Zip code'}
												onChange={handleChange}
												name="address.postalCode"
												disabled={!editPersonalInfo}
												value={
													values.address.postalCode
												}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</>
			)}
		</Formik>
	);
}
