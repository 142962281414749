import {
	Box,
	Grid,
	InputLabel,
	TextField,
	Checkbox,
	FormControlLabel,
	Link,
	Alert,
	AlertTitle,
	InputAdornment,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Check } from 'tabler-icons-react';
import AuthService from '../../../services/AuthService';
import FormHeader from '../../FormHeader/FormHeader';
import LoadingButton from '../../LoadingButton/LoadingButton';
import TwoFactorAuthForm from '../TwoFactorAuthForm/TwoFactorAuthForm';
import styles from './LoginForm.module.scss';

export default function LoginForm() {
	const history = useHistory();

	const [user, setUser] = useState();
	const [pass, setPass] = useState();
	const [rememberMe, setRememberMe] = useState();
	const [error, setError] = useState();
	const [twoFactorTokenState, setTwoFactorTokenState] = useState();
	const [phone, setPhone] = useState();
	const [loading, setLoading] = useState(false);

	const onLoginClick = () => {
		setError(null);
		setLoading(true);
		AuthService.login(user, pass, rememberMe).then(
			({
				success,
				twoFactorToken,
				phoneNumber,
				errorReason,
				error: { message } = {},
			}) => {
				setLoading(false);
				if (success) {
					if (!twoFactorToken) {
						history.push(AuthService.getPreLoginPath());
					} else {
						setPhone(phoneNumber);
						setTwoFactorTokenState(twoFactorToken);
					}
					return;
				}

				setError(errorReason || message);
			},
		);
	};

	const handleResetPasswordClick = (e) => {
		e.preventDefault();
		history.push('/reset-password');
	};

	const codeVerifyCallback = (token) => {
		AuthService.authenticateUser(token).then(
			({ success, errorReason, error: { message } = {} }) => {
				setLoading(false);
				if (success) {
					history.push(AuthService.getPreLoginPath());
				}

				setError(errorReason || message);
			},
		);
	};

	useEffect(() => {
		return function cleanup() {
			setUser(null);
			setPass(null);
		};
	}, []);

	const handleFormSubmit = (e) => {
		if (e.key === 'Enter' && user && pass) {
			onLoginClick();
		}
	};

	return (
		<Box fixed className={styles.formContainer}>
			<FormHeader title={'Log in to your account'} />
			{!twoFactorTokenState && (
				<Grid container spacing={4}>
					{error && (
						<Grid item xs={12} md={12}>
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{error}
							</Alert>
						</Grid>
					)}
					<Grid item xs={12} md={12}>
						<InputLabel>Email</InputLabel>
						<TextField
							placeholder={'Type your email address'}
							onKeyDown={handleFormSubmit}
							onChange={(e) => {
								setUser(e.target.value);
								setError(false);
							}}
							InputProps={{
								endAdornment:
									user && user.length > 1 ? (
										<InputAdornment position="end">
											<Check color="#24A148" />
										</InputAdornment>
									) : null,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<InputLabel>Password</InputLabel>
						<TextField
							type={'password'}
							placeholder={'Input Password'}
							onKeyDown={handleFormSubmit}
							onChange={(e) => {
								setPass(e.target.value);
								setError(false);
							}}
							data-test-id="login-button"
						/>
						<Link
							href={'#'}
							alignContent={'right'}
							className={styles.forgotPass}
							onClick={(e) => handleResetPasswordClick(e)}
						>
							Forgot your password?
						</Link>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						className={styles.keepMeInContainer}
					>
						<FormControlLabel
							control={
								<Checkbox
									onChange={(e) =>
										setRememberMe(e.target.checked)
									}
								/>
							}
							label="Keep me logged in for a week"
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<LoadingButton
							className={styles.loginBtn}
							variant="contained"
							onClick={onLoginClick}
							disabled={!(user && pass)}
							loading={loading}
							children={'Continue'}
						/>
					</Grid>
				</Grid>
			)}
			{twoFactorTokenState && (
				<TwoFactorAuthForm
					callback={codeVerifyCallback}
					phone={phone}
					token={twoFactorTokenState}
				/>
			)}
		</Box>
	);
}
