import { useState, useEffect } from 'react';
import {
	Container,
	Grid,
	Select,
	MenuItem,
	Button,
	TextField,
	InputAdornment,
	InputLabel,
	CircularProgress,
	Alert,
	AlertTitle,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import DashboardKPI from '../DashboardKPI/DashboardKPI';
import styles from './InvestmentCalculator.module.scss';
import Graph from '../Graph/Graph';

import {
	getEbitda,
	getReturn,
	getRevenue,
	getValuation,
} from '../../utils/KpiHelper';
import FundsTable from '../FundsTable/FundsTable';
import PEService from '../../services/PEService';

/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

export default function InvestmentCalculator() {
	const history = useHistory();

	const [error, setError] = useState();
	const [loading, setLoading] = useState();

	const [deals, setDeals] = useState([]);
	const [funds, setFunds] = useState([]);
	const [labels, setLabels] = useState([]);
	const [selectedDealId, setSelectedDealId] = useState('');
	const [deal, setDeal] = useState(null);

	const [selectedYear, setSelectedYear] = useState('Today');
	const [selectedIndex, setSelectedIndex] = useState(11);

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('status');

	// calculator
	const [investment, setInvestment] = useState();

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	useEffect(() => {
		setLoading(true);
		PEService.getPEDashboard().then(
			({
				success,
				deals: receivedDeals,
				funds: receivedFunds,
				labels: receivedLabels,
				errorReason,
				error: { message } = {},
			}) => {
				setLoading(false);
				if (success) {
					setDeals(receivedDeals);
					setFunds(receivedFunds);
					setLabels(receivedLabels);
					setSelectedDealId(receivedDeals[0].id);
				} else {
					setError(errorReason || message);
				}
			},
		);
	}, []);

	useEffect(() => {
		if (investment) {
			setLoading(true);
			PEService.getEstimation(investment).then(
				({
					success,
					deals: receivedDeals,
					errorReason,
					error: { message } = {},
				}) => {
					setLoading(false);
					if (success) {
						setDeal(receivedDeals[0]);
					} else {
						setError(errorReason || message);
					}
				},
			);
		} else {
			setDeal(null);
		}
	}, [investment]);

	// useEffect(() => {
	// 	let filteredDeals = deals.filter((d) => d.id === selectedDealId);
	// 	setDeal(filteredDeals[0]);
	// }, [selectedDealId]);

	const handleGraphClck = (index, xAxis) => {
		setSelectedIndex(index);
		setSelectedYear(xAxis);
	};

	return (
		<Container maxWidth="false" className={styles.container}>
			{!loading && (
				<Grid container className={styles.section}>
					<Grid item xs={12} md={12}>
						<Select
							className={styles.dealSelector}
							value={selectedDealId}
							onChange={(e) => setSelectedDealId(e.target.value)}
						>
							{deals.map((dealOption, index) => (
								<MenuItem key={index} value={dealOption.id}>
									{dealOption.name}
								</MenuItem>
							))}
						</Select>

						<Select
							className={styles.periodSelector}
							value={selectedIndex}
							onChange={(e) => setSelectedIndex(e.target.value)}
						>
							{labels.map((label, index) => (
								<MenuItem
									key={index}
									value={index}
									className={
										label === 'Today' ||
										label === 'Target Projection'
											? styles.markedLabel
											: ''
									}
								>
									{label}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			)}
			{error && (
				<Grid item xs={12} md={12}>
					<Grid item xs={12} md={12}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					</Grid>
				</Grid>
			)}
			{deals && (
				<Grid
					container
					className={classNames(styles.kpiGrid, styles.section)}
				>
					<Grid item md={2} className={styles.kpi}>
						<DashboardKPI name={'Investment'} value={investment} />
					</Grid>
					<Grid item md={2} className={styles.kpi}>
						<DashboardKPI
							name={'Target Valuation'}
							value={getValuation(investment, selectedIndex)}
							// changeValue={0}
							// changePercent={0}
						/>
					</Grid>
					<Grid item md={2} className={styles.kpi}>
						<DashboardKPI
							name={'Target Total Return'}
							value={getReturn(investment, selectedIndex)}
							// changeValue={0}
							// changePercent={0}
						/>
					</Grid>
					<Grid item md={2} className={styles.kpi}>
						<DashboardKPI
							name={'Target Revenue'}
							value={getRevenue(investment, selectedIndex)}
							// changeValue={0}
							// changePercent={0}
						/>
					</Grid>
					<Grid item md={2} className={styles.kpi}>
						<DashboardKPI
							name={'Target EBITDA'}
							value={getEbitda(investment, selectedIndex)}
							// changeValue={0}
							// changePercent={0}
						/>
					</Grid>
					<Grid item md={2}>
						<div className={styles.simulator}>
							<InputLabel>Investment Amount</InputLabel>
							<NumberFormat
								value={investment}
								customInput={TextField}
								onValueChange={(values) =>
									setInvestment(values.value)
								}
								placeholder={'Type amount'}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											$
										</InputAdornment>
									),
								}}
								thousandSeparator={','}
								allowNegative={false}
							/>
							<Button
								className={styles.closeSimulator}
								variant={'outlined'}
								onClick={() => history.push('/')}
							>
								Close Simulator
							</Button>
						</div>
					</Grid>
				</Grid>
			)}

			{loading && (
				<>
					<CircularProgress />
					Loading ...
				</>
			)}

			{deal && (
				<Grid container className={classNames(styles.graphContainer)}>
					<Grid item md={12}>
						<p className={styles.title}>
							Investment Simulator Performance Graph
						</p>
						{/* <div className={classNames('description', styles.graphDescription)}>
							<div className={classNames('description', styles.left)}>
								{deal.name} - Historical Data
							</div>
							<div className={classNames('description', styles.right)}>
								{deal.name} - Target Projection
							</div>
						</div> */}
						<Graph
							series={deal.series}
							clickHandler={handleGraphClck}
							selectedIndex={selectedIndex}
							xAxisLabels={labels}
							hideAnnotations
						/>
					</Grid>
				</Grid>
			)}

			{deal && (
				<Grid
					container
					className={classNames(
						styles.section,
						styles.tableContainer,
					)}
				>
					<Grid item md={12}>
						<p className={styles.title}>Investment breakdown</p>
						<FundsTable
							selectedYear={selectedYear}
							funds={funds}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>
					</Grid>
				</Grid>
			)}
		</Container>
	);
}
