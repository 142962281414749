import {
	Alert,
	AlertTitle,
	TableCell,
	TableRow,
	TextField,
} from '@material-ui/core';
import moment from 'moment';
import { useRef, useState } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { Eye, FileText, Folder } from 'tabler-icons-react';
import styles from './FileVaultFolderRow.module.scss';
import FileVualtDeleteBtn from '../FileVaultDeleteBtn/FileVaultDeleteBtn';
import FileVaultService from '../../services/FileVaultService';

export default function FileVaultFolderRow({
	folder,
	openFolderCallback,
	selected,
	variant = 'overview',
	loading,
}) {
	const filesRowRef = useRef();

	const [rename, setRename] = useState();
	const [folderName, setFolderName] = useState(folder.name);
	const [error, setError] = useState();

	const startRename = () => {
		setRename(true);
	};

	const renameFolder = (e) => {
		if (e.key === 'Enter' && folderName.trim()) {
			FileVaultService.renameFolder(folder.id, folderName.trim()).then(
				({ success, errorReason, error: { message } = {} }) => {
					if (success) {
						setRename(false);
					} else {
						setError(errorReason || message);
					}
				},
			);
		}
	};

	let options = [];

	switch (variant) {
		default:
		case 'overview':
			options = [
				{
					label: (
						<span className={styles.contextItem}>
							<Eye size={16} /> Open
						</span>
					),
					onClick: openFolderCallback,
				},
				{
					label: (
						<span className={styles.contextItem}>
							<FileText size={16} /> Rename
						</span>
					),
					onClick: () => startRename(),
				},
				// {
				// 	label: <FileVualtCopyBtn file={file} long={true} />,
				// },
				// {
				// 	label: <FileVaultMoveBtn file={file} long={true} />,
				// },
				{
					label: <FileVualtDeleteBtn folder={folder} long={true} />,
				},
			];
			break;
	}

	return (
		<TableRow hover selected={selected}>
			<TableCell />
			<TableCell>
				<Folder />
			</TableCell>
			<TableCell
				align="left"
				ref={filesRowRef}
				className={styles.file}
				onClick={() =>
					rename || loading ? null : openFolderCallback()
				}
			>
				<ContextMenuTrigger id={folder.id}>
					<span className={styles.fileName}>
						{rename ? (
							<TextField
								value={folderName}
								onChange={(e) => setFolderName(e.target.value)}
								onKeyDown={renameFolder}
								className={styles.editField}
							/>
						) : (
							folder.name
						)}
						{error && (
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{error}
							</Alert>
						)}
					</span>
				</ContextMenuTrigger>
			</TableCell>
			<TableCell align="left">
				<span className={styles.user}>{folder.owner.name}</span>
			</TableCell>
			<TableCell align="left">
				{moment(folder.updatedAt).format('MMMM DD, YYYY')}{' '}
				<span className={styles.user}>{folder.updatedBy.name}</span>
			</TableCell>
			{!rename && (
				<ContextMenu id={folder.id} className={styles.contextMenu}>
					{options.map((option, index) => (
						<MenuItem
							key={index}
							className={styles.item}
							onClick={option.onClick}
						>
							{option.label}
						</MenuItem>
					))}
				</ContextMenu>
			)}
		</TableRow>
	);
}
