import { Grid } from '@material-ui/core';
import PasswordForm from '../forms/profile/Password/PasswordForm';
import PersonalDetailsForm from '../forms/profile/PersonalDetails/PersonalDetailsForm';
import PhoneNumberForm from '../forms/profile/PhoneNumber/PhoneNumberForm';

export default function Profile() {
	return (
		<Grid container spacing={2}>
			<PersonalDetailsForm />
			<PasswordForm />
			<PhoneNumberForm />
		</Grid>
	);
}
