/* eslint-disable no-unused-vars */

import {
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
	Link,
	TableFooter,
} from '@material-ui/core';
import { Eye } from 'tabler-icons-react';
import { useHistory } from 'react-router-dom';
import { moneyNumberFormat } from '../../utils/numbers';
import styles from './DealsTable.module.scss';

/* eslint-disable no-console */

export default function DealsTable({
	deals,
	// selectedYear,
	selectedIndex,
	order,
	orderBy,
	onRequestSort,
	onDealSelect,
	selectedDealId,
}) {
	const history = useHistory();
	return (
		<TableContainer component={Paper} className={styles.table}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						{onDealSelect && <TableCell></TableCell>}
						<TableCell align="left">Deal Name</TableCell>
						{/* <TableCell
							align={'left'}
							sortDirection={orderBy === 'status' ? order : false}
						>
							<TableSortLabel
								active={orderBy === 'status'}
								direction={orderBy === 'status' ? order : 'asc'}
								onClick={() =>
									onRequestSort
										? onRequestSort('status')
										: null
								}
							>
								Status
							</TableSortLabel>
						</TableCell> */}
						<TableCell align="left">Status</TableCell>
						<TableCell align="right">Target Valuation</TableCell>
						<TableCell align="right">Revenue</TableCell>
						<TableCell align="right">Target Earnings</TableCell>
						<TableCell align="right">Target Equity</TableCell>
						<TableCell align="right">Total Return</TableCell>
						<TableCell align="right">% Complete</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{deals
						.filter((d) => d && d.id !== 'total')
						.map((deal, index) => (
							<TableRow
								key={index}
								selected={deal.id === selectedDealId}
							>
								{onDealSelect && (
									<TableCell>
										<Link
											href="#"
											onClick={(e) => {
												e.preventDefault();
												onDealSelect(deal.id);
											}}
										>
											<Eye />
										</Link>
									</TableCell>
								)}
								<TableCell align="left">
									<Link
										href={`#/deal/${deal.id}`}
										onClick={(e) => {
											e.preventDefault();
											history.push(`/deal/${deal.id}`);
										}}
									>
										{deal.name}
									</Link>
								</TableCell>
								<TableCell align="left">
									<Chip
										color={
											deal.status === 'Underwriting'
												? 'warning'
												: 'success'
										}
										label={deal.status}
									/>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['Target Valuation'],
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												].Revenue,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['Target Earnings'],
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['Target Equity'],
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['Total Return'],
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['% Complete'],
											3,
										)}
										%
									</Typography>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
				<TableFooter className={styles.footer}>
					{deals
						.filter((t) => t && t.id === 'total')
						.map((deal, index) => (
							<TableRow
								className={styles.dealsTotal}
								key={index}
								selected={deal.id === selectedDealId}
							>
								<TableCell>
									<Link
										href="#"
										onClick={(e) => {
											e.preventDefault();
											onDealSelect(deal.id);
										}}
									>
										<Eye />
									</Link>
								</TableCell>
								<TableCell
									align="left"
									className={styles.cellName}
								>
									<Link href="#">{deal.name}</Link>
								</TableCell>
								<TableCell />
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['Target Valuation'],
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												].Revenue,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												].ebitda,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												].equityInvstmnt,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												].return,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										{moneyNumberFormat(
											Object.values(deal.kpiValues)[
												selectedIndex
											] &&
												Object.values(deal.kpiValues)[
													selectedIndex
												]['% Complete'],
											3,
										)}
									</Typography>
								</TableCell>
							</TableRow>
						))}
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
