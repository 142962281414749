import {
	Button,
	Grid,
	TableRow,
	TableCell,
	TableHead,
	Table,
	CircularProgress,
	TableBody,
	Link,
} from '@material-ui/core';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { X, UserPlus } from 'tabler-icons-react';
import UserService, { useUsers } from '../../../services/UserService';
import AddNewUserForm from '../../forms/AddNewUserForm/AddNewUserForm';
import EditUser from '../EditUser/EditUser';
import styles from './ManageAccess.module.scss';

export default function ManageAccess() {
	const [addUser, setAddUser] = useState();
	const [error, setError] = useState();
	const [showUser, setShowUser] = useState(null);
	const [loading, setLoading] = useState(false);

	const userState = useUsers();
	const { users } = userState;

	useEffect(() => {
		setLoading(true);
		UserService.getUsersList().then(
			({ success, errorReason, error: { message } = {} }) => {
				setLoading(false);
				if (!success) {
					setError(errorReason || message);
				}
			},
		);
	}, []);

	const handleClose = () => {
		setShowUser(null);
		setAddUser(null);
	};

	if (loading) {
		return <CircularProgress />;
	}

	return (
		<Grid container className={styles.settingsContainer}>
			<Grid item md={addUser || showUser ? 9 : 12}>
				{error}
				<div
					className={classNames(
						styles.container,
						addUser || showUser ? styles.users : '',
					)}
				>
					<div className={styles.title}>
						User list
						<Button
							disabled={addUser}
							onClick={() => {
								setShowUser(null);
								setAddUser(true);
							}}
							variant="contained"
						>
							Add users&nbsp;&nbsp;
							<UserPlus size={16} />
						</Button>
					</div>
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Role</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users?.map((user, index) => {
								if (!user.name) {
									return null;
								}
								return (
									<TableRow
										className={styles.userRow}
										key={index}
										hover
										onClick={() => {
											setAddUser(false);
											setShowUser(user);
										}}
									>
										<TableCell>{user.name}</TableCell>
										<TableCell>
											{user?.investorExperience
												? 'Active'
												: 'Pending'}

											{/* {user.documentSignatures &&
											Object.values(
												user.documentSignatures,
											).every((o) => o.signed)
												? 'Active'
												: 'Pending'} */}
										</TableCell>
										<TableCell>
											<Link href={`mailto:${user.email}`}>
												{user.email}
											</Link>
										</TableCell>
										<TableCell>
											{user.isAdmin && 'Administrator'}
											{user.isAdvisor && 'Advisor'}
											{user.isClient &&
												!user.isAdmin &&
												!user.isAdvisor &&
												'Client'}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</Grid>
			{(addUser || showUser) && (
				<Grid item md={3}>
					<div className={classNames(styles.container, styles.form)}>
						<div className={styles.title}>
							{showUser ? showUser.name : 'Add new User'}
							<div className={styles.closeIcon}>
								<X size={16} onClick={handleClose} />
							</div>
						</div>

						{addUser && <AddNewUserForm onSuccess={handleClose} />}
						{showUser && (
							<EditUser
								user={showUser}
								handleClose={handleClose}
							/>
						)}
					</div>
				</Grid>
			)}
		</Grid>
	);
}
