import { useState, useEffect } from 'react';
import {
	Container,
	Grid,
	Select,
	MenuItem,
	Box,
	Tabs,
	Chip,
	Tab,
	Breadcrumbs,
} from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
// import { useHistory } from 'react-router-dom';
import { ChevronRight } from 'tabler-icons-react';
import DashboardKPI from '../DashboardKPI/DashboardKPI';
import styles from './DealInfo.module.scss';
import TabPanel from '../TabPanel/TabPanel';
import Graph from '../Graph/Graph';
import MediaDoc from '../MediaDoc/MediaDoc';

/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */

export default function DealInfo({ deal }) {
	const [labels, setLabels] = useState([]);
	const [kpiLabels, setKpiLabels] = useState([]);

	const [selectedYear, setSelectedYear] = useState(
		moment().format('YYYY-MM'),
	);
	const [selectedIndex, setSelectedIndex] = useState(
		moment().format('M') - 1,
	);

	useEffect(() => {
		const dealsLabels = Object.keys(deal.kpiValues).slice(0, 12);
		setLabels(dealsLabels);
	}, []);

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};
	function compare(a, b) {
		if (a.label < b.label) {
			return -1;
		}
		if (a.label > b.label) {
			return 1;
		}
		return 0;
	}
	useEffect(() => {
		setKpiLabels(
			deal.kpiHighlights
				.filter((i) => !i.isGeneralKpi)
				.sort(compare)
				.slice(0, 5),
		);
	}, []);

	const [value, setValue] = useState(0);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	const handleGraphClck = (index, xAxis) => {
		setSelectedIndex(index);
		setSelectedYear(xAxis);
	};

	const getVimeoId = (link) => {
		const matches = link.match(/(.com\/)?(\w*)/);

		return matches[2];
	};

	return (
		<Container maxWidth="false" className={styles.container}>
			<Grid container className={styles.section}>
				<Grid item xs={12} md={12}>
					<Breadcrumbs
						className={styles.breadcrumbs}
						separator={<ChevronRight size={20} />}
					>
						<div key={'fund2'} className={styles.breadcrumb}>
							{deal.name}
							<Chip
								className={styles.status}
								color={
									deal.status === 'Underwriting'
										? 'warning'
										: 'success'
								}
								label={deal.status}
							/>
							<Select
								className={styles.periodSelector}
								value={`${selectedIndex}|${selectedYear}`}
								onChange={(e) => {
									const data = e.target.value.split('|');
									setSelectedIndex(parseInt(data[0], 10));
									setSelectedYear(data[1]);
								}}
							>
								{labels.map((label, index) => (
									<MenuItem
										key={index}
										value={`${index}|${label}`}
										className={
											label === 'Today' ||
											label === 'Target Projection'
												? styles.markedLabel
												: ''
										}
									>
										{label === moment().format('YYYY-MM')
											? 'Today'
											: label}
									</MenuItem>
								))}
							</Select>
						</div>
					</Breadcrumbs>
				</Grid>
			</Grid>
			{deal && (
				<Grid
					container
					className={classNames(styles.kpiGrid, styles.section)}
				>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Invested Equity'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Invested Equity'
								]
							}
							changeValue={3456290}
							changePercent={34.82}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Valuation'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Valuation'
								]
							}
							changeValue={3549020}
							changePercent={27.4}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Total Return'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Total Return'
								]
							}
							changeValue={34588230}
							changePercent={4.9}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Revenue'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Revenue'
								]
							}
							changeValue={67802782}
							changePercent={5.6}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Equity'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Equity'
								]
							}
							changeValue={44673950}
							changePercent={7.89}
						/>
					</Grid>
				</Grid>
			)}

			{deal && (
				<Grid
					container
					className={classNames(
						styles.section,
						styles.tableContainer,
					)}
				>
					<Grid item md={8}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
							>
								<Tab
									label="Info and Performance"
									{...a11yProps(0)}
								/>
								<Tab
									label="Media and Files"
									{...a11yProps(1)}
								/>
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<div className={styles.description}>
								{deal.description}
							</div>
							<Grid
								container
								className={classNames(
									styles.kpiGrid,
									styles.section,
									styles.smallKpi,
								)}
							>
								{kpiLabels.map((kpi) => (
									<Grid item md className={styles.kpi}>
										<DashboardKPI
											name={kpi.label}
											small
											value={
												kpi.data[selectedIndex] &&
												kpi.data[selectedIndex].value
											}
										/>
									</Grid>
								))}
							</Grid>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<Grid container spacing={3}>
								{deal.media &&
									Object.values(deal.media).map(
										(document) => (
											<Grid item md={4}>
												<MediaDoc file={document} />
											</Grid>
										),
									)}
								{Object.values(deal.media).length === 0 && (
									<div className={styles.noMeida}>
										No media files
									</div>
								)}
							</Grid>
						</TabPanel>
					</Grid>
					<Grid item md={4}>
						<div className={styles.videoContainer}>
							<iframe
								title="Video player"
								src={`https://player.vimeo.com/video/${getVimeoId(
									deal.vimeoUrl,
								)}`}
								width="100%"
								height="325"
								frameborder="0"
								allowfullscreen
							></iframe>
						</div>
					</Grid>

					<Grid item md={12} className={styles.graphContainer}>
						<p className={styles.title}>
							{deal.name} - Performance
						</p>

						{labels.length && (
							<div className={styles.graphDetails}>
								<Graph
									series={deal.kpiHighlights.filter(
										(i) =>
											[
												'Target Valuation',
												'Total Return',
												'Invested Equity',
											].indexOf(i.label) > -1,
									)}
									clickHandler={handleGraphClck}
									selectedIndex={selectedIndex}
									xAxisLabels={labels}
									hideAnnotations
								/>
							</div>
						)}
					</Grid>
				</Grid>
			)}
		</Container>
	);
}
