import { HelmetProvider } from 'react-helmet-async';
import { HashRouter as Router } from 'react-router-dom';
import Routes from 'components/Routes.js';
import { ThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultTheme from './Theme';

function App() {
	return (
		<HelmetProvider>
			<ThemeProvider theme={DefaultTheme}>
				<Router>
					<Routes />
				</Router>
				<ToastContainer />
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
