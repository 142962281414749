import {
	Grid,
	InputLabel,
	TextField,
	Autocomplete,
	InputAdornment,
	Checkbox,
	Alert,
	AlertTitle,
	Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { X, InfoSquare, Check } from 'tabler-icons-react';
import styles from './OnboardingForm.module.scss';
import LoadingButton from '../../LoadingButton/LoadingButton';
import constants from '../../../utils/constants';
import FileUploader from '../../FileUploader/FileUploader';
import AuthService from '../../../services/AuthService';
import FileVaultService from '../../../services/FileVaultService';

export default function InvestorNetWorth({ handleNetWorth: onSuccess }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [netWorth, setNetWorth] = useState({
		annualIncome: null,
		netWorth: null,
		wealthSources: [],
		incomeDocs: [],
	});

	const uploadFiles = async () => {
		setLoading(true);
		return Promise.all(
			netWorth.incomeDocs.map((file) => {
				return FileVaultService.uploadFile(file);
			}),
		);
	};
	/* eslint-disable no-console */
	const handleNetWorth = () => {
		if (netWorth.annualIncome >= 200000 || netWorth.netWorth >= 1000000) {
			setLoading(true);
			uploadFiles().then((fileResponses) => {
				const errors = fileResponses.filter(
					(response) => response.error,
				);
				if (errors.length === 0) {
					AuthService.onboarding({
						netWorth,
					}).then(
						({ success, errorReason, error: { message } = {} }) => {
							setLoading(false);
							if (success) {
								onSuccess();
							}

							setError(errorReason || message);
						},
					);
				} else {
					setError(errors[0].error.message);
				}
			});
		} else {
			setError(
				'You should either earn 200k Annual income or 1 million of net worth',
			);
		}
	};

	return (
		<Grid container spacing={4}>
			{error && (
				<Grid item xs={12} md={12}>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{error}
					</Alert>
				</Grid>
			)}
			<Grid item xs={12} md={12}>
				<span>
					Per SEC rules you need to be accredited investor to get
					access, this means to prove that you either earn 200k Annual
					income or 1 million of net worth.
				</span>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Annual Income</InputLabel>
				<NumberFormat
					customInput={TextField}
					onValueChange={(values) =>
						setNetWorth({ ...netWorth, annualIncome: values.value })
					}
					placeholder={'Type amount'}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">$</InputAdornment>
						),
						endAdornment:
							netWorth && netWorth.annualIncome ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
					thousandSeparator={','}
					allowNegative={false}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Net Worth</InputLabel>
				<NumberFormat
					customInput={TextField}
					onValueChange={(values) =>
						setNetWorth({ ...netWorth, netWorth: values.value })
					}
					placeholder={'Type amount'}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">$</InputAdornment>
						),
						endAdornment:
							netWorth && netWorth.netWorth ? (
								<InputAdornment position="end">
									<Check color="#24A148" />
								</InputAdornment>
							) : null,
					}}
					thousandSeparator={','}
					allowNegative={false}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Source of wealth</InputLabel>
				<Autocomplete
					multiple
					id="checkboxes-tags-demo"
					options={constants.netWorthSource}
					disableCloseOnSelect
					getOptionLabel={(option) => option.value}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.value}
						</li>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={
								netWorth.wealthSources.length > 0
									? ''
									: 'Please, select at least one'
							}
						/>
					)}
					ChipProps={{
						deleteIcon: <X size={14} />,
					}}
					onChange={(e, value) =>
						setNetWorth({
							...netWorth,
							wealthSources: value.map((item) => item.value),
						})
					}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel className={styles.fileUploadLabel}>
					Please upload a file to prove your Annual Income and Net
					Worth{' '}
					<Tooltip title="Upload documents that prove your income or net worth">
						<span>
							<InfoSquare size={12} className={styles.icon} />
						</span>
					</Tooltip>
				</InputLabel>
				<FileUploader
					onDrop={(files) =>
						setNetWorth({ ...netWorth, incomeDocs: files })
					}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<LoadingButton
					className={styles.loginBtn}
					variant="contained"
					onClick={handleNetWorth}
					disabled={
						!(
							netWorth.annualIncome &&
							netWorth.netWorth &&
							netWorth.wealthSources.length &&
							(netWorth.annualIncome >= 200000 ||
								netWorth.netWorth >= 1000000)
						)
					}
					loading={loading}
					children={'Continue'}
				/>
			</Grid>
		</Grid>
	);
}
