import {
	Grid,
	InputLabel,
	TextField,
	Autocomplete,
	Alert,
	AlertTitle,
} from '@material-ui/core';
import { useState } from 'react';
import styles from './OnboardingForm.module.scss';
import LoadingButton from '../../LoadingButton/LoadingButton';
import AuthService from '../../../services/AuthService';

export default function InvestorExperienceForm({
	handleExperience: onSuccess,
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [investorExperience, setInvestorExperience] = useState({
		publicSecurities: '',
		privateSecurities: '',
	});
	const handleExperience = () => {
		setLoading(true);
		AuthService.onboarding({
			investorExperience,
		}).then(({ success, errorReason, error: { message } = {} }) => {
			setLoading(false);
			if (success) {
				onSuccess();
			}

			setError(errorReason || message);
		});
	};

	return (
		<Grid container spacing={4}>
			{error && (
				<Grid item xs={12} md={12}>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{error}
					</Alert>
				</Grid>
			)}
			<Grid item xs={12} md={12}>
				<span>
					Tell us a little bit about your investment background and
					experience.
				</span>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Public securities</InputLabel>
				<Autocomplete
					disablePortal
					options={[
						'None',
						'1-3 years',
						'4-6 years',
						'7-9 years',
						'10+',
					]}
					renderInput={(params) => (
						<TextField {...params} placeholder={'Select period'} />
					)}
					onChange={(e, value) => {
						setInvestorExperience({
							...investorExperience,
							publicSecurities: value,
						});
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<InputLabel>Private securities</InputLabel>
				<Autocomplete
					disablePortal
					options={[
						'None',
						'1-3 years',
						'4-6 years',
						'7-9 years',
						'10+',
					]}
					renderInput={(params) => (
						<TextField {...params} placeholder={'Select period'} />
					)}
					onChange={(e, value) => {
						setInvestorExperience({
							...investorExperience,
							privateSecurities: value,
						});
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<LoadingButton
					className={styles.loginBtn}
					variant="contained"
					onClick={handleExperience}
					disabled={
						!(
							investorExperience.publicSecurities &&
							investorExperience.privateSecurities
						)
					}
					loading={loading}
					children={'Continue'}
				/>
			</Grid>
		</Grid>
	);
}
