/* eslint-disable no-console */
import { NavLink } from 'react-router-dom';
import styles from './MainAppNavigation.module.scss';
import logo from '../../img/um-blue.svg';
import UserDropdown from '../UserDropdown/UserDropdown';

export default function MainAppNavigation() {
	return (
		<div className={styles.navigation}>
			<div className={styles.logo}>
				<NavLink exact={true} to="/">
					<img src={logo} alt="Unknown moon" />
				</NavLink>
			</div>
			<div className={styles.link}>
				<NavLink exact={true} to="/" activeClassName={styles.active}>
					Private Equity
				</NavLink>
			</div>
			<div className={styles.link}>
				<NavLink
					exact={true}
					to="/file-vault"
					activeClassName={styles.active}
				>
					File Vault
				</NavLink>
			</div>
			<div className={styles.user}>
				<UserDropdown />
			</div>
		</div>
	);
}
