import CookieConsent from 'react-cookie-consent';

export default function CookieInfo() {
	return (
		<CookieConsent
			buttonText="Accept"
			cookieName="um-consent"
			style={{ background: '#21272A' }}
			buttonStyle={{
				color: '#F2F4F8',
				fontSize: '14px',
				background: '#00539A',
				padding: '12px 8px',
				fontWeight: '600',
				margin: '12px 25px',
			}}
		>
			This website uses cookies to enhance the user experience.
		</CookieConsent>
	);
}
