import { Folder } from 'tabler-icons-react';
import styles from './FileVaultEmpty.module.scss';

export const FileVaultEmpty = () => {
	return (
		<div className={styles.container}>
			<Folder className={styles.icon} size={90} />
			<div className={styles.title}>This folder is empty</div>
			<p>
				Use the “New” button to upload a file or create a folder here.
			</p>
		</div>
	);
};
