import Head from 'components/Head';
import CookieInfo from './CookieInfo/CookieInfo';
import MainAppNavigation from './MainAppNavigation/MainAppNavigation';

/**
 * Simple frame for most app pages that keeps pages DRY.
 * Wraps the page in an `<AppFrame>`, adds a `<Head>` for SEO,
 * places the `<MainNavigation>` at top, then renders any
 * children given.
 *
 * @param {string} props.title Page Title (for the <title> tag)
 * @param {*} props.children Child nodes to render on the page
 */
export default function PageFrame({ title, children }) {
	return (
		<>
			<Head title={title} />
			<MainAppNavigation />
			{children}

			<CookieInfo />
		</>
	);
}
