import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Link,
	TableFooter,
} from '@material-ui/core';
import { moneyNumberFormat } from '../../utils/numbers';
import CollapsedFundRow from '../CollapsedFundRow/CollapsedTableRow';
import styles from './FundsTable.module.scss';

/* eslint-disable no-console */

export default function FundsTable({
	funds,
	selectedYear,
	// order,
	// orderBy,
	// onRequestSort,
}) {
	return (
		<TableContainer component={Paper} className={styles.table}>
			<Table aria-label="funds table" stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell align="left">Fund Name</TableCell>
						<TableCell align="right">Target Valuation</TableCell>
						<TableCell align="right">Revenue</TableCell>
						<TableCell align="right">Target Earnings</TableCell>
						<TableCell align="right">Target Equity</TableCell>
						<TableCell align="right">Total Return</TableCell>
						<TableCell align="right">% Complete</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{funds
						.filter((d) => d.id !== 'total')
						.map((fund, index) => (
							<CollapsedFundRow
								key={index}
								fund={fund}
								year={selectedYear}
							/>
							// <TableRow key={index}>
							// 	<TableCell align="left">
							// 		<Link href="#">{fund.name}</Link>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			$
							// 			{moneyNumberFormat(fund.periods[selectedYear].valuation, 3)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			${moneyNumberFormat(fund.periods[selectedYear].revenue, 3)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			${moneyNumberFormat(fund.periods[selectedYear].ebitda, 3)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			$
							// 			{moneyNumberFormat(
							// 				fund.periods[selectedYear].contribution,
							// 				3,
							// 			)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			$
							// 			{moneyNumberFormat(
							// 				fund.periods[selectedYear].unitContribution,
							// 				3,
							// 			)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			{moneyNumberFormat(fund.periods[selectedYear].daysCash, 3)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			$
							// 			{moneyNumberFormat(
							// 				fund.periods[selectedYear].equityInvstmnt,
							// 				3,
							// 			)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			${moneyNumberFormat(fund.periods[selectedYear].return, 3)}
							// 		</Typography>
							// 	</TableCell>
							// 	<TableCell align="right">
							// 		<Typography fontFamily={'numbers'} className={styles.value}>
							// 			{moneyNumberFormat(fund.periods[selectedYear].duration, 3)}
							// 		</Typography>
							// 	</TableCell>
							// </TableRow>
						))}
				</TableBody>
				<TableFooter className={styles.footer}>
					{funds
						.filter((t) => t.id === 'total')
						.map((fund, index) => (
							<TableRow className={styles.dealsTotal} key={index}>
								<TableCell />
								<TableCell
									align="left"
									className={styles.cellName}
								>
									<Link href="#">{fund.name}</Link>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											fund.periods[selectedYear]
												.valuation,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											fund.periods[selectedYear].revenue,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											fund.periods[selectedYear].ebitda,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											fund.periods[selectedYear]
												.equityInvstmnt,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										$
										{moneyNumberFormat(
											fund.periods[selectedYear].return,
											3,
										)}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										fontFamily={'numbers'}
										className={styles.value}
									>
										{moneyNumberFormat(
											fund.periods[selectedYear][
												'% Complete'
											],
											3,
										)}
										%
									</Typography>
								</TableCell>
							</TableRow>
						))}
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
