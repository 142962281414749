/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { Alert, AlertTitle, Container, Grid } from '@material-ui/core';
import FileVaultDashboard from '../FileVaultDashboard/FileVaultDashboard';
import FileVaultMenu from '../FileVaultMenu/FileVaultMenu';
import PageFrame from '../PageFrame';
import styles from './FileVault.module.scss';
import FileVaultService from '../../services/FileVaultService';

// import styles from './FileVault.module.scss';

export default function FileVault() {
	const [activeTab, setActiveTab] = useState('overview');
	const [error, setError] = useState();

	useEffect(() => {
		FileVaultService.getDocumentsList().then(
			({ success, errorReason, error: { message } = {} }) => {
				if (!success) {
					setError(errorReason || message);
				}
			},
		);
	}, []);

	const handleTabChange = (tab) => {
		setActiveTab(tab);
		FileVaultService.resetSearchState();
	};

	return (
		<PageFrame title="File Vault">
			<Container maxWidth="false" className={styles.container}>
				<Grid container>
					{error && (
						<Grid item xs={12} md={12}>
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{error}
							</Alert>
						</Grid>
					)}
					<Grid item md={2}>
						<FileVaultMenu
							active={activeTab}
							onTabChange={(tab) => handleTabChange(tab)}
						/>
					</Grid>
					<Grid item md={10}>
						<FileVaultDashboard tab={activeTab} />
					</Grid>
				</Grid>
			</Container>
		</PageFrame>
	);
}
