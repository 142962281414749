import { Box, Grid, Alert, AlertTitle } from '@material-ui/core';
import { useState, useEffect, useCallback } from 'react';

import { CircleCheck } from 'tabler-icons-react';

import { useHistory } from 'react-router-dom';
import FormHeader from '../../FormHeader/FormHeader';
import styles from './OnboardingForm.module.scss';

import OnboardingProgress from '../../OnboardingProgress/OnboardingProgress';
import AccoutnCreation from './AccountCreation';
import LoadingButton from '../../LoadingButton/LoadingButton';
import AddressForm from './AddressForm';
import InvestorNetWorth from './InvestorNetWorthForm';
import InvestorExperienceForm from './InvestorExeperienceForm';
// Client changed their mind, not signing docs in onboarding flow for now
// import DocuSignForm from './DocuSignForm';
import TwoFactorOnboardingForm from './TwoFactorOnboardingForm/TwoFactorOnboardingForm';
import { useProfile } from '../../../services/AuthService';

export default function OnboardingForm() {
	const history = useHistory();
	const profile = useProfile();

	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState();
	const [error] = useState();

	const [step, setStep] = useState(1);

	const codeVerifyCallback = useCallback(() => {
		setLoading(true);
		setStep(3);
		setLoading(false);
	}, []);

	const handleAddress = useCallback(() => {
		setStep(4);
	}, []);

	const handleNetWorth = useCallback(() => {
		setStep(5);
	}, []);

	const handleExperience = useCallback(() => {
		setStep(7);
	}, []);

	// Client changed their mind, not signing docs in onboarding flow for now
	// const handleDocuSign = useCallback(() => {
	// 	setStep(7);
	// }, []);

	useEffect(() => {
		if (profile.user) {
			const {
				name,
				address,
				netWorth,
				investorExperience,
				phoneNumVerified,
				// documentSignatures,
				hasPassword,
			} = profile.user;

			if (!name || !hasPassword) {
				setStep(1);
			} else if (!phoneNumVerified) {
				setStep(2);
			} else if (!address) {
				setStep(3);
			} else if (!netWorth) {
				setStep(4);
			} else if (!investorExperience) {
				setStep(5);
			} else {
				// Client changed their mind, not signing docs in onboarding flow for now
				setStep(7);
			}
			//  else if (
			// 	!documentSignatures ||
			// 	!documentSignatures.oak ||
			// 	!documentSignatures.tax ||
			// 	!documentSignatures.w9 ||
			// 	!documentSignatures.oak.signed ||
			// 	!documentSignatures.tax.signed ||
			// 	!documentSignatures.w9.signed
			// ) {
			// 	setStep(6);
			// }
		}
	}, [profile]);

	useEffect(() => {
		switch (step) {
			case 1:
			default:
				setTitle('Create your Account');
				break;
			case 2:
				setTitle('Two Factor Authentication');
				break;
			case 3:
				setTitle('Address');
				break;
			case 4:
				setTitle('Investor Net Worth');
				break;
			case 5:
				setTitle('Investor Experience');
				break;
			case 6:
				setTitle('Document Signature');
				break;
			case 7:
				setTitle('Congratulations');
				break;
		}
	}, [step]);

	const handleAccountCreation = useCallback(() => {
		setStep(2);
	}, []);

	return (
		<Box fixed className={styles.formContainer}>
			<OnboardingProgress total={7} step={step} />
			<FormHeader title={title} />
			{step === 1 && (
				<AccoutnCreation handleSuccess={handleAccountCreation} />
			)}
			{step === 2 && (
				<TwoFactorOnboardingForm callback={codeVerifyCallback} />
			)}
			{step === 3 && <AddressForm handleAddress={handleAddress} />}
			{step === 4 && <InvestorNetWorth handleNetWorth={handleNetWorth} />}
			{step === 5 && (
				<InvestorExperienceForm handleExperience={handleExperience} />
			)}
			{/* {
				// Client changed their mind, not signing docs in onboarding flow for now
				step === 6 && <DocuSignForm handleDocuSign={handleDocuSign} />
			} */}
			{step === 7 && (
				<Grid container spacing={4}>
					{error && (
						<Grid item xs={12} md={12}>
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{error}
							</Alert>
						</Grid>
					)}
					<Grid item xs={12} md={12}>
						<span>
							Your account is created. You can start exploring our
							investment opportunities.
						</span>
					</Grid>
					<Grid item xs={12} md={12}>
						<div className={styles.finalIcon}>
							<CircleCheck size={74} />
						</div>
					</Grid>
					<Grid item xs={12} md={12}>
						<LoadingButton
							className={styles.loginBtn}
							variant="contained"
							loading={loading}
							children={'Go to Dashboard'}
							onClick={() => history.push('/')}
						/>
					</Grid>
				</Grid>
			)}
		</Box>
	);
}
