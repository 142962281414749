/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */

import { useState, useEffect, useMemo, useCallback } from 'react';
import {
	Container,
	Grid,
	Select,
	MenuItem,
	Button,
	Box,
	Tabs,
	Alert,
	AlertTitle,
	Tab,
	CircularProgress,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { generateAxisLabels } from 'shared/utils/mockDealData';
import { useHistory } from 'react-router-dom';
import { ChevronDown } from 'tabler-icons-react';
import DashboardKPI from '../DashboardKPI/DashboardKPI';
import styles from './PrivateEquityDashboard.module.scss';
// import SharedKpiGridStyles from './SharedKpiGridStyles.module.scss';
import Graph from '../Graph/Graph';
import TabPanel from '../TabPanel/TabPanel';
import DealsTable from '../DealsTable/DealsTable';
import FundsTable from '../FundsTable/FundsTable';
import PEService from '../../services/PEService';

export default function PrivateEquityDashboard() {
	const [error, setError] = useState();
	const [loading, setLoading] = useState();

	const [deals, setDeals] = useState([]);
	const [funds, setFunds] = useState([]);
	const [labels, setLabels] = useState([]);
	const [sortedDeals, setSortedDeals] = useState([]);
	const [selectedDealId, setSelectedDealId] = useState('');
	const [deal, setDeal] = useState('');

	const [selectedYear, setSelectedYear] = useState(
		moment().format('YYYY-MM'),
	);
	const [selectedIndex, setSelectedIndex] = useState(
		moment().format('M') - 1,
	);

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('status');

	const handleRequestSort = useCallback(
		(property) => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		},
		[order, orderBy],
	);

	const sortDeals = useCallback(
		(dealsToSort) => {
			dealsToSort.sort((a, b) => {
				if (a[orderBy] < b[orderBy]) {
					return order === 'desc' ? 1 : -1;
				}
				if (a[orderBy] > b[orderBy]) {
					return order === 'desc' ? -1 : 1;
				}
				return 0;
			});

			return dealsToSort;
		},
		[order, orderBy],
	);

	useEffect(() => {
		setLoading(true);
		PEService.getPEDashboard().then(
			({
				success,
				deals: receivedDeals,
				funds: receivedFunds,
				// labels: receivedLabels,
				errorReason,
				error: { message } = {},
			}) => {
				setLoading(false);
				if (success) {
					const sortedDealsMock = sortDeals([...receivedDeals]);
					setDeals(receivedDeals);
					setFunds(receivedFunds);

					if (receivedDeals.length > 0) {
						const dealsLabels = Object.keys(
							receivedDeals[0].kpiValues,
						).slice(0, 12);

						setLabels(dealsLabels);
					} else {
						setLabels(generateAxisLabels());
					}
					setSortedDeals(sortedDealsMock);
					setSelectedDealId(receivedDeals[0]?.id);
				} else {
					setError(errorReason || message);
				}
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (deals.length) {
			const sortedDealsMock = sortDeals([...deals]);
			setSortedDeals(sortedDealsMock);
		}
	}, [deals, order, sortDeals]);

	useEffect(() => {
		let filteredDeals = deals.filter((d) => d.id === selectedDealId);
		setDeal(filteredDeals[0]);
	}, [deals, selectedDealId]);

	const a11yProps = useCallback((index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}, []);

	const [value, setValue] = useState(0);

	const handleChange = useCallback((e, newValue) => {
		setValue(newValue);
	}, []);

	const handleDealSelect = useCallback((dealId) => {
		setSelectedDealId(dealId);
	}, []);

	const handleGraphClick = useCallback((index, xAxis) => {
		setSelectedIndex(index);
		setSelectedYear(xAxis);
	}, []);

	const dealKpiSeries = useMemo(() => {
		return (
			Array.from(
				deal?.kpiHighlights?.filter(
					(i) =>
						[
							'Target Valuation',
							'Total Return',
							'Invested Equity',
						].includes(i.label) && i.data.length > 0,
				) || [],
			) || []
		);
	}, [deal?.kpiHighlights]);

	// const { labels, dealKpiSeries } = dataForCrash;

	// console.log(`Debug for staging crash`, {
	// 	dealKpiSeries,
	// 	labels,
	// 	selectedIndex,
	// });

	return (
		<Container maxWidth="false" className={styles.container}>
			{loading && (
				<>
					<CircularProgress />
					Loading ...
				</>
			)}
			{!loading && (
				<Grid container className={styles.section}>
					<Grid item xs={12} md={12}>
						{!!deals?.length && (
							<Select
								className={classNames(
									styles.dealSelector,
									styles.selectStyles,
								)}
								value={selectedDealId}
								onChange={(e) =>
									setSelectedDealId(e.target.value)
								}
							>
								{deals.map((dealOption, index) => (
									<MenuItem key={index} value={dealOption.id}>
										{dealOption.name}
									</MenuItem>
								))}
							</Select>
						)}

						{!!labels?.length && (
							<Select
								className={classNames(
									styles.periodSelector,
									styles.selectStyles,
								)}
								value={
									labels.find(
										(label, index) =>
											label === selectedYear &&
											index === selectedIndex,
									)
										? `${selectedIndex}|${selectedYear}`
										: `${(labels?.length || 2) - 2}|${
												labels[labels?.length - 2] || ''
										  }`
								}
								onChange={(e) => {
									const data = e.target.value.split('|');
									setSelectedIndex(parseInt(data[0], 10));
									setSelectedYear(data[1]);
								}}
							>
								{labels.map((label, index) => (
									<MenuItem
										key={index}
										value={`${index}|${label}`}
										className={
											label ===
												moment().format('YYYY-MM') ||
											label === 'Target Projection'
												? styles.markedLabel
												: ''
										}
									>
										{label === moment().format('YYYY-MM')
											? 'Today'
											: label}
									</MenuItem>
								))}
							</Select>
						)}
					</Grid>
				</Grid>
			)}

			{!!error && (
				<Grid item xs={12} md={12}>
					<Grid item xs={12} md={12}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					</Grid>
				</Grid>
			)}

			{!!deal && (
				<Grid
					container
					className={classNames(styles.kpiGrid, styles.section)}
				>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Invested Equity'}
							value={
								Object.values(deal?.kpiValues)[selectedIndex] &&
								Object.values(deal?.kpiValues)[selectedIndex][
									'Invested Equity'
								]
							}
							changeValue={3456290}
							changePercent={34.82}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Valuation'}
							value={
								Object.values(deal?.kpiValues)[selectedIndex] &&
								Object.values(deal?.kpiValues)[selectedIndex][
									'Target Valuation'
								]
							}
							changeValue={3549020}
							changePercent={27.4}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Total Return'}
							value={
								Object.values(deal?.kpiValues)[selectedIndex] &&
								Object.values(deal?.kpiValues)[selectedIndex][
									'Target Total Return'
								]
							}
							changeValue={34588230}
							changePercent={4.9}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Revenue'}
							value={
								Object.values(deal?.kpiValues)[selectedIndex] &&
								Object.values(deal?.kpiValues)[selectedIndex][
									'Target Revenue'
								]
							}
							changeValue={67802782}
							changePercent={5.6}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Equity'}
							value={
								Object.values(deal?.kpiValues)[selectedIndex] &&
								Object.values(deal?.kpiValues)[selectedIndex][
									'Target Equity'
								]
							}
							changeValue={44673950}
							changePercent={7.89}
						/>
					</Grid>
					{/* <Grid item md={2}>
						<div className={styles.simulator}>
							<p>
								Try out the Investment simulator. See the
								projected performance of your investment.
							</p>
							<Button
								variant={'outlined'}
								onClick={() => history.push('/calculator')}
							>
								Investment Simulator
							</Button>
						</div>
					</Grid> */}
				</Grid>
			)}

			{!!dealKpiSeries?.length && labels?.length && (
				<Grid container className={classNames(styles.graphContainer)}>
					<Grid item md={12}>
						<Accordion defaultExpanded={true}>
							<AccordionSummary
								expandIcon={<ChevronDown />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								className={styles.title}
							>
								{deal?.name} - Performance
							</AccordionSummary>
							<AccordionDetails className={styles.graphDetails}>
								<Graph
									series={dealKpiSeries}
									clickHandler={handleGraphClick}
									selectedIndex={selectedIndex}
									xAxisLabels={labels}
									height={500}
								/>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
			)}

			{deal && (
				<Grid
					container
					className={classNames(
						styles.section,
						styles.tableContainer,
					)}
				>
					<Grid item md={12}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
							>
								<Tab label="Deals" {...a11yProps(0)} />
								<Tab label="Funds" {...a11yProps(1)} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<DealsTable
								selectedYear={selectedYear}
								selectedIndex={selectedIndex}
								deals={sortedDeals}
								order={order}
								orderBy={orderBy}
								selectedDealId={selectedDealId}
								onDealSelect={handleDealSelect}
								onRequestSort={handleRequestSort}
							/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<FundsTable
								selectedYear={selectedYear}
								funds={funds}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
							/>
						</TabPanel>
					</Grid>
				</Grid>
			)}
		</Container>
	);
}
