import { useState, useEffect } from 'react';
import {
	Container,
	Grid,
	Select,
	MenuItem,
	Box,
	Tabs,
	Tab,
	Breadcrumbs,
	Alert,
} from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
// import { useHistory } from 'react-router-dom';
import { ChevronRight } from 'tabler-icons-react';
import DashboardKPI from '../DashboardKPI/DashboardKPI';
import styles from './FundInfo.module.scss';
// import SharedKpiGridStyles from '../PrivateEquityDashboard/SharedKpiGridStyles.module.scss';
import TabPanel from '../TabPanel/TabPanel';
import Graph from '../Graph/Graph';
import DealsTable from '../DealsTable/DealsTable';

/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */

export default function FundInfo({ fund }) {
	const [labels, setLabels] = useState([]);
	const [deal, setDeal] = useState(null);
	// const [deals, setDeals] = useState([]);

	const [selectedYear, setSelectedYear] = useState(
		moment().format('YYYY-MM'),
	);
	const [selectedIndex, setSelectedIndex] = useState(
		moment().format('M') - 1,
	);

	useEffect(() => {
		if (fund.deals) {
			const deals = fund.deals.filter((f) => f.deal);

			const dealsLabels =
				(deals[0].deal &&
					Object.keys(deals[0].deal.kpiValues).slice(0, 12)) ||
				[];
			setLabels(dealsLabels);
			setDeal(deals[0].deal);
			// setDeals(deals.map((d) => d.deal));
		}
	}, []);

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};

	const [value, setValue] = useState(0);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	const handleGraphClck = (index, xAxis) => {
		setSelectedIndex(index);
		setSelectedYear(xAxis);
	};

	if (!fund) {
		return <>Not found</>;
	}

	if (!deal) {
		return (
			<Container maxWidth="false" className={styles.container}>
				<Grid container className={styles.section}>
					<Grid item xs={12} md={12}>
						<Breadcrumbs
							className={styles.breadcrumbs}
							separator={<ChevronRight size={20} />}
						>
							<div key={'fund2'} className={styles.breadcrumb}>
								{fund.name}
							</div>
						</Breadcrumbs>
						<br />
						<Alert severity="info">No deals for this fund</Alert>
					</Grid>
				</Grid>
			</Container>
		);
	}

	const handleDealSelect = (dealId) => {
		const listOfDeals = fund.deals.map((d) => d && d.deal);
		const newDeal = listOfDeals.filter((d) => d && d.id === dealId);
		setDeal(newDeal[0]);
	};

	return (
		<Container maxWidth="false" className={styles.container}>
			<Grid container className={styles.section}>
				<Grid item xs={12} md={12}>
					<Breadcrumbs
						className={styles.breadcrumbs}
						separator={<ChevronRight size={20} />}
					>
						<div key={'fund2'} className={styles.breadcrumb}>
							{fund.name}
							<Select
								className={styles.periodSelector}
								value={`${selectedIndex}|${selectedYear}`}
								onChange={(e) => {
									const data = e.target.value.split('|');
									setSelectedIndex(parseInt(data[0], 10));
									setSelectedYear(data[1]);
								}}
							>
								{labels.map((label, index) => (
									<MenuItem
										key={index}
										value={`${index}|${label}`}
										className={
											label ===
												moment().format('YYYY-MM') ||
											label === 'Target Projection'
												? styles.markedLabel
												: ''
										}
									>
										{label === moment().format('YYYY-MM')
											? 'Today'
											: label}
									</MenuItem>
								))}
							</Select>
						</div>
					</Breadcrumbs>
				</Grid>
			</Grid>
			{deal && (
				<Grid
					container
					className={classNames(styles.kpiGrid, styles.section)}
				>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Invested Equity'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Invested Equity'
								]
							}
							changeValue={3456290}
							changePercent={34.82}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Valuation'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Valuation'
								]
							}
							changeValue={3549020}
							changePercent={27.4}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Total Return'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Total Return'
								]
							}
							changeValue={34588230}
							changePercent={4.9}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Revenue'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Revenue'
								]
							}
							changeValue={67802782}
							changePercent={5.6}
						/>
					</Grid>
					<Grid item md className={styles.kpi}>
						<DashboardKPI
							name={'Target Equity'}
							value={
								Object.values(deal.kpiValues)[selectedIndex] &&
								Object.values(deal.kpiValues)[selectedIndex][
									'Target Equity'
								]
							}
							changeValue={44673950}
							changePercent={7.89}
						/>
					</Grid>
				</Grid>
			)}

			{deal && (
				<Grid
					container
					className={classNames(
						styles.section,
						styles.tableContainer,
					)}
				>
					<Grid item md={12}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
							>
								<Tab label="Fund Deals" {...a11yProps(0)} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<div className={styles.description}>
								<DealsTable
									onDealSelect={handleDealSelect}
									selectedYear={selectedYear}
									deals={fund.deals.map((data) => data.deal)}
									order={'desc'}
									selectedDealId={deal.id}
									selectedIndex={selectedIndex}
								/>
							</div>
						</TabPanel>
					</Grid>
					{/* <Grid item md={4}>
						<div className={styles.videoContainer}>
							<iframe
								width="100%"
								height="325"
								src="https://www.youtube.com/embed/DlA2jMueIyc"
								frameborder="0"
								allowfullscreen
							></iframe>
						</div>
					</Grid> */}

					<Grid item md={12} className={styles.graphContainer}>
						<p className={styles.title}>
							{fund.name} - Performance
						</p>

						{labels.length && (
							<div className={styles.graphDetails}>
								<Graph
									series={deal.kpiHighlights.filter(
										(i) =>
											[
												'Target Valuation',
												'Total Return',
												'Invested Equity',
											].indexOf(i.label) > -1,
									)}
									clickHandler={handleGraphClck}
									selectedIndex={selectedIndex}
									xAxisLabels={labels}
									hideAnnotations
								/>
							</div>
						)}
					</Grid>
				</Grid>
			)}
		</Container>
	);
}
