import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Grid,
	InputLabel,
	TextField,
} from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import FormHeader from '../../FormHeader/FormHeader';
import LoadingButton from '../../LoadingButton/LoadingButton';
import styles from './ResetPasswordForm.module.scss';

export default function ResetPasswordForm({ id, tfaCode }) {
	const history = useHistory();

	const [pass1, setPass1] = useState();
	const [pass2, setPass2] = useState();
	const [reset, setReset] = useState();
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const onResetClick = () => {
		setError(null);
		if (pass1 !== pass2) {
			setError('Passwords do not match');
			return false;
		}

		setLoading(true);
		AuthService.ressetPassword(id, tfaCode, pass1).then(
			({ success, errorReason, error: { message } = {} }) => {
				setLoading(false);
				if (success) {
					setReset(true);
				}
				setError(errorReason || message);
			},
		);

		return true;
	};

	const goToDashboardClick = () => {
		history.push('/');
	};

	return (
		<Box fixed className={styles.formContainer}>
			<FormHeader
				title={
					!reset ? 'Reset your Password' : 'Password reset successful'
				}
			/>
			<Grid container spacing={4}>
				{error && (
					<Grid item xs={12} md={12}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					</Grid>
				)}
				{!reset && (
					<>
						<Grid item xs={12} md={12}>
							<InputLabel>New Password</InputLabel>
							<TextField
								placeholder={'Type your new password'}
								type={'password'}
								onChange={(e) => setPass1(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<InputLabel>Confirm your password</InputLabel>
							<TextField
								placeholder={'Type your new password again'}
								type={'password'}
								onChange={(e) => setPass2(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<LoadingButton
								className={styles.loginBtn}
								variant="contained"
								onClick={onResetClick}
								disabled={!pass1 || !pass2}
								loading={loading}
								children={'Continue'}
							/>
						</Grid>
					</>
				)}
				{reset && (
					<>
						<Grid item xs={12} md={12}>
							<p>You have successfully changed your password.</p>
						</Grid>
						<Grid item xs={12} md={12}>
							<Button
								className={styles.loginBtn}
								variant="contained"
								onClick={goToDashboardClick}
							>
								Continue to Dashboard
							</Button>
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
}
