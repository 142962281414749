import { Video, File, X } from 'tabler-icons-react';

import { useState } from 'react';
import { Modal } from '@material-ui/core';
import styles from './MediaDoc.module.scss';
import FileIcon from '../FileIcon/FileIcon';
import MediaFileViewer from '../MediaFileViewer/MediaFileViewer';

export default function MediaDoc({ file }) {
	const [open, setOpen] = useState(false);

	const handleModalClose = () => {
		setOpen(false);
	};
	return (
		<>
			<div className={styles.container} onClick={() => setOpen(true)}>
				<div className={styles.icon}>
					{file.mimeType === 'video' ? <Video /> : <File />}
				</div>
				<div>{file.name}</div>
			</div>
			<Modal
				open={open}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div className={styles.modal}>
					<div className={styles.title}>
						<FileIcon mimeType={file.mimeType} />
						<div className={styles.fileName}>{file.name} </div>
						<div className={styles.closeBtn}>
							<X onClick={handleModalClose} />
						</div>
					</div>
					<div className={styles.content}>
						{file.mimeType === 'pdf' && (
							<MediaFileViewer file={file} />
						)}
						{file.mimeType === 'video' && (
							<div className={styles.videoContainer}>
								<iframe
									title="Video Player"
									width="100%"
									height="500"
									src={file.link}
									frameborder="0"
									allowfullscreen
								></iframe>
							</div>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
}
