import FileVaultNav from '../FileVaultNav/FileVaultNav';
import FileVaultNewDropdown from '../FileVaultNewDropdown/FileVaultNewDropdown';
import { useFileVault } from '../../services/FileVaultService';

export default function FileVaultMenu({ active, onTabChange }) {
	const fileVault = useFileVault();
	return (
		<div>
			{active !== 'recycle' && fileVault.currentFolder && (
				<FileVaultNewDropdown active={active} />
			)}
			<FileVaultNav onTabChange={onTabChange} active={active} />
		</div>
	);
}
