import {
	Grid,
	TextField,
	Link,
	FormLabel,
	Alert,
	AlertTitle,
	InputLabel,
} from '@material-ui/core';
import { useEffect, useState, useCallback } from 'react';
import MuiPhoneNumber from 'mui-phone-number';
import styles from './TwoFactorOnboardingForm.module.scss';
import LoadingButton from '../../../LoadingButton/LoadingButton';
import AuthService from '../../../../services/AuthService';

export default function TwoFactorOnboardingForm({ callback }) {
	const [sent, setSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [resend, setResend] = useState(false);
	const [code, setCode] = useState();
	const [error, setError] = useState();
	const [verification, setVerification] = useState();

	const [token] = useState();
	const [phone, setPhone] = useState();

	// resend verification code
	const sendVerificationCodeCallback = useCallback(() => {
		setError(null);
		setSent(false);
		setLoading(true);
		AuthService.onboardingVerifyPhone(phone).then(
			({ success, errorReason, error: { message } = {} }) => {
				setLoading(false);
				if (success) {
					setSent(true);
				} else {
					setError(errorReason || message);
				}
			},
		);
	}, [phone]);

	// verify that code what user enters is correct
	const verifyCodeCallback = useCallback(() => {
		setError(null);
		setVerification(true);
		AuthService.onboardingVerifyCode(code).then(
			({ success, errorReason, error: { message } = {} }) => {
				setVerification(false);
				if (success) {
					callback();
				} else {
					setError(errorReason || message);
				}
			},
		);
	}, [code, callback]);

	useEffect(() => {
		if (code && code.length === 6) {
			verifyCodeCallback();
		}

		return function cleanup() {
			setCode(null);
		};
	}, [code, verifyCodeCallback]);

	const onResendClick = (e) => {
		setResend(true);
		e.preventDefault();
		setTimeout(() => {
			setResend(false);
		}, 3000);
		sendVerificationCodeCallback();
	};

	const onCodeChange = (e) => {
		setError(false);
		setCode(e.target.value);
	};

	const handlePhoneChange = () => {
		sendVerificationCodeCallback();
	};

	return (
		<Grid container spacing={4} className={styles.formContainter}>
			<>
				{error && (
					<Grid item xs={12} md={12}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					</Grid>
				)}
				<Grid item xs={12} md={12}>
					{resend && <p>New verification code was sent!</p>}
					{sent ? (
						<p className={styles.description}>
							SMS with a verification code has been sent to{' '}
							<b>{phone}</b>. Please input the verification code
							in the field below.{' '}
							<Link href={'#'} onClick={() => setSent(false)}>
								Wrong number?
							</Link>
						</p>
					) : (
						<p className={styles.description}>
							Two step authentication adds a layer of security to
							your account. We need to verify your phone via SMS.
							Afterwards when signing in you will need to enter
							password and SMS generated Authorisation code.
						</p>
					)}
				</Grid>
				{!sent && !token && (
					<>
						<Grid item xs={12} md={12}>
							<InputLabel>Phone number</InputLabel>
							<MuiPhoneNumber
								className={styles.phoneNumber}
								defaultCountry={'us'}
								onChange={setPhone}
								variant={'outlined'}
								preferredCountries={['us']}
								disableAreaCodes={true}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<LoadingButton
								className={styles.loginBtn}
								variant="contained"
								onClick={handlePhoneChange}
								disabled={!phone || phone.length < 5}
								loading={loading}
								children={'Continue'}
							/>
						</Grid>
					</>
				)}
				{phone && (sent || token) && (
					<Grid item xs={12} md={12}>
						<TextField
							disabled={verification}
							placeholder={'_ _ _ _ _ _'}
							onChange={onCodeChange}
							className={styles.twoFactorInput}
							inputProps={{ maxlength: 6 }}
						/>
						<FormLabel>
							I haven't receved an SMS.{' '}
							<Link href={'#'} onClick={onResendClick}>
								Resend
							</Link>
						</FormLabel>
					</Grid>
				)}
			</>
		</Grid>
	);
}
