import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { generateMockDeals } from 'shared/utils/mockDealData';
import { Alert, AlertTitle, CircularProgress } from '@material-ui/core';
import PEService from '../../services/PEService';
import DealInfo from '../DealInfo/DealInfo';
import PageFrame from '../PageFrame';

export default function DealPage() {
	const [deal, setDeal] = useState(null);
	const [error, setError] = useState(null);

	const [loading, setLoading] = useState(false);

	const { id } = useParams();

	// const temmp = generateMockDeals(1)[0];

	useEffect(() => {
		setLoading(true);
		PEService.getDeal(id).then(
			({ success, errorReason, error: { message } = {}, result }) => {
				setLoading(false);
				if (!success) {
					setError(errorReason || message);
				} else {
					setDeal(result);
				}
			},
		);
	}, [id]);

	return (
		<PageFrame title="Deal information">
			{error && deal && false && (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{error}
				</Alert>
			)}

			{loading && <CircularProgress sx={{ padding: '14px' }} />}
			{deal && <DealInfo deal={deal} />}
		</PageFrame>
	);
}
