import {
	Grid,
	InputLabel,
	Autocomplete,
	TextField,
	Button,
	Modal,
	Box,
	Alert,
	AlertTitle,
} from '@material-ui/core';
import { Trash } from 'tabler-icons-react';
import { useState, useEffect } from 'react';
import styles from './EditUser.module.scss';
import UserService from '../../../services/UserService';

export default function EditUser({ user, handleClose }) {
	const [newRole, setNewRole] = useState(null);
	const [showDeleteWindow, setShowDeleteUser] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setNewRole(user.isAdmin ? 'Admin' : 'Client');
	}, [user]);

	const handleSave = () => {
		UserService.updateUser(user.id, {
			isAdmin: newRole === 'Admin',
			isClient: newRole !== 'Admin',
		}).then(({ success, errorReason, error: { message } = {} }) => {
			if (success) {
				handleClose();
			} else {
				setError(errorReason || message);
			}
		});
	};

	const deleteUser = () => {
		UserService.updateUser(user.id, { isDeleted: true }).then(
			({ success, errorReason, error: { message } = {} }) => {
				if (success) {
					setShowDeleteUser(false);
					handleClose();
				} else {
					setError(errorReason || message);
				}
			},
		);
	};

	return (
		<div className={styles.editUser}>
			<Grid container spacing={2} className={styles.properties}>
				<Grid item md={3} className={styles.label}>
					Status
				</Grid>
				<Grid item md={9}>
					{user?.investorExperience ? 'Active' : 'Pending'}
					{/* {user.documentSignatures &&
					Object.values(user.documentSignatures).every(
						(o) => o.signed,
					)
						? 'Active'
						: 'Pending'} */}
				</Grid>
				<Grid item md={3} className={styles.label}>
					Email
				</Grid>
				<Grid item md={9}>
					{user.email}
				</Grid>
				<Grid item xs={12} md={12}>
					<InputLabel>Role</InputLabel>
					<Autocomplete
						disablePortal
						options={['Admin', 'Client']}
						value={newRole}
						clearIcon={false}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={'Select user role'}
							/>
						)}
						onChange={(e, value) => setNewRole(value)}
					/>
				</Grid>
				{error && (
					<Grid item xs={12} md={12}>
						<Grid item xs={12} md={12}>
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{error}
							</Alert>
						</Grid>
					</Grid>
				)}
			</Grid>

			<div className={styles.actions}>
				<Button
					className={styles.deleteBtn}
					variant="outlined"
					onClick={() => setShowDeleteUser(true)}
				>
					Delete &nbsp;&nbsp;
					<Trash size={16} />
				</Button>
				{user.role !== newRole && (
					<div className={styles.right}>
						<Button
							variant="outlined"
							className={styles.cancelBtn}
							onClick={() => handleClose()}
						>
							Cancel
						</Button>
						<Button variant="contained" onClick={handleSave}>
							Save
						</Button>
					</div>
				)}
			</div>
			<Modal
				open={showDeleteWindow}
				onClose={() => setShowDeleteUser(false)}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
				<Box className={styles.modal}>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12}>
							<h2>Delete?</h2>
						</Grid>
						<Grid item md={12} sm={12}>
							Are you sure you want to delete this user?
							{error && (
								<Grid item xs={12} md={12}>
									<Grid item xs={12} md={12}>
										<Alert severity="error">
											<AlertTitle>Error</AlertTitle>
											{error}
										</Alert>
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid item md={12} sm={12}>
							<div className={styles.btnGroup}>
								<Button
									variant={'outlined'}
									onClick={() => setShowDeleteUser(false)}
								>
									Cancel
								</Button>
								<Button
									variant={'contained'}
									onClick={deleteUser}
								>
									Delete
								</Button>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}
