import FunctionalState, {
	useFunctionalState,
} from 'shared/components/FunctionalState';
import ApiService from './ApiService';

export default class PEService {
	static dashboardState = new FunctionalState({
		deals: [],
		funds: [],
		labels: [],
	});

	static async getPEDashboard() {
		const result = await ApiService.getPEDashboard();
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { deals, funds, labels } = result;

		this.updateDashboardState(result);

		return { success: true, deals, funds, labels };
	}

	static async getDeal(id) {
		const result = await ApiService.getDeal(id);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true, result };
	}

	static async getFund(id) {
		const result = await ApiService.getFund(id);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		return { success: true, result };
	}

	static async getEstimation(amount) {
		const result = await ApiService.getEstimation(amount);
		if (result instanceof Error) {
			const {
				response: { data: { error: { message } = {} } = {} } = {},
			} = result;

			return { error: result, errorReason: message };
		}

		const { deals, funds, labels } = result;

		this.updateDashboardState({
			estimation: {
				deals,
				funds,
				labels,
			},
		});

		return {
			success: true,
			deals,
			funds,
			labels,
		};
	}

	static updateDashboardState({ deals, funds, labels, estimation }) {
		this.dashboardState.setState({
			deals,
			funds,
			labels,
			estimation,
		});
	}
}

// Export our FileVault as a state
export const useDashboard = () => {
	return useFunctionalState(PEService.dashboardState);
};
