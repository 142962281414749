/* eslint-disable react-hooks/exhaustive-deps */
import { FileExport, ChevronRight, Folder } from 'tabler-icons-react';
import { useState, useEffect } from 'react';
import {
	Modal,
	Box,
	Grid,
	Button,
	Breadcrumbs,
	Table,
	TableCell,
	TableHead,
	TableBody,
	TableRow,
	CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import styles from './FileVaultMoveBtn.module.scss';
import FileVaultService from '../../services/FileVaultService';
import FileVaultToast from '../FileVaultToast/FileVaultToast';
import FileVaultNewFolderRow from '../FIleVaultNewFolderRow/FileVaultNewFolderRow';
import { useProfile } from '../../services/AuthService';

/* eslint-disable no-console */
export default function FileVaultMoveBtn(props) {
	const { file, callback, long, label, files, filesInstances, onSuccess } =
		props;
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [folders, setFolders] = useState([]);
	const [currentFolder, setCurrentFolder] = useState();

	const [path, setPath] = useState([]);
	const [loading, setLoading] = useState(false);

	const profile = useProfile();

	const setInitialState = () => {
		setCurrentFolder(null);
		setFolders([
			{
				id: 'root',
				name: 'My File Vault',
			},
			{
				id: 'investors',
				name: 'Investors',
			},
		]);
		setPath([
			{
				id: 'home',
				name: 'Home',
			},
		]);
	};
	const loadFolder = (folderId, name, back = false) => {
		if (folderId === 'home') {
			setInitialState();
			return;
		}
		setLoading(true);
		if (!back) {
			setPath([
				...path,
				{
					id: folderId,
					name,
				},
			]);
		} else {
			const newBreadcrumbs = [];
			for (let i = 0; i < path.length; i++) {
				newBreadcrumbs.push(path[i]);
				if (path[i].id === folderId) {
					break;
				}
			}

			setPath(newBreadcrumbs);
		}
		if (folderId === 'investors') {
			setCurrentFolder(null);
			FileVaultService.getInvestorsFileVaults(false).then(
				({ roots, success, errorReason, error: { message } = {} }) => {
					setLoading(false);
					if (!success) {
						setError(errorReason || message);
					}
					setFolders(
						roots.map((folder) => {
							if (folder.owner) {
								return {
									id: folder.id,
									name: folder.owner.name,
								};
							}
							return null;
						}),
					);
				},
			);
		} else {
			FileVaultService.getDocumentsList(folderId, false, false).then(
				({
					success,
					folders: newFolders,
					errorReason,
					error: { message } = {},
				}) => {
					setLoading(false);
					if (!success) {
						setError(errorReason || message);
					}
					setCurrentFolder(folderId);
					setFolders(newFolders);
				},
			);
		}
	};

	useEffect(() => {
		if (open) {
			if (profile.user.isAdmin) {
				setInitialState();
			} else {
				loadFolder('root', 'My File Vault');
			}
		}

		return () => {
			setFolders([]);
			setPath([]);
		};
	}, [open]);

	const copy = () => {
		setOpen();
		if (callback) {
			callback();
		}
		return toast.promise(
			FileVaultService.move(files || [file.id], currentFolder),
			{
				pending: {
					icon: false,
					closeButton: false,
					render: () => {
						return (
							<FileVaultToast
								title={
									files
										? `Moving ${files.length} files`
										: `Moving file`
								}
								name={
									file ? file.name : `${files.length} items`
								}
								mimeType={file?.mimeType}
								status="loading"
							/>
						);
					},
				},
				success: {
					render: ({ data: { errorReason } }) => {
						if (onSuccess) {
							onSuccess();
						}
						return (
							<FileVaultToast
								title={
									files
										? `${files.length} files moved`
										: `File moved`
								}
								name={
									file ? file.name : `${files.length} items`
								}
								mimeType={file?.mimeType}
								status="finished"
								error={errorReason}
							/>
						);
					},
					closeButton: false,
					icon: false,
					autoClose: 3000,
				},
				error: {
					closeButton: false,
					icon: false,
					autoClose: 4000,
					render: ({ errorReason, error: { message } = {} }) => {
						return (
							<FileVaultToast
								title="Error during the move"
								name={
									file ? file.name : `${files.length} items`
								}
								mimeType={file?.mimeType}
								status="finished"
								error={errorReason || message}
							/>
						);
					},
				},
			},
			{
				position: 'bottom-right',
			},
		);
	};

	const handleFolderCreation = (newFolder) => {
		setFolders([...folders, newFolder]);
	};

	return (
		<>
			{!long ? (
				<FileExport {...props} onClick={() => setOpen(true)} />
			) : (
				<div
					className={styles.longDeleteBtn}
					onClick={() => setOpen(true)}
				>
					<FileExport size={16} />
					{label || 'Move to ...'}
				</div>
			)}
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
				keepMounted={false}
			>
				<Box className={styles.modal}>
					<Grid container>
						<Grid item md={12} sm={12} className={styles.title}>
							<h2>
								Move{' '}
								{file ? file.name : `${files.length} items`}
							</h2>
							{error}
						</Grid>
						<Grid item md={12} sm={12} className={styles.content}>
							<Breadcrumbs
								className={styles.breadcrumbs}
								separator={<ChevronRight size={20} />}
							>
								{path.map(({ id, name }) => (
									<div
										key={id}
										className={styles.breadcrumb}
										onClick={() =>
											loadFolder(id, name, true)
										}
									>
										{name}
									</div>
								))}
							</Breadcrumbs>

							{loading && <CircularProgress />}

							{!loading && (
								<Table aria-label="simple table" stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell
												className={styles.iconCell}
											></TableCell>
											<TableCell align="left">
												Name
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{folders &&
											folders.map((folder, index) => (
												<TableRow
													key={index}
													hover
													onClick={() =>
														loading
															? null
															: loadFolder(
																	folder.id,
																	folder.name,
															  )
													}
												>
													<TableCell
														className={
															styles.iconCell
														}
													>
														<Folder />
													</TableCell>
													<TableCell align="left">
														<span
															className={
																styles.folderName
															}
														>
															{folder.name}
														</span>
													</TableCell>
												</TableRow>
											))}
										<FileVaultNewFolderRow
											folderId={currentFolder}
											callback={handleFolderCreation}
										/>
									</TableBody>
								</Table>
							)}
						</Grid>
						<Grid item md={12} sm={12}>
							<div className={styles.btnGroup}>
								<Button
									variant={'outlined'}
									onClick={() => setOpen(false)}
								>
									Cancel
								</Button>
								<Button
									variant={'contained'}
									onClick={copy}
									disabled={
										(file
											? file.folderId === currentFolder
											: filesInstances[0]?.folderId ===
											  currentFolder) || !currentFolder
									}
								>
									Move
								</Button>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}
