import InvestmentCalculator from '../InvestmentCalculator/InvestmentCalculator';
import PageFrame from '../PageFrame';

export default function InvestmentCalcPage() {
	return (
		<PageFrame title="Investment Calculator">
			<InvestmentCalculator />
		</PageFrame>
	);
}
