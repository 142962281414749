import {
	Button,
	Menu,
	MenuItem,
	Modal,
	Box,
	Grid,
	InputLabel,
	TextField,
	Alert,
	AlertTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
	FolderPlus,
	FileUpload,
	ChevronDown,
	ChevronUp,
} from 'tabler-icons-react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import styles from './FileVaultNewDropdown.module.scss';
import FileVaultService, {
	useFileVault,
} from '../../services/FileVaultService';
import FileVaultUploader from '../FileVaultUploader/FileVaultUploader';
import FileVaultToast from '../FileVaultToast/FileVaultToast';

/* eslint-disable no-console */

export default function FileVaultNewDropdown(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const tab = props.active;

	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [folder, setFolder] = useState();

	const fileVault = useFileVault();

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const createFolder = () => {
		if (folder.trim()) {
			FileVaultService.createFolder(folder.trim()).then(
				({ success, errorReason, error: { message } = {} }) => {
					setLoading(false);
					if (!success) {
						setError(errorReason || message);
					} else {
						// all good, new folder has been created
						setOpenModal(false);
					}
				},
			);
		}
	};

	const handleCreateFolder = (e) => {
		if (e.key === 'Enter' && folder) {
			createFolder();
		}
	};

	const onDrop = (files) => {
		files.map((file) => {
			return toast.promise(
				FileVaultService.uploadFile(file, fileVault.currentFolder, tab),
				{
					pending: {
						icon: false,
						closeButton: false,
						render: () => {
							return (
								<FileVaultToast
									title="Uploading file"
									name={file.name}
									mimeType={file.type}
									status="loading"
								/>
							);
						},
					},
					success: {
						render: () => {
							return (
								<FileVaultToast
									title="File uploaded"
									name={file.name}
									mimeType={file.type}
									status="finished"
								/>
							);
						},
						closeButton: false,
						icon: false,
						autoClose: 2000,
					},
				},
				{
					position: 'bottom-right',
				},
			);
		});
	};

	const {
		getRootProps,
		getInputProps,
		open: openFileDialog,
	} = useDropzone({
		multiple: true,
		onDrop,
		noClick: true,
		noKeyboard: true,
		accept: 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	return (
		<>
			<Button
				className={styles.button}
				id="basic-button"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={
					open ? <ChevronUp size={18} /> : <ChevronDown size={18} />
				}
				variant={'contained'}
			>
				+ New
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				className={styles.menu}
			>
				<MenuItem
					onClick={() => {
						setAnchorEl(null);
						setOpenModal(true);
					}}
				>
					<FolderPlus size={16} /> Create Folder
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAnchorEl(null);
						openFileDialog();
					}}
				>
					<FileUpload size={16} /> Upload File(s)
				</MenuItem>
			</Menu>
			<FileVaultUploader
				rootProps={getRootProps}
				inputProps={getInputProps}
			/>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
				<Box className={styles.modal}>
					{loading && <div>loading...</div>}
					<Grid container spacing={2}>
						{error && (
							<Grid item xs={12} md={12}>
								<Alert severity="error">
									<AlertTitle>Error</AlertTitle>
									{error}
								</Alert>
							</Grid>
						)}
						<Grid item md={12} sm={12}>
							<h2>Please enter the folder name below</h2>
						</Grid>
						<Grid item md={12} sm={12}>
							<InputLabel>Folder name</InputLabel>
							<TextField
								placeholder={'Enter your desired folder name'}
								onKeyDown={handleCreateFolder}
								onChange={(e) => {
									setFolder(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={12} sm={12}>
							<div className={styles.btnGroup}>
								<Button
									variant={'outlined'}
									onClick={() => setOpenModal(false)}
								>
									Close
								</Button>
								<Button
									variant={'contained'}
									disabled={!folder}
									onClick={createFolder}
								>
									Save
								</Button>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}
