import {
	Breadcrumbs,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Alert,
	Grid,
	Button,
	Checkbox,
	CircularProgress,
} from '@material-ui/core';
import { ChevronRight, Folder, Trash, User } from 'tabler-icons-react';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import FileVaultService, {
	useFileVault,
} from '../../services/FileVaultService';
import styles from './FileVaultBrowser.module.scss';
import FileVaultFileRow from '../FileVaultFileRow/FileVaultFileRow';
import FileVaultFileInfo from '../FileVaultFileInfo/FileVaultFileInfo';
import FileVaultPreview from '../FileVaultPreview/FileVaultPreview';
import FileVaultBulkActions from '../FileVaultBulkActions/FileVaultBulkActions';
import FileVaultFolderRow from '../FileVaultFolderRow/FileVaultFolderRow';
import { FileVaultEmpty } from '../FileVaultEmpty/FileVaultEmpty';

/* eslint-disable no-console */

export default function FileVaultBrowser({ tab }) {
	const fileVault = useFileVault();
	const [error, setError] = useState();
	const [fileInfo, setFileInfo] = useState(null);
	const [filePreview, setFilePreview] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [selectedFilesInst, setSelectedFilesInst] = useState([]);
	const [loading, setLoading] = useState(false);
	const [path, setPath] = useState([
		{
			id: 'root',
			name: <>My File Vault</>,
		},
	]);

	const { files, folders, bin, investors } = fileVault;

	useEffect(() => {
		// load recycle bin files/folders
		if (tab === 'recycle') {
			setLoading(true);
			FileVaultService.getRecycleBin().then(
				({ success, errorReason, error: { message } = {} }) => {
					setLoading(false);
					if (!success) {
						setError(errorReason || message);
					}
				},
			);
		} else if (tab === 'overview') {
			setLoading(true);
			setPath([
				{
					id: 'root',
					name: <>My File Vault</>,
				},
			]);
			FileVaultService.getDocumentsList().then(
				({ success, errorReason, error: { message } = {} }) => {
					setLoading(false);
					if (!success) {
						setError(errorReason || message);
					}
				},
			);
		} else if (tab === 'investors') {
			setLoading(true);
			setPath([
				{
					id: 'root',
					name: <>Investor File Vaults</>,
				},
			]);
			FileVaultService.getInvestorsFileVaults().then(
				({ success, errorReason, error: { message } = {} }) => {
					setLoading(false);
					if (!success) {
						setError(errorReason || message);
					}
				},
			);
		}

		return () => {
			setFileInfo(null);
		};
	}, [tab]);

	useEffect(() => {
		if (
			(tab === 'overview' || tab === 'investors') &&
			fileInfo &&
			(files || investors.files).filter(({ id }) => id === fileInfo.id)
				.length === 0
		) {
			setFileInfo(null);
		}

		if (
			tab === 'recycle' &&
			fileInfo &&
			bin?.files.filter(({ id }) => id === fileInfo.id).length === 0
		) {
			setFileInfo(null);
		}
	}, [files, fileInfo, tab, investors?.files, bin?.files]);

	const openFolder = (folderId, name, back = false, investor = false) => {
		setLoading(true);
		setSelectedFiles([]);
		if (!back) {
			setPath([
				...path,
				{
					id: folderId,
					name,
				},
			]);
		} else {
			const newBreadcrumbs = [];
			for (let i = 0; i < path.length; i++) {
				newBreadcrumbs.push(path[i]);
				if (path[i].id === folderId) {
					break;
				}
			}

			setPath(newBreadcrumbs);
		}
		if (folderId === 'root' && tab === 'investors') {
			FileVaultService.getInvestorsFileVaults().then(
				({ success, errorReason, error: { message } = {} }) => {
					if (!success) {
						setError(errorReason || message);
					}
					setLoading(false);
				},
			);
		} else {
			FileVaultService.getDocumentsList(folderId, investor).then(
				({ success, errorReason, error: { message } = {} }) => {
					if (!success) {
						setError(errorReason || message);
					}
					setLoading(false);
				},
			);
		}
	};

	const openFile = (file) => {
		setFilePreview(file);
	};

	const showFileInfo = (file) => {
		if (fileInfo && file.id === fileInfo.id) {
			setFileInfo(null);
		} else {
			setFileInfo(file);
		}
	};

	const emptyBin = () => {
		FileVaultService.emptyBin().then(
			({ success, errorReason, error: { message } = {} }) => {
				if (!success) {
					setError(errorReason || message);
				} else {
					toast('Recycle bin cleaned up');
					setFileInfo(null);
				}
			},
		);
	};

	const onFileSelect = (id, selected = true, file) => {
		if (selected) {
			setSelectedFiles([...selectedFiles, id]);
			setSelectedFilesInst([...selectedFilesInst, file]);
		} else {
			const newSelectedFiles = selectedFiles.filter((f) => f !== id);
			const newInst = selectedFilesInst.filter((f) => f.id !== id);
			setSelectedFiles(newSelectedFiles);
			setSelectedFilesInst(newInst);
		}
	};

	const handleAllFilesSelect = (selected = true) => {
		if (selected) {
			if (tab === 'overview') {
				setSelectedFiles(files.map((f) => f.id));
				setSelectedFilesInst(files);
			} else if (tab === 'investors') {
				setSelectedFiles(investors.files.map((f) => f.id));
				setSelectedFilesInst(investors.files);
			}
		} else {
			setSelectedFiles([]);
			setSelectedFilesInst([]);
		}
	};

	return (
		<Grid container className={styles.container}>
			<Grid
				item
				md={fileInfo ? 9 : 12}
				sx={{ paddingRight: fileInfo ? '16px' : 0 }}
			>
				<Breadcrumbs
					className={styles.breadcrumbs}
					separator={<ChevronRight size={20} />}
				>
					{tab !== 'recycle' &&
						path.map(({ id, name }) => (
							<div
								key={id}
								className={styles.breadcrumb}
								onClick={() =>
									openFolder(
										id,
										name,
										true,
										tab === 'investors',
									)
								}
							>
								{name}
							</div>
						))}
					{tab === 'recycle' && (
						<div>
							Recycle bin{' '}
							<Button
								variant={'outlined'}
								className={styles.emptyBin}
								onClick={() => emptyBin()}
							>
								Empty bin <Trash color={'#00539a'} size={16} />
							</Button>
						</div>
					)}
				</Breadcrumbs>
				{error && <Alert severity="error">{error}</Alert>}
				{loading && <CircularProgress />}
				{!loading && (
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>
									{((files && files.length > 0) ||
										(tab === 'investors' &&
											investors &&
											investors.files &&
											investors.files?.length > 0)) && (
										<Checkbox
											checked={
												(tab === 'overview' &&
													files &&
													selectedFiles.length ===
														files.length) ||
												(tab === 'investors' &&
													investors &&
													investors.files &&
													selectedFiles.length ===
														investors.files.length)
											}
											onChange={(e) =>
												handleAllFilesSelect(
													e.target.checked,
												)
											}
										/>
									)}
								</TableCell>
								{tab !== 'recycle' && <TableCell></TableCell>}
								<TableCell align="left">Name</TableCell>
								{((tab === 'investors' && path.length > 1) ||
									tab !== 'investors') && (
									<>
										<TableCell align="left">
											Owner
										</TableCell>
									</>
								)}
								<TableCell align="left">
									Last Modified
								</TableCell>
							</TableRow>
						</TableHead>
						{tab === 'overview' && (
							<TableBody>
								{folders &&
									folders.map((folder, index) => (
										<FileVaultFolderRow
											key={index}
											folder={folder}
											openFolderCallback={() =>
												openFolder(
													folder.id,
													folder.name,
												)
											}
											loading={loading}
										/>
									))}
								{files &&
									files.map((file, index) => (
										<FileVaultFileRow
											file={file}
											openFileCallback={openFile}
											fileInfoCallback={showFileInfo}
											key={index}
											selected={
												fileInfo &&
												fileInfo.id === file.id
											}
											onFileSelect={onFileSelect}
											selectedFiles={selectedFiles}
										/>
									))}
							</TableBody>
						)}
						{tab === 'recycle' && (
							<TableBody>
								{bin &&
									bin.folders &&
									bin.folders?.map((folder, index) => (
										<TableRow key={index} hover>
											<TableCell>
												<Folder />
											</TableCell>
											<TableCell align="left">
												<span
													className={
														styles.folderName
													}
													onClick={() =>
														openFolder(
															folder.id,
															folder.name,
														)
													}
												>
													{folder.name}
												</span>
											</TableCell>
											<TableCell align="left">
												<span className={styles.user}>
													{folder.owner.name}
												</span>
											</TableCell>
											<TableCell align="left">
												{moment(
													folder.updatedAt,
												).format('MMMM DD, YYYY')}{' '}
												<span>
													{folder.updatedBy.name}
												</span>
											</TableCell>
										</TableRow>
									))}
								{bin &&
									bin.files &&
									bin.files?.map((file, index) => (
										<FileVaultFileRow
											file={file}
											openFileCallback={openFile}
											fileInfoCallback={showFileInfo}
											key={index}
											selected={
												fileInfo &&
												fileInfo.id === file.id
											}
											variant={'recycle'}
										/>
									))}
							</TableBody>
						)}
						{tab === 'investors' && (
							<TableBody>
								{investors &&
									investors.folders &&
									investors.folders.map((folder, index) => {
										if (folder.owner) {
											return (
												<TableRow key={index} hover>
													{folder.id !== 'root' && (
														<TableCell />
													)}
													<TableCell>
														{path.length === 1 ? (
															<User />
														) : (
															<Folder />
														)}
													</TableCell>
													<TableCell align="left">
														<span
															className={
																styles.folderName
															}
															onClick={() =>
																openFolder(
																	folder.id,
																	<>
																		{path.length ===
																		1 ? (
																			<>
																				<User
																					color={
																						'#000'
																					}
																					size={
																						20
																					}
																				/>
																				{
																					folder
																						.owner
																						.name
																				}
																			</>
																		) : (
																			folder.name
																		)}
																	</>,
																	false,
																	true,
																)
															}
														>
															{folder.name === ''
																? folder.owner
																		.name
																: folder.name}
														</span>
													</TableCell>
													{path.length > 1 && (
														<TableCell align="left">
															<span
																className={
																	styles.user
																}
															>
																{
																	folder.owner
																		.name
																}
															</span>
														</TableCell>
													)}
													<TableCell align="left">
														{moment(
															folder.updatedAt,
														).format(
															'MMMM DD, YYYY',
														)}{' '}
														<span>
															{
																folder.updatedBy
																	.name
															}
														</span>
													</TableCell>
												</TableRow>
											);
										}
										return null;
									})}
								{investors &&
									investors.files &&
									investors.files.map((file, index) => (
										<FileVaultFileRow
											file={file}
											openFileCallback={openFile}
											fileInfoCallback={showFileInfo}
											key={index}
											selected={
												fileInfo &&
												fileInfo.id === file.id
											}
											onFileSelect={onFileSelect}
											selectedFiles={selectedFiles}
										/>
									))}
							</TableBody>
						)}
					</Table>
				)}

				{!loading &&
					((tab === 'overview' &&
						files?.length === 0 &&
						folders?.length === 0) ||
						(tab === 'investors' &&
							investors?.files?.length === 0 &&
							investors?.folders?.length === 0)) && (
						<FileVaultEmpty />
					)}
			</Grid>
			{fileInfo && (
				<Grid item md={3}>
					<FileVaultFileInfo
						file={fileInfo}
						viewFile={() => setFilePreview(fileInfo)}
						onDelete={() => setFileInfo(null)}
						tab={tab}
					/>
				</Grid>
			)}
			{filePreview && (
				<FileVaultPreview
					file={filePreview}
					onClose={() => setFilePreview(null)}
					tab={tab}
				/>
			)}
			{selectedFiles.length > 0 && (
				<FileVaultBulkActions
					files={selectedFiles}
					filesInstances={selectedFilesInst}
					onSuccess={() => {
						setSelectedFiles([]);
						setSelectedFilesInst([]);
					}}
				/>
			)}
		</Grid>
	);
}
