import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle, CircularProgress } from '@material-ui/core';
import PEService from '../../services/PEService';
import FundInfo from '../FundInfo/FundInfo';
import PageFrame from '../PageFrame';

export default function FundPage() {
	const [fund, setFund] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		setLoading(true);
		PEService.getFund(id).then(
			({ success, errorReason, error: { message } = {}, result }) => {
				setLoading(false);
				if (!success) {
					setError(errorReason || message);
				} else {
					setFund(result);
				}
			},
		);
	}, [id]);

	return (
		<PageFrame title="Fund information">
			{error && (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{error}
				</Alert>
			)}
			{loading && <CircularProgress sx={{ padding: '14px' }} />}
			{fund && <FundInfo fund={fund} />}
		</PageFrame>
	);
}
