// //////////////////////////////////////////////////////////////////////
// This file is generated in package.json under scripts/prebuild:config
// To change values here, don't edit this file. Edit /app.config.js
// and rerun 'npm run prebuild:config'
// //////////////////////////////////////////////////////////////////////
const path = require('path');

// Setup the env to match what we built with
Object.assign(process.env, {
	NODE_ENV: 'production',
	RUBBER_BUILD_ENV: 'prod',
});

// Export config for the above env
const AppConfig = {
	    tcpPort: 8098,
    kpiSheetId: '1PAYx8VMnsh18V-IZ1KEnizGF4Y8t8JFE9J2kFMOf1eI',
    doctl: {
	"token": "<...>"
},
    // Shared with PublicAppConfig, below
    ...({
	"apiHost": "api.1815north.com",
	"backendRoot": "https://api.1815north.com",
	"frontendRoot": "https://launch.1815north.com/#"
}),
    docusign: {
	"integrationKey": "8959399c-42de-4c30-a6a7-540640a72158",
	"privateKeyLocation": "./data/docusign-prod-priv.key",
	"redirectUriProd": "https://api.1815north.com/webhooks/docusign/callback",
	"redirectUriDev": "https://1815north-dev-api.ngrok.io/webhooks/docusign/callback",
	"impersonatedUserGuid": "6d6319d0-939e-44fb-8f34-050c14e96457",
	"apiAccountId": "1c23c5d6-45ef-4003-a9ed-60b34b32c84b",
	"dsClientId": "8959399c-42de-4c30-a6a7-540640a72158",
	"dsJWTClientId": "8959399c-42de-4c30-a6a7-540640a72158",
	"dsClientSecret": "5e73ab23-1043-4610-9d19-482e601eeb78",
	"production": false,
	"targetAccountId": false,
	"docTypePdfs": {
		"type1": "./data/pdfs/Oak Quarry Fund - L.P. Agreement.pdf",
		"type2": "./data/pdfs/Oak Quarry Fund - L.P. Subscription Agreement.pdf",
		"type3": "./data/pdfs/W-9.pdf"
	},
	"docTypeNames": {
		"type1": "Oak Quarry Fund - L.P. Agreement",
		"type2": "Oak Quarry Fund - L.P. Subscription Agreement",
		"type3": "W-9"
	},
	"dsOauthServer": "https://account-d.docusign.com",
	"dsAuthState": "./data/docusign-auth-state.json",
	"oak": "type1",
	"tax": "type2"
},
    groupdocs: {
	"clientId": "8afa9fbf-2f45-434d-bcae-b6e621a23149",
	"clientSecret": "8afa9fbf-2f45-434d-bcae-b6e621a23149"
},
    doSpaces: {
	"accessKeyId": "65DN5BXFCLZLCULLRU73",
	"secretAccessKey": "V3kfC/44PaQE+gr2ktdHj//OLLqBmcga50uLMYQ9A+M",
	"endpoint": "sfo3.digitaloceanspaces.com",
	"urlBase": "https://files.chatbetter.io",
	"spaceName": "chatbetter"
},
    pinExpiration: 3600000,
    fcmServerKey: '...',
    firebaseKey: '...',
    firebaseWorkspaceId: '...',
    firebaseStorageUrl: '...',
    mailgunKey: 'c6546f0a84f39784da6d84bdebd664b8-e49cc42c-3bd1405d',
    mailgunDomain: 'crunch.josiahbryan.com',
    defaultFromAddress: 'no-reply@crunch.josiahbryan.com',
    twilioAccountSid: 'ACae6c5c8d900d648bba0115cc4003cd4e',
    twilioAuthToken: '7d5ee09a146b858791e74836e27b2d66',
    twilioPhoneNum: '+15128903157',
    jsonPrettyPrint: 4,
    jwtSignKey: 'eimeilahquah6raigooNgaichahgh0queishoh2eiphaeneebu9ahzu9AhyeiNaefeu3TielaiLa6cahm7befiko7IheiXiv0Aethichaekai2taeb4ohnab9Ohkoid5',
    dataRoot: path.resolve('./data/'),
    slack: {
	"notifyCicdUrl": "https://hooks.slack.com/services/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"
},

	
	version: '1.0.3-2a55b3e-prod',

	gitVersion: '2a55b3e',

	buildEnv: 'prod',

	buildTime: '2024-02-16T19:04:14.604Z',

	buildArch: 'armv7l',

	buildPlatform: 'linux',

	// Generated as an MD5 of package-lock.json so we can tell if hotfix will work or not
	depHash: '732c88a730fa6aa9d1f3259ab338d2f4',

	// Set in Jenkins via the BUILD_NUMBER env var
	buildNumber: 'undefined',

}
	
export default AppConfig;

// Import THIS in the front end instead of AppConfig
// so nothing private is exposed.
export const PublicAppConfig = {
	...({
	"apiHost": "api.1815north.com",
	"backendRoot": "https://api.1815north.com",
	"frontendRoot": "https://launch.1815north.com/#"
}),
	
	version: '1.0.3-2a55b3e-prod',

	gitVersion: '2a55b3e',

	buildEnv: 'prod',

	buildTime: '2024-02-16T19:04:14.604Z',

	buildArch: 'armv7l',

	buildPlatform: 'linux',

	// Generated as an MD5 of package-lock.json so we can tell if hotfix will work or not
	depHash: '732c88a730fa6aa9d1f3259ab338d2f4',

	// Set in Jenkins via the BUILD_NUMBER env var
	buildNumber: 'undefined',

};
