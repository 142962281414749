/* eslint-disable no-console */
import { Link } from 'react-router-dom';
import styles from './FileVaultNav.module.scss';
import { useProfile } from '../../services/AuthService';

export default function FileVaultNav({ active, onTabChange }) {
	const profile = useProfile();

	return (
		<div className={styles.navigation}>
			<div className={styles.link}>
				<Link
					className={active === 'overview' ? styles.active : ''}
					onClick={() => onTabChange('overview')}
				>
					My File Vault
				</Link>
			</div>
			{profile.user.isAdmin && (
				<div className={styles.link}>
					<Link
						className={active === 'investors' ? styles.active : ''}
						onClick={() => onTabChange('investors')}
					>
						Investor File Vaults
					</Link>
				</div>
			)}
			<div className={styles.link}>
				<Link
					className={active === 'recycle' ? styles.active : ''}
					onClick={() => onTabChange('recycle')}
				>
					Recycle bin
				</Link>
			</div>
		</div>
	);
}
