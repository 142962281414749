import { File } from 'tabler-icons-react';
import PDFLogo from '../../img/pdfLogo.svg';
import XLSLogo from '../../img/xlsLogo.svg';
import styles from './FileIcon.module.scss';

export default function FileIcon({ mimeType }) {
	let iconPath;
	switch (mimeType) {
		case 'application/pdf':
			iconPath = PDFLogo;
			break;
		case 'application/xls':
		case 'application/xlsx':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			iconPath = XLSLogo;
			break;
		default:
			return <File />;
	}

	return <img className={styles.fileIcon} src={iconPath} alt={mimeType} />;
}
