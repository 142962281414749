import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import FileViewer from 'react-file-viewer';
import axios from 'axios';
import FileVaultService from '../../services/FileVaultService';
/* eslint-disable no-console */
export default function MediaFileViewer({ file }) {
	const [url, setUrl] = useState();
	const [blobFile, setBlobFile] = useState(null);
	const [loading, setLoading] = useState();

	const downloadBlobFile = (downloadURL) => {
		axios.get(downloadURL, { responseType: 'blob' }).then((response) => {
			setLoading(false);
			setBlobFile(response.data);
		});
	};

	useEffect(() => {
		setLoading(true);
		FileVaultService.getDocument(file.id).then((data) => {
			setUrl(data.url);
			downloadBlobFile(data.url);
		});
	}, [file]);

	return (
		<>
			{loading && <CircularProgress />}
			{url && blobFile && (
				<div>
					<FileViewer
						fileType={'pdf'}
						filePath={URL.createObjectURL(blobFile)}
					/>
				</div>
			)}
		</>
	);
}
