import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Alert,
	Grid,
	Checkbox,
} from '@material-ui/core';
import { useState } from 'react';
import { useFileVault } from '../../services/FileVaultService';
import styles from './FileVaultSearchResult.module.scss';
import FileVaultFileRow from '../FileVaultFileRow/FileVaultFileRow';
import FileVaultFileInfo from '../FileVaultFileInfo/FileVaultFileInfo';
import FileVaultPreview from '../FileVaultPreview/FileVaultPreview';
import NoResult from '../../img/no-results.svg';
import FileVaultBulkActions from '../FileVaultBulkActions/FileVaultBulkActions';

/* eslint-disable no-console */

function FileVaultSearchResult() {
	const fileVault = useFileVault();
	const [error] = useState();
	const [fileInfo, setFileInfo] = useState(null);
	const [filePreview, setFilePreview] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [selectedFilesInst, setSelectedFilesInst] = useState([]);

	const { search } = fileVault;

	const openFile = (file) => {
		setFilePreview(file);
	};

	const showFileInfo = (file) => {
		if (fileInfo && file.id === fileInfo.id) {
			setFileInfo(null);
		} else {
			setFileInfo(file);
		}
	};

	const handleAllFilesSelect = (selected = true) => {
		if (selected) {
			setSelectedFiles(search.map((f) => f.id));
			setSelectedFilesInst(search);
		} else {
			setSelectedFiles([]);
			setSelectedFilesInst([]);
		}
	};

	const onFileSelect = (id, selected = true, file) => {
		if (selected) {
			setSelectedFiles([...selectedFiles, id]);
			setSelectedFilesInst([...selectedFilesInst, file]);
		} else {
			const newSelectedFiles = selectedFiles.filter((f) => f !== id);
			const newInst = selectedFilesInst.filter((f) => f.id !== id);
			setSelectedFiles(newSelectedFiles);
			setSelectedFilesInst(newInst);
		}
	};

	return (
		<Grid container className={styles.container}>
			<Grid
				item
				md={fileInfo ? 9 : 12}
				sx={{ paddingRight: fileInfo ? '16px' : 0 }}
			>
				<div className={styles.breadcrumbs}>
					<div className={styles.breadcrumb}>Search results</div>
				</div>
				{error && <Alert>{error}</Alert>}
				{search && search.length > 0 && (
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>
									{search?.length > 0 && (
										<Checkbox
											checked={
												selectedFiles.length ===
												search.length
											}
											onChange={(e) =>
												handleAllFilesSelect(
													e.target.checked,
												)
											}
										/>
									)}
								</TableCell>
								<TableCell></TableCell>
								<TableCell align="left">Name</TableCell>
								<TableCell align="left">Owner</TableCell>
								<TableCell align="left">
									Last Modified
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{search &&
								search.map((file, index) => (
									<FileVaultFileRow
										file={file}
										openFileCallback={openFile}
										fileInfoCallback={showFileInfo}
										key={index}
										selected={
											fileInfo && fileInfo.id === file.id
										}
										onFileSelect={onFileSelect}
										selectedFiles={selectedFiles}
									/>
								))}
						</TableBody>
					</Table>
				)}
				{search && search.length === 0 && (
					<div className={styles.noResult}>
						<img src={NoResult} alt="No Results" />
						<div className={styles.title}>
							No files or folders match this search
						</div>
						<div className={styles.description}>
							Try another search or type fewer characters
						</div>
					</div>
				)}
			</Grid>
			{fileInfo && (
				<Grid item md={3}>
					<FileVaultFileInfo file={fileInfo} />
				</Grid>
			)}
			{filePreview && (
				<FileVaultPreview
					file={filePreview}
					onClose={() => setFilePreview(null)}
				/>
			)}
			{selectedFiles.length > 0 && (
				<FileVaultBulkActions
					files={selectedFiles}
					filesInstances={selectedFilesInst}
					onSuccess={() => {
						setSelectedFiles([]);
						setSelectedFilesInst([]);
					}}
				/>
			)}
		</Grid>
	);
}

export { FileVaultSearchResult };
