/* eslint-disable no-console */

import { moneyNumberFormat } from '../numbers';

const getOrCreateTooltip = (chart) => {
	let tooltipEl = chart.canvas.parentNode.querySelector(
		'div:not(.description,.border)',
	);
	let border = chart.canvas.parentNode.querySelector('div.border');

	if (!tooltipEl) {
		tooltipEl = document.createElement('div');
		tooltipEl.style.background = '#121619';
		tooltipEl.style.borderRadius = '3px';
		tooltipEl.style.color = 'white';
		tooltipEl.style.opacity = 1;
		tooltipEl.style.pointerEvents = 'none';
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.transform = 'translate(-50%, 0)';
		tooltipEl.style.transition = 'all .1s ease';
		tooltipEl.style.fontFamily = 'Roboto Mono';
		tooltipEl.style.fontSize = '12px';
		tooltipEl.style.zIndex = '2';
		tooltipEl.style.minWidth = '255px';

		const table = document.createElement('table');
		table.style.margin = '0px';
		table.style.width = '100%';

		border = document.createElement('div');
		border.className = 'border';
		border.style.width = '2px';
		border.style.position = 'absolute';
		border.style.backgroundColor = '#121619';

		tooltipEl.appendChild(table);
		tooltipEl.appendChild(border);
		chart.canvas.parentNode.appendChild(tooltipEl);
	}

	return { tooltipEl, border };
};

const calculatePercent = (start, current) => {
	if (start === 0 || current - start === 0) {
		return '0';
	}
	const percent = ((current - start) / start) * 100;
	return Math.round(percent * 100) / 100;
};

const externalTooltipHandler = (context) => {
	// Tooltip Element
	const { chart, tooltip } = context;
	const { tooltipEl, border } = getOrCreateTooltip(chart);
	// Hide if no tooltip
	if (tooltip.opacity === 0) {
		tooltipEl.style.opacity = 0;
		return;
	}

	// Set Text
	if (tooltip.body) {
		const titleLines = tooltip.title || [];

		const { dataPoints } = tooltip;

		const tableHead = document.createElement('thead');

		titleLines.forEach((title) => {
			const tr = document.createElement('tr');
			tr.style.borderWidth = 0;

			const th = document.createElement('th');
			th.colSpan = 3;
			th.style.borderBottom = '1px solid #909696';
			th.style.textAlign = 'left';
			const text = document.createTextNode(title);

			th.appendChild(text);
			tr.appendChild(th);
			tableHead.appendChild(tr);
		});

		const tableBody = document.createElement('tbody');
		dataPoints.forEach((point, i) => {
			const colors = tooltip.labelColors[i];

			const label = document.createElement('span');
			label.style.background = colors.backgroundColor;
			label.style.borderColor = colors.borderColor;
			label.style.borderWidth = '2px';
			label.style.marginRight = '10px';
			label.style.height = '10px';
			label.style.width = '10px';

			label.innerText = point.dataset.label;

			const tr = document.createElement('tr');
			tr.style.backgroundColor = 'inherit';
			tr.style.borderWidth = 0;

			const tdLabel = document.createElement('td');
			tdLabel.style.color = '#909696';

			const tdValue = document.createElement('td');
			tdValue.style.color = point.dataset.borderColor;
			tdValue.style.fontWeight = 700;

			const text = document.createTextNode(
				`$${moneyNumberFormat(point.raw, 3)}`,
			);

			const tdPercentage = document.createElement('td');
			tdPercentage.style.color = '#24A148';
			tdPercentage.style.fontWeight = 'bold';
			const percent = calculatePercent(point.dataset.data[0], point.raw);
			tdPercentage.innerHTML = `( ${percent}% )`;

			tdLabel.appendChild(label);
			tdValue.appendChild(text);
			tr.appendChild(tdLabel);
			tr.appendChild(tdValue);
			tr.appendChild(tdPercentage);

			tableBody.appendChild(tr);
		});

		const tableRoot = tooltipEl.querySelector('table');

		// Remove old children
		if (tableRoot) {
			while (tableRoot.firstChild) {
				tableRoot.firstChild.remove();
			}
		}

		// Add new children
		tableRoot.appendChild(tableHead);
		tableRoot.appendChild(tableBody);
	}

	// const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
	const { offsetLeft: positionX } = chart.canvas;

	// Display, position, and set styles for font

	let left = positionX + tooltip.caretX;
	if (left < 100) {
		left = 110;
	} else if (chart.width - left < 150) {
		left = chart.width - 115;
	}

	tooltipEl.style.opacity = 1;
	tooltipEl.style.left = `${left}px`;
	// tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
	tooltipEl.style.top = '8px';
	tooltipEl.style.font = tooltip.options.bodyFont.string;
	tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;

	border.style.left =
		left === 110 ? '55px' : `${tooltipEl.offsetWidth / 2 - 1}px`;
	if (left === chart.width - 115) {
		border.style.left = '223px';
	}
	border.style.top = `${tooltipEl.offsetHeight}px`;
	border.style.height = `${
		chart.chartArea.height - tooltipEl.offsetHeight
	}px`;
};

const getGradient = (ctx, chartArea) => {
	let width;
	let height;
	let gradient;
	const chartWidth = chartArea.right - chartArea.left;
	const chartHeight = chartArea.bottom - chartArea.top;
	if (gradient === null || width !== chartWidth || height !== chartHeight) {
		// Create the gradient because this is either the first render
		// or the size of the chart has changed
		width = chartWidth;
		height = chartHeight;
		gradient = ctx.createLinearGradient(
			0,
			chartArea.bottom,
			0,
			chartArea.top,
		);
		gradient.addColorStop(0, 'rgba(120, 169, 255, 0.6)');
		gradient.addColorStop(1, 'rgba(154, 191, 255, 0)');
	}

	return gradient;
};

export { externalTooltipHandler, getGradient };
