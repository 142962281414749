import {
	Alert,
	AlertTitle,
	TableCell,
	TableRow,
	TextField,
	Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import { useRef, useState } from 'react';
import useDoubleClick from 'use-double-click';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { Eye, RotateClockwise2, FileText } from 'tabler-icons-react';
import { toast } from 'react-toastify';
import FileIcon from '../FileIcon/FileIcon';
import styles from './FileVaultFileRow.module.scss';
import FileVualtDeleteBtn from '../FileVaultDeleteBtn/FileVaultDeleteBtn';
import FileVaultService from '../../services/FileVaultService';
import FileVaultToast from '../FileVaultToast/FileVaultToast';
import FileVualtCopyBtn from '../FileVaultCopyBtn/FileVaultCopyBtn';
import FileVaultMoveBtn from '../FileVaultMoveBtn/FileVaultMoveBtn';

export default function FileVaultFileRow({
	file,
	openFileCallback,
	fileInfoCallback,
	selected,
	variant = 'overview',
	onFileSelect,
	selectedFiles,
}) {
	const filesRowRef = useRef();

	const [rename, setRename] = useState();
	const [fileName, setFileName] = useState(file.name);
	const [error, setError] = useState();

	useDoubleClick({
		onSingleClick: () => {
			if (!rename) {
				fileInfoCallback(file);
			}
		},
		onDoubleClick: () => {
			if (!rename) {
				openFileCallback(file);
			}
		},
		ref: filesRowRef,
		latency: 250,
	});

	const startRename = () => {
		setFileName(file.name);
		setRename(true);
		filesRowRef.current.focus();
	};

	const renameFile = (e) => {
		/* eslint-disable no-console */
		if (e.key === 'Enter' && fileName.trim()) {
			FileVaultService.renameFile(file.id, fileName.trim()).then(
				({ success, errorReason, error: { message } = {} }) => {
					if (success) {
						setRename(false);
					} else {
						setError(errorReason || message);
					}
				},
			);
		}

		if (e.key === 'Escape') {
			setFileName(file.name);
			setRename(false);
		}
	};

	let options = [];

	const restore = () => {
		return toast.promise(
			FileVaultService.restore(file.id),
			{
				pending: {
					icon: false,
					closeButton: false,
					render: () => {
						return (
							<FileVaultToast
								title="Restoring file"
								name={file.name}
								mimeType={file.type}
								status="loading"
							/>
						);
					},
				},
				success: {
					render: () => {
						return (
							<FileVaultToast
								title="File restored"
								name={file.name}
								mimeType={file.type}
								status="finished"
							/>
						);
					},
					closeButton: false,
					icon: false,
					autoClose: 2000,
				},
			},
			{
				position: 'bottom-right',
			},
		);
	};

	switch (variant) {
		default:
		case 'overview':
			options = [
				{
					label: (
						<span className={styles.contextItem}>
							<Eye size={16} /> View
						</span>
					),
					onClick: () => openFileCallback(file),
				},
				{
					label: (
						<span className={styles.contextItem}>
							<FileText size={16} /> Rename
						</span>
					),
					onClick: () => startRename(),
				},
				{
					label: <FileVualtCopyBtn file={file} long={true} />,
				},
				{
					label: <FileVaultMoveBtn file={file} long={true} />,
				},
				{
					label: <FileVualtDeleteBtn file={file} long={true} />,
				},
			];
			break;
		case 'recycle':
			options = [
				{
					label: (
						<span className={styles.contextItem}>
							<RotateClockwise2 size={16} /> Restore
						</span>
					),
					onClick: () => restore(),
				},
				{
					label: (
						<FileVualtDeleteBtn
							file={file}
							long={true}
							label={'Delete permanently'}
							permanently={true}
							bin={true}
						/>
					),
				},
			];
			break;
	}

	return (
		<TableRow hover selected={selected}>
			{onFileSelect && (
				<TableCell>
					<Checkbox
						checked={selectedFiles.indexOf(file.id) > -1}
						onChange={(e) => {
							setRename(false);
							setFileName(file.name);
							onFileSelect(file.id, e.target.checked, file);
						}}
						defaultChecked={false}
					/>
				</TableCell>
			)}
			<TableCell>
				<FileIcon mimeType={file.mimeType} />
			</TableCell>
			<TableCell align="left" ref={filesRowRef} className={styles.file}>
				<ContextMenuTrigger id={file.id}>
					<span className={styles.fileName}>
						{rename ? (
							<TextField
								value={fileName}
								onChange={(e) => setFileName(e.target.value)}
								onKeyDown={renameFile}
								className={styles.editField}
							/>
						) : (
							file.name
						)}
						{error && (
							<Alert>
								<AlertTitle>Error</AlertTitle>
								{error}
							</Alert>
						)}
					</span>
				</ContextMenuTrigger>
			</TableCell>
			<TableCell align="left">
				<span className={styles.user}>{file.owner.name}</span>
			</TableCell>
			<TableCell align="left">
				{moment(file.updatedAt).format('MMMM DD, YYYY')}{' '}
				<span className={styles.user}>{file.updatedBy.name}</span>
			</TableCell>
			{!rename && (
				<ContextMenu id={file.id} className={styles.contextMenu}>
					{options.map((option, index) => (
						<MenuItem
							key={index}
							className={styles.item}
							onClick={option.onClick}
						>
							{option.label}
						</MenuItem>
					))}
				</ContextMenu>
			)}
		</TableRow>
	);
}
