/* eslint-disable no-underscore-dangle */
// Based on the components/Head component in material-ui repo,
// modified for use with react-helmet

import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

/**
 * Reusable component to include Helmet with various SEO attributes
 *
 * Note: If we ever add twitter or FB pages, we can add those comment-out props back in.
 *
 * @param {string} params.title Title for this page
 * @param {string} params.description (optional) Description for this page
 */
function Head({ description, title }) {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			{/* Twitter */}
			<meta name="twitter:card" content="summary_large_image" />
			{/* <meta name="twitter:site" content="@MaterialUI" /> */}
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{/* <meta name="twitter:image" content="https://material-ui.com/static/brand.png" /> */}
			{/* Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			{/* <meta
				property="og:url"
				content={`https://material-ui.com${Router._rewriteUrlForNextExport(router.asPath)}`}
			/> */}
			<meta property="og:description" content={description} />
			{/* <meta property="og:image" content="https://material-ui.com/static/brand.png" /> */}
			<meta property="og:ttl" content="604800" />
			{/* Algolia */}
			{/* <meta name="docsearch:language" content={userLanguage} /> */}
			<meta name="docsearch:version" content="master" />
		</Helmet>
	);
}

Head.propTypes = {
	description: PropTypes.string,
	title: PropTypes.string,
};

export default Head;
