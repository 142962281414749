import styles from './OnboardingProgress.module.scss';

export default function OnboardingProgress({ step, total }) {
	return (
		<div className={styles.progress}>
			{Array.from(Array(total).keys()).map((el, index) => (
				<div
					key={index}
					className={step > index ? styles.active : ''}
				></div>
			))}
		</div>
	);
}
