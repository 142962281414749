import Fingerprint2 from 'fingerprintjs2';
import { v4 as uuid } from 'uuid';

const DEVICE_ID_KEY = '@um/deviceId';
export default class DeviceInfo {
	static iOSVersion() {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			// supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
			let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
			return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
		}
		return null;
	}

	static getGeneratedDeviceId() {
		if (this.deviceId) return this.deviceId;

		this.deviceId = window.localStorage.getItem(DEVICE_ID_KEY);
		if (!this.deviceId) {
			this.deviceId = uuid();
			window.localStorage.setItem(DEVICE_ID_KEY, this.deviceId);
		}

		return this.deviceId;
	}

	static _processFingerprintResult(components, resolve) {
		const values = components.map((component) => component.value);
		const result = Fingerprint2.x64hash128(values.join(''), 31);

		// console.log("[DeviceInfo._processFingerprintResult]", result, components) // an array of components: {key: ..., value: ...}
		if (!result) resolve(this.getGeneratedDeviceId());

		this.deviceId = result;
		resolve(result);
	}

	static getDeviceId() {
		const _this = this;
		return new Promise((resolve) => {
			// Just for debugging
			// // if(process.env.NODE_ENV === 'development') {
			// 	console.warn("[getDeviceId] only using getGeneratedDeviceId()")
			// 	resolve(this.getGeneratedDeviceId());
			// // }

			if (_this.deviceId) resolve(_this.deviceId);

			if (window.requestAnimationFrame) {
				window.requestAnimationFrame(async () => {
					_this._processFingerprintResult(
						await Fingerprint2.getPromise(),
						resolve,
					);
				});
			} else {
				setTimeout(async () => {
					_this._processFingerprintResult(
						await Fingerprint2.getPromise(),
						resolve,
					);
				}, 500);
			}
		});
	}

	static parseNavigatorAsDevice() {
		// From https://stackoverflow.com/questions/12489546/how-to-get-browsers-name-client-side
		/*eslint-disable */ // No lint for external code, from https://stackoverflow.com/questions/27732209/turning-off-eslint-rule-for-a-specific-line
		// var nVer = navigator.appVersion;
		var nAgt = navigator.userAgent;
		var browserName  = navigator.appName;
		var fullVersion  = ''+parseFloat(navigator.appVersion);
		var majorVersion = parseInt(navigator.appVersion,10);
		var nameOffset,verOffset,ix;

		// In Opera, the true version is after "Opera" or after "Version"
		if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
		   browserName = "Opera";
		   fullVersion = nAgt.substring(verOffset+6);
		   if ((verOffset=nAgt.indexOf("Version"))!=-1)
		     fullVersion = nAgt.substring(verOffset+8);
		}
		// In MSIE, the true version is after "MSIE" in userAgent
		else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
		   browserName = "Microsoft Internet Explorer";
		   fullVersion = nAgt.substring(verOffset+5);
		}
		// In Chrome, the true version is after "Chrome"
		else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
		   browserName = "Chrome";
		   fullVersion = nAgt.substring(verOffset+7);
		}
		// In Safari, the true version is after "Safari" or after "Version"
		else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
		   browserName = "Safari";
		   fullVersion = nAgt.substring(verOffset+7);
		   if ((verOffset=nAgt.indexOf("Version"))!=-1)
		     fullVersion = nAgt.substring(verOffset+8);
		}
		// In Firefox, the true version is after "Firefox"
		else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
		    browserName = "Firefox";
		    fullVersion = nAgt.substring(verOffset+8);
		}
		// In most other browsers, "name/version" is at the end of userAgent
		else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ) {
		    browserName = nAgt.substring(nameOffset,verOffset);
		    fullVersion = nAgt.substring(verOffset+1);
		    if (browserName.toLowerCase()==browserName.toUpperCase()) {
		       browserName = navigator.appName;
		    }
		}
		// trim the fullVersion string at semicolon/space if present
		if ((ix=fullVersion.indexOf(";"))!=-1)
		    fullVersion=fullVersion.substring(0,ix);
		if ((ix=fullVersion.indexOf(" "))!=-1)
		    fullVersion=fullVersion.substring(0,ix);

		majorVersion = parseInt(''+fullVersion,10);
		if (isNaN(majorVersion)) {
		    fullVersion  = ''+parseFloat(navigator.appVersion);
		    majorVersion = parseInt(navigator.appVersion,10);
		}
		
		return {
			platform: browserName,
			model: '',
			version: majorVersion,
		};
	}

	static deviceClass() {
		const breakpointMinimums = {
			// desktop default
			desktop: 1200,
			tablet: 768,
			// phone default
		};

		for (let type in breakpointMinimums) {
			// eslint-disable-line no-unused-vars
			if (
				window.matchMedia(`(min-width: ${breakpointMinimums[type]}px)`).matches
			) {
				return type;
			}
		}
		return 'phone';
	}

	static appType() {
		return window.isPhoneGap ? 'native' : 'web';
	}

	static deviceProps() {
		// window.device will be present on PhoneGap to identify
		// the type of native device we are running on. If it's not present,
		// we will use the code in parseNavigatorAsDevice() to emulate the
		// same fields present on PhoneGap's .device object and fill
		// them with data from the web browser.
		return window.device || this.parseNavigatorAsDevice();
	}

	static deviceName() {
		const execDevice = this.deviceProps();

		return (
			[
				execDevice.manufacturer ? execDevice.manufacturer : execDevice.platform,
				execDevice.model,
				execDevice.version,
			]
				.join(' ')
				// On browsers, .model is null, so it leaves double spaces in the middle.
				// This regex just collapses those spaces into one
				.replace(/\s+/g, ' ')
		);
	}

	static async getDeviceInfo(clearCache = false) {
		if (this.cachedDeviceInfo && !clearCache) {
			return this.cachedDeviceInfo;
		}

		const deviceId = await this.getDeviceId();
		const device = {
			deviceId,
			timestamp: new Date(),
			appType: this.appType(),
			deviceClass: this.deviceClass(),
			name: this.deviceName(),
			properties: this.deviceProps(),
		};

		// Used as generic pivot point now
		device.brand = this.ua.Android
			? 'android'
				: this.ua.iOS
			? 'ios'
				: this.appType() === 'native'
			? 'other'
			: 'browser';

		if (this.ua.iOS) {
			device.iOSVersion = this.iOSVersion();
		}

		this.cachedDeviceInfo = device;

		return device;
	}
}

// UA matching
DeviceInfo.ua = {
	Android: /Android/gi.test(navigator.userAgent),
	iOS:
		/AppleWebKit/.test(navigator.userAgent) &&
		/Mobile\/\w+/.test(navigator.userAgent),
};

